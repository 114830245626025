<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{'project-create.title' | translate}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row mb-2" [hidden]="!has(TEMPLATE.NAME)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.name' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="50" class="form-control" [(ngModel)]="projectName" [disabled]="isProcessing"
        required focus="true">
    </div>
  </div>


  <div class="row mb-2" [hidden]="!has(TEMPLATE.PLATFORM)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.platform' | translate}}</span>
    </div>
    <div class="col-sm-10">
      <div class="row">
        <div *ngFor="let optionTemplate of optionTemplates; let i = index" class="col-sm-2 pl-2">
          <div class="media abc-checkbox pl-2" [ngClass]="{'abc-checkbox-primary':isPlatformSupported(optionTemplate.get(TEMPLATE.PLATFORM))}">
            <input [id]="'platform-'+optionTemplate.get(TEMPLATE.PLATFORM)" type="checkbox" [checked]="isPlatformSupported(optionTemplate.get(TEMPLATE.PLATFORM))"
            (change)="setPlatform(optionTemplate.get(TEMPLATE.PLATFORM), $event.target.checked)" class="media-left">&nbsp;

            <label [for]="'platform-'+optionTemplate.get(TEMPLATE.PLATFORM)" class="media-body form-check-label align-items-center d-flex text-nowrap"
              [ngClass]="{'text-primary':isPlatformSupported(optionTemplate.get(TEMPLATE.PLATFORM))}">
              <i class="fa fa-2x fa-fw {{'platform-icon.' + optionTemplate.get(TEMPLATE.PLATFORM)|translate}}"></i>
              {{"platform." + optionTemplate.get(TEMPLATE.PLATFORM)|translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-2" [hidden]="!has(TEMPLATE.PACKAGE)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.packageFilter' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <input type="text" maxlength="100" class="form-control" [(ngModel)]="projectFilter" [disabled]="isProcessing"
        required>
    </div>
  </div>

  <div class="row mb-2" [hidden]="!has(TEMPLATE.NOTE)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.note' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <textarea rows="3" maxlength="200" class="form-control" [(ngModel)]="projectNote"
        [disabled]="isProcessing"></textarea>
    </div>
  </div>

  <!-- <div class="row mb-2" [hidden]="!has(TEMPLATE.ICON)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.icon' | translate}}</span>
    </div>
    <div class="col-sm-8">
      <div class="custom-file" id="customFile">
        <input #iconFile type="file" class="custom-file-input" id="iconFile" (change)="onIconChange($event)">
        <label class="custom-file-label" for="iconFile">
          {{ iconFileName }}
        </label>
      </div>
    </div>
  </div> -->
  <div class="row mb-2" [hidden]="!has(TEMPLATE.KEYSTORE)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.keystore' | translate}}</span>
    </div>
    <div class="input-group col-sm-8">

      <ul class="list-group col-sm p-0" [hidden]="!isKeyStoreSelected()">
        <li class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
          <span>
            <span>
              <span [hidden]="!keyStore?.name">
                <i class="fa fa-key"></i>&nbsp;{{keyStore?.name}}</span>
              <span [hidden]="!keyStore?.alias">/ {{keyStore?.alias}}</span>
              <br>
              <span [hidden]="!keyStore?.commonName">{{'keystore.common-name'|translate}}:
                {{keyStore?.commonName}}</span>
              <span [hidden]="!keyStore?.organizationUnit">, {{'keystore.organization-unit'|translate}}:
                {{keyStore?.organizationUnit}}</span>
              <span [hidden]="!keyStore?.organization">, {{'keystore.organization'|translate}}:
                {{keyStore?.organization}}</span>
              <span [hidden]="!keyStore?.locality">, {{'keystore.locality'|translate}}: {{keyStore?.locality}}</span>
              <span [hidden]="!keyStore?.state">, {{'keystore.state'|translate}}: {{keyStore?.state}}</span>
              <span [hidden]="!keyStore?.countryCode">, {{'keystore.country-code'|translate}}:
                {{keyStore?.countryCode}}</span>
              <span [hidden]="!keyStore?.note">, {{keyStore?.note}}</span>
            </span>
          </span>
          <button type="button" class="close pr-2" (click)="removeKeyStore()">
            <span>&times;</span>
          </button>
        </li>
      </ul>
      <ul class="list-group col-sm p-0" [hidden]="isKeyStoreSelected()">
        <li class="list-group-item p-1 pl-3">
          <span>{{'common.none' | translate}}</span>
        </li>
      </ul>
      <div class="input-group-append col-sm-2 p-0">
        <button class="btn btn-outline-secondary col-sm p-0"
          (click)="createKeyStore()">{{'common.create'|translate}}</button>
        <button class="btn btn-outline-secondary col-sm p-0"
          (click)="selectKeyStore()">{{'common.select'|translate}}</button>
      </div>
    </div>
  </div>


  <div class="row mb-2" [hidden]="!has(TEMPLATE.MANAGERS)">
    <div class="col-sm-2 text-right mt-1">
      <span>{{'project.manager' | translate}}</span>
    </div>
    <div class="input-group col-sm-8">
      <ul class="list-group col-sm p-0" [hidden]="managers?.length == 0">
        <li *ngFor="let user of managers"
          class="list-group-item p-1 pl-3 d-flex justify-content-between align-items-center">
          <span>
            <span [hidden]="!user?.id">
              <i class="fa fa-address-card-o"></i>&nbsp;{{user?.id}}</span>
            <span [hidden]="!user?.name">/ {{user?.name}}</span>
            <span [hidden]="!user?.department">/ {{user?.department}}</span>
            <br>
            <span [hidden]="!user?.email">
              <i class="fa fa-envelope-o"></i>&nbsp;{{user?.email}}</span>
            <span [hidden]="!user?.tel">/
              <i class="fa fa-phone"></i>&nbsp;{{user?.tel}}</span>
          </span>
          <button type="button" class="close pr-2" (click)="removeUser(user)">
            <span aria-hidden="true">&times;</span>
          </button>
        </li>
      </ul>
      <ul class="list-group col-sm p-0" [hidden]="managers?.length > 0">
        <li class="list-group-item p-1 pl-3">
          <span>{{'common.none' | translate}}</span>
        </li>
      </ul>
      <div class="input-group-append col-sm-1 p-0">
        <button class="btn btn-outline-secondary col-sm p-0"
          (click)="selectUser()">{{'common.select'|translate}}</button>
      </div>
    </div>
  </div>

  <hr>

  <ul ngbNav #platformOptionTab="ngbNav" class="nav-tabs mb-3 mt-3 pl-10">
    <li *ngFor="let option of optionTemplates; let i = index" [ngbNavItem]="option.get(TEMPLATE.PLATFORM)" [hidden]="!isPlatformSupported(option.get(TEMPLATE.PLATFORM))">
      <a ngbNavLink>{{"platform." + option.get(TEMPLATE.PLATFORM)|translate}}</a>
      <ng-template ngbNavContent>
        <ul ngbNav #platformConfigTab="ngbNav" [activeId]="0" class="nav-pills mt-2 mb-4 pl-10">
          <li [ngbNavItem]="0">
            <a ngbNavLink><i class="fa fa-shield fa-1x"></i>&nbsp;{{"config.security-option-config"|translate}}</a>
            <ng-template ngbNavContent>
              <project-option-view #projectOption [template]="option" [readOnly]="false"></project-option-view>
            </ng-template>
          </li>
          <li *ngFor="let config of getConfigs(option.get(TEMPLATE.PLATFORM)); let j = index" [ngbNavItem]="j+1">
            <a ngbNavLink><i class="fa fa-window-restore fa-1x"></i>&nbsp;{{"config." + config.get(TEMPLATE.NAME)|translate}}</a>
            <ng-template ngbNavContent>
              <project-config-view [template]="config" [readOnly]="false"></project-config-view>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="platformConfigTab" class="mt-2 mb-4">
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="platformOptionTab" class="mt-2"></div>

</div>


<hr>
<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="create()">
      <div [hidden]="isProcessing">
        {{'project-create.button.create' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
  </div>
</div>