import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule, CookieService } from 'ngx-cookie';
import { FocusModule } from 'angular2-focus';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { AuthService } from './service/auth.service';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { PagenationComponent } from './common/pagenation/pagenation.component';
import { FormsModule } from '@angular/forms';
import { MessageBoxComponent } from './common/message-box/message-box.component';
import { UserListViewComponent } from './user/user-list-view/user-list-view.component';
import { UserDetailViewComponent } from './user/user-detail-view/user-detail-view.component';
import { UserCreateViewComponent } from './user/user-create-view/user-create-view.component';
import { UserUpdateViewComponent } from './user/user-update-view/user-update-view.component';
import { CommonService } from './service/common.service';
import { KeyStoreListViewComponent } from './keystore/key-store-list-view/key-store-list-view.component';
import { KeyStoreDetailViewComponent } from './keystore/key-store-detail-view/key-store-detail-view.component';
import { KeyStoreCreateViewComponent } from './keystore/key-store-create-view/key-store-create-view.component';
import { KeyStoreAddViewComponent } from './keystore/key-store-add-view/key-store-add-view.component';
import { ProjectListViewComponent } from './project/project-list-view/project-list-view.component';
import { ProjectDetailViewComponent } from './project/project-detail-view/project-detail-view.component';
import { ProjectCreateViewComponent } from './project/project-create-view/project-create-view.component';
import { KeyStoreSearchDialogComponent } from './keystore/key-store-search-dialog/key-store-search-dialog.component';
import { UserSearchDialogComponent } from './user/user-search-dialog/user-search-dialog.component';
import { ProjectUpdateViewComponent } from './project/project-update-view/project-update-view.component';
import { MobileAppListViewComponent } from './mobile-app/mobile-app-list-view/mobile-app-list-view.component';
import { MobileAppUploadDialogComponent } from './mobile-app/mobile-app-upload-dialog/mobile-app-upload-dialog.component';
import { MyProjectListViewComponent } from './project/my-project-list-view/my-project-list-view.component';
import { BlankViewComponent } from './common/blank-view/blank-view.component';
import { ProcessingStatusViewComponent } from './common/processing-status-view/processing-status-view.component';
import { MobileAppDetailViewComponent } from './mobile-app/mobile-app-detail-view/mobile-app-detail-view.component';
import { TaskViewComponent } from './task/task-view/task-view.component';
import { UserProfilePopoverComponent } from './user/user-profile-popover/user-profile-popover.component';
import { QnaListViewComponent } from './qna/qna-list-view/qna-list-view.component';
import { QnaDetailViewComponent } from './qna/qna-detail-view/qna-detail-view.component';
import { RunningTaskListViewComponent } from './task/running-task-list-view/running-task-list-view.component';
import { ReplyViewComponent } from './qna/reply-view/reply-view.component';
import { QnaCreateViewComponent } from './qna/qna-create-view/qna-create-view.component';
import { UserPasswordResetDialogComponent } from './user/user-password-reset-dialog/user-password-reset-dialog.component';
import { TaskListViewComponent } from './task/task-list-view/task-list-view.component';
import { TaskOptionBadgeViewComponent } from './task/task-option-badge-view/task-option-badge-view.component';
import { TaskFindViewComponent } from './task/task-find-view/task-find-view.component';
import { AndroidAppDetailViewComponent } from './mobile-app/android-app-detail-view/android-app-detail-view.component';
import { IosAppDetailViewComponent } from './mobile-app/ios-app-detail-view/ios-app-detail-view.component';
import { ProjectOptionViewComponent } from './project/project-option-view/project-option-view.component';
import { EllipsisPipe } from './common/ellipsis/ellipsis-pipe';
import { TaskOptionViewComponent } from './task/task-option-view/task-option-view.component';
import { TimezoneSelectorComponent } from './common/timezone-selector/timezone-selector.component';
import { ProjectConfigViewComponent } from './project/project-config-view/project-config-view.component';
import { TaskConfigViewComponent } from './task/task-config-view/task-config-view.component';
import { TaskHistoryConfigViewComponent } from './task/task-history-config-view/task-history-config-view.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    PagenationComponent,
    MessageBoxComponent,
    UserListViewComponent,
    UserDetailViewComponent,
    UserCreateViewComponent,
    UserUpdateViewComponent,
    KeyStoreListViewComponent,
    KeyStoreDetailViewComponent,
    KeyStoreCreateViewComponent,
    KeyStoreAddViewComponent,
    ProjectListViewComponent,
    ProjectDetailViewComponent,
    ProjectCreateViewComponent,
    KeyStoreSearchDialogComponent,
    UserSearchDialogComponent,
    ProjectUpdateViewComponent,
    MobileAppListViewComponent,
    MobileAppUploadDialogComponent,
    MyProjectListViewComponent,
    BlankViewComponent,
    ProcessingStatusViewComponent,
    MobileAppDetailViewComponent,
    TaskViewComponent,
    UserProfilePopoverComponent,
    QnaListViewComponent,
    QnaDetailViewComponent,
    RunningTaskListViewComponent,
    ReplyViewComponent,
    QnaCreateViewComponent,
    UserPasswordResetDialogComponent,
    TaskListViewComponent,
    TaskOptionBadgeViewComponent,
    TaskFindViewComponent,
    AndroidAppDetailViewComponent,
    IosAppDetailViewComponent,
    ProjectOptionViewComponent,    
    EllipsisPipe,
    TaskOptionViewComponent,
    TimezoneSelectorComponent,
    ProjectConfigViewComponent,
    TaskConfigViewComponent,
    TaskHistoryConfigViewComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    CookieModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RoutesModule,
    FormsModule,
    FocusModule.forRoot(),
    NgxFilesizeModule,
    NgSelectModule  
  ],
  entryComponents: [
    MessageBoxComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    KeyStoreSearchDialogComponent,
    UserSearchDialogComponent,
    MobileAppUploadDialogComponent,
    ProcessingStatusViewComponent,
    UserPasswordResetDialogComponent
  ],
  providers: [
    AuthService,
    CommonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
