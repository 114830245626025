import { Component } from '@angular/core';
import { Logger } from '../../../environments/environment';
import { ConfigViewComponent } from '../../common/config-view/config-view.component';
import { CommonService } from '../../service/common.service';

let log = Logger("TaskConfigView");

@Component({
  selector: 'task-config-view',
  templateUrl: './task-config-view.component.html',
  styleUrls: ['./task-config-view.component.css']
})
export class TaskConfigViewComponent extends ConfigViewComponent {
  constructor(protected common: CommonService) {
    super(common);
  }
}
