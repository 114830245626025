import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService, UserList, User, ProjectList, Project } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../environments/environment';
import { Collection } from '../../service/cj';
import { Subscription } from 'rxjs';

let log = Logger('MyProjectListView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-my-project-list-view',
  templateUrl: './my-project-list-view.component.html',
  styleUrls: ['./my-project-list-view.component.css']
})
export class MyProjectListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public state: State = State.none;
  public projects: ProjectList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private router: Router) {

    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${this.page}`);
    this.getList(this.page);
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.getList(this.page);
  }

  ngOnInit() {
  }

  public loadPage(page: number) {
    this.router.navigate([environment.PATH.MY_PROJECT_LIST], { queryParams: { page } });
  }

  public loadItem(project: Project) {
    this.router.navigate([environment.PATH.MY_PROJECT_LIST, project.href]);
  }

  
  public getList(pageIndex: number = 0) {
    log(`getList > get list page --->`,pageIndex);
    this.state = State.loading;    
    this.auth.getProjectList(pageIndex).then(list => {
      this.state = State.none;

      log(`getList > result ---> `, list);
      this.projects = list;
      this.pg.update(list.page.pageIndex, list.page.itemsPerPage, list.page.totalItems);
      this.updated.emit({
        pageIndex: list.page.pageIndex, total: list.page.totalItems
      });

    }).catch(e => {
      this.state = State.none;
      log(`getList > error ---> `, e);
    })
  }
}
