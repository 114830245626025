<div class="row">
  <div class="col-sm-12 offset-sm-1">
    <h4>{{'qna-create.title' | translate}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row mb-2" [hidden]="!has(TEMPLATE.TITLE)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'qna.title' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <input type="text" class="form-control" [(ngModel)]="title" [disabled]="isProcessing" required>
    </div>
  </div>
  <div class="row mb-2" [hidden]="!has(TEMPLATE.CONTENT)">
    <div class="col-sm-2 text-right text-primary">
      <span>{{'qna.content' | translate}}*</span>
    </div>
    <div class="col-sm-8">
      <textarea row="10" class="form-control" [(ngModel)]="content" [disabled]="isProcessing"></textarea>
    </div>
  </div>

  <div class="row mb-2" [hidden]="!has(TEMPLATE.PUBLIC)">
    <div class="col-sm-2 text-right">
    </div>
    <div class="col-sm-9">
      <input type="checkbox" id="qna-public" [checked]="get(TEMPLATE.PUBLIC)" (change)="set(TEMPLATE.PUBLIC, $event.target.checked)" [disabled]="isProcessing">&nbsp;
      <label class="form-check-label" for="qna-public">{{'qna-create.label.public' | translate}}</label>
    </div>
  </div>

  <div class="row mb-2" [hidden]="!has(TEMPLATE.NOTICE)">
    <div class="col-sm-2 text-right">
    </div>
    <div class="col-sm-9">
      <input type="checkbox" id="qna-notice" [checked]="get(TEMPLATE.NOTICE)" (change)="set(TEMPLATE.NOTICE, $event.target.checked)" [disabled]="isProcessing">&nbsp;
      <label class="form-check-label" for="qna-notice">{{'qna-create.label.notice' | translate}}</label>
    </div>
  </div>


</div>


<hr>
<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="create()">
      <div [hidden]="isProcessing">
        {{'qna-create.button.create' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>
    </button>
  </div>
</div>