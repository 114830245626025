<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_TITLE)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.alert-title' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_TITLE)"
            (change)="set(TEMPLATE.CONFIG_ALERT_TITLE, $event.target.value)" [readonly]="readOnly">
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.alert-exit-button' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)"
            (change)="set(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON, $event.target.value)" [readonly]="readOnly">
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.alert-restart-button' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)"
            (change)="set(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON, $event.target.value)" [readonly]="readOnly">
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-compromised' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_COMPROMISED, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-unsafe-env' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control"
            [value]="get(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-frida-detected' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-not-permitted-time' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control"
            [value]="get(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_INSTALLING)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-installing' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_INSTALLING)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_INSTALLING, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_RESTART)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-restart' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_RESTART)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_RESTART, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.msg-manual-restart' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_USE, TEMPLATE.CONFIG_ALERT_SHOW)">
    <div class="col-sm-12">
    </div>
    <div class="col-sm-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_SHOW)">
        <div class="form-check">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" [checked]="get(TEMPLATE.CONFIG_ALERT_SHOW)"
                (click)="clickOption(TEMPLATE.CONFIG_ALERT_SHOW, $event)">
                {{'config.alert-show' | translate}}
            </label>
        </div>
    </div>
    <div class="col-sm-2" [hidden]="!existAny(TEMPLATE.CONFIG_USE)">
        <div class="form-check">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" [checked]="get(TEMPLATE.CONFIG_USE)"
                (click)="clickOption(TEMPLATE.CONFIG_USE, $event)">
                {{'config.use' | translate}}
            </label>
        </div>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">
    <div class="col-sm-12">
        <small class="text-success">
            <strong>{{'config.bg-image' | translate}}</strong>
        </small>
    </div>
    <div class="col-sm-5">
        <ul class="list-group col-sm p-0">
            <li class="list-group-item p-2 pl-3 pr-3 d-flex justify-content-between align-items-center">
                <img [src]="getImageSource(TEMPLATE.CONFIG_BACKGROUND_IMAGE)" width="100%"
                    [hidden]="!isImageExist(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">
                <small [hidden]="isImageExist(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">{{"common.none" | translate}}</small>
            </li>
        </ul>
    </div>
    <div class="col-sm-2 pl-0 pt-2" [hidden]="readOnly">
        <label class="btn btn-primary">
            {{'config.change-image' | translate}}
            <input type="file" class="custom-file-input"
                (change)="onFileChange(TEMPLATE.CONFIG_BACKGROUND_IMAGE, $event)" [hidden]="true">
        </label>
    </div>
</div>