import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService, UserList, User, ProjectList, Project, PARAM, PLATFORM } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CommonService, DateValue } from '../../service/common.service';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

let log = Logger('ProjectListView');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.NAME, "name"],
  [PARAM.PACKAGE, "packageFilter"],
  [PARAM.MANAGER_ID, "manager-id"],
  [PARAM.MANAGER_NAME, "manager-name"],
  [PARAM.NOTE, "note"],
  [PARAM.DATE, "date"],
]);

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-project-list-view',
  templateUrl: './project-list-view.component.html',
  styleUrls: ['./project-list-view.component.css']
})
export class ProjectListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public PARAM = PARAM;
  public PLATFORM = PLATFORM;
  public state: State = State.none;
  public projects: ProjectList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;

  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;

  public startDate: DateValue;
  public endDate: DateValue;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService,
    private router: Router) {

    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params[PARAM.PAGE] || 0);
    log(`page parameter ---> ${this.page}`);

    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && params.hasOwnProperty(k)) {
        this.searchField = k;
        this.searchValue = params[k];

        if (this.searchField == PARAM.DATE) {
          log(`onParamChanged > searchField --->`, this.searchField)
          log(`onParamChanged > searchValue --->`, this.searchValue)
          let s = this.searchValue.split("~");
          log(`onParamChanged > splited --->`, s)
          if (s.length >= 1) {
            this.startDate = DateValue.readDateValue(s[0]);
            log(`onParamChanged > start --->`, this.startDate);
          }
          if (s.length >= 2) {
            this.endDate = DateValue.readDateValue(s[1]);
            log(`onParamChanged > end --->`, this.endDate);
          }
        }
        found = true;
        log(`selected search field ---> ${k}, value ---> ${this.searchValue}`);
      }
    });
    this.load();
  }


  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  ngOnInit() {
  }

  public loadPage(page: number) {
    if (this.searchField == PARAM.DATE) {
      if ((this.common.isDateSpecified(this.startDate) || this.common.isDateSpecified(this.endDate)) && !this.common.isDateRangeValid(this.startDate, this.endDate)) {
        this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
        return;
      }
      this.searchValue = this.common.dateValueToDateRange(this.startDate, this.endDate);
    }

    let queryParams = {
      page,
      [this.searchField]: this.searchValue
    }

    log(`loadPage > queryParams --->`, queryParams);
    this.router.navigate([environment.PATH.PROJECT_LIST], { queryParams });
  }

  public loadItem(project: Project) {
    this.router.navigate([environment.PATH.PROJECT_LIST, project.href]);
  }

  public createItem() {
    this.router.navigate([environment.PATH.PROJECT_LIST, "create"]);
  }

  public viewAppList(project: Project) {
    this.router.navigate([environment.PATH.MY_PROJECT_LIST, project.href]);
  }

  public updateProject(project: Project) {
    this.router.navigate([environment.PATH.PROJECT_LIST, project.href, "update"]);
  }

  public load() {
    this.state = State.loading;
    this.common.anyway(this.auth.getProjectSearchQueryData()).then(data => {
      this.state = State.none;
      log(`project-search query data ---> `, data);
      this.searchItems = data;
      this.selectSearchField();

      this.search(this.page);

    }).catch(e => {
      this.state = State.none;
      log(`load > error ---> ${e}`);
    })
  }

  public list() {
    this.common.anyway(this.auth.getProjectList(this.page)).then(projects => {
      log(`project list ---> `, projects);
      this.projects = projects;
      this.pg.update(projects.page.pageIndex, projects.page.itemsPerPage, projects.page.totalItems);
      this.state = State.none;
    })
  }

  public selectSearchField() {
    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);

    let findInSearchField = (k: string) => this.searchField == k;
    let findInSearchItems = (k: string) => this.searchItems.has(k);

    let find = (this.searchField) ? findInSearchField : findInSearchItems;
    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && find(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.PAGE, page)

    this.auth.searchProject(this.searchItems).then(projects => {
      log(`project-search result ---> `, projects);
      this.projects = projects;
      this.pg.update(projects.page.pageIndex, projects.page.itemsPerPage, projects.page.totalItems);
      this.state = State.none;
    }).catch(code => {
      this.state = State.none;
      log(`search > project-search error ---> `, code);
      this.common.showError("common.error", code);
    });
  }


  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    if ((this.searchField == PARAM.DATE) && (f != PARAM.DATE)) {
      this.searchValue = "";
    }
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
    log(`set > searchItems --->`, this.searchItems)
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }
}
