<div>
  <div class="btn-group btn-group-sm mr-2">
    <button type="button" class="btn btn-secondary" [ngClass]="{'p-0 pl-2 pr-2': small}" [disabled]="!hasPrevPage" (click)="prevPage()">
      {{"pagenation.button.prev-page" | translate}}
    </button>
  </div>

  <div class="btn-group btn-group-sm mr-2">
    <button type="button" class="btn btn-secondary" [ngClass]="{'p-0 pl-2 pr-2': small}" [disabled]="page == p" (click)="pageClicked(p)"
      *ngFor="let p of pages">
      {{p+1}}
    </button>
  </div>

  <div class="btn-group btn-group-sm">
    <button type="button" class="btn btn-secondary" [ngClass]="{'p-0 pl-2 pr-2': small}" [disabled]="!hasNextPage" (click)="nextPage()">
      {{"pagenation.button.next-page" | translate}}
    </button>
  </div>
</div>