<div class="modal-header">
  <h4 class="modal-title">{{'keystore-search.title' | translate}}</h4>
  <span class="close" aria-label="Close" (click)="cancel()">&times;</span>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 input-group mb-3">
      <div class="input-group-prepend">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
            {{'keystore.'+selectedFieldName | translate}}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'keystore.name' | translate}}</button>
            <button [hidden]="!has(PARAM.NOTE)" class="dropdown-item" (click)="set(PARAM.NOTE)">{{'keystore.note' | translate}}</button>
            <button [hidden]="!has(PARAM.COMMON_NAME)" class="dropdown-item" (click)="set(PARAM.COMMON_NAME)">{{'keystore.common-name' | translate}}</button>
            <button [hidden]="!has(PARAM.ORGANIZATION_UNIT)" class="dropdown-item" (click)="set(PARAM.ORGANIZATION_UNIT)">{{'keystore.organization-unit' | translate}}</button>
            <button [hidden]="!has(PARAM.ORGANIZATION)" class="dropdown-item" (click)="set(PARAM.ORGANIZATION)">{{'keystore.organization' | translate}}</button>
            <button [hidden]="!has(PARAM.LOCALITY)" class="dropdown-item" (click)="set(PARAM.LOCALITY)">{{'keystore.locality' | translate}}</button>
            <button [hidden]="!has(PARAM.STATE)" class="dropdown-item" (click)="set(PARAM.STATE)">{{'keystore.state' | translate}}</button>
            <button [hidden]="!has(PARAM.COUNTRY_CODE)" class="dropdown-item" (click)="set(PARAM.COUNTRY_CODE)">{{'keystore.country-code' | translate}}</button>
            <button [hidden]="!has(PARAM.ALIAS)" class="dropdown-item" (click)="set(PARAM.ALIAS)">{{'keystore.alias' | translate}}</button>
          </div>
        </div>
      </div>
      <input type="text" class="form-control" (input)="updateSearch($event.target.value)" [disabled]="state != State.none">
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" [disabled]="state != State.none" (click)="search()">{{'common.search'|translate}}</button>
      </div>
    </div>
  </div>

  <div [hidden]="state != State.loading">
    <div class="media">
      <div class="media-left">
        <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
      </div>
      <div class="media-body">
        <h4 class="media-heading">{{'common.loading' | translate}}</h4>
        {{'common.loading-please-wait' | translate}}
      </div>
    </div>
  </div>
  <div [hidden]="state == State.loading">

    <table class="table table-sm table-hover table-rounded">
      <tbody>
        <tr *ngFor="let ks of keystores?.items" class="row m-0" scope="row" (click)="toggleSelection(ks)">
          <td class="col-sm-12 d-flex justify-content-between">
            <div class="ml-2">
              <input type="checkbox" id="select" [checked]="isSelected(ks)" (change)="select(ks, $event.target.value)" [disabled]="state == State.loading">&nbsp;
            </div>
            <div class="col-sm pl-1">
              <span [class.text-primary]="isSelected(ks)" [class.text-success]="ks.uploaded && !isSelected(ks)">
                <span [hidden]="!ks?.name" class="text-wrap">
                  <i [hidden]="ks?.uploaded" class="fa fa-key mr-1"></i>
                  <i [hidden]="!ks?.uploaded" class="fa fa-cloud-upload mr-1"></i>
                  {{ks?.name}}
                </span>
                <span [hidden]="!ks?.alias" class="text-wrap">/ {{ks?.alias}}</span>
                <br>
                <span [hidden]="!ks?.commonName" class="text-wrap">{{'keystore.common-name'|translate}}: {{ks?.commonName}}</span>
                <span [hidden]="!ks?.organizationUnit" class="text-wrap">, {{'keystore.organization-unit'|translate}}: {{ks?.organizationUnit}}</span>
                <span [hidden]="!ks?.organization" class="text-wrap">, {{'keystore.organization'|translate}}: {{ks?.organization}}</span>
                <span [hidden]="!ks?.locality" class="text-wrap">, {{'keystore.locality'|translate}}: {{ks?.locality}}</span>
                <span [hidden]="!ks?.state" class="text-wrap">, {{'keystore.state'|translate}}: {{ks?.state}}</span>
                <span [hidden]="!ks?.countryCode" class="text-wrap">, {{'keystore.country-code'|translate}}: {{ks?.countryCode}}</span>
                <span [hidden]="!ks?.note" class="text-wrap">, {{ks?.note}}</span>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between">
      <div></div>
      <pagenation class="text-center" #pg (pageClick)="search($event)"></pagenation>
      <span class="float-right">{{'keystore-list.label.total-keystore'|translate:keystores?.page}}</span>
    </div>

  </div>

</div>


<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">{{'common.ok' | translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">{{'common.cancel' | translate}}</button>
</div>