import { PATH } from "./config";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  canDeleteAdminAccount: false,
  LOGS: {
    UserSearchDialog: false,
    CommonService: false,
    AppComponent: false,
    UserListView: false,
    UserDetailView: false,
    UserUpdateView: false,
    UserCreateView: false,
    KeyStoreListView: false,
    KeyStoreDetailView: false,
    KeyStoreCreateView: false,
    KeyStoreAddView: false,
    ProjectListView: false,
    ProjectDetailView: false,
    ProjectCreateView: false,
    ProjectUpdateView: false,
    ProjectOptionView: false,
    ProjectConfigView: false,
    MyProjectListView: false,
    MobileAppUploadDialog: false,
    MobileAppListView: false,
    MobileAppDetailView: false,
    QnaListView: false,
    QnaDetailView: false,
    RunningTaskListView: false,
    QnaCreateView: false,
    TaskListView: false,
    TaskFindView: false,
    TaskView: false,
    TaskOptionView: false,
    ReplyView: false,
    cj: false,
    AuthService: false,
    Pagenation: false,
    UserSearch: false,  
    TimeZoneSelector: false
  },
  VALID_APP_EXTENSIONS: [".apk", ".aab", ".xcarchive.zip", ".app.zip"],

  DEFAULT_LANGUAGE: "en",
  ITEMS_PER_PAGE: 10,
  SPLASH_DELAY: 1,
  TASK_LIST_UPDATE_INTERVAL: 10,
  ADMIN_INITIAL_VIEW_PATH: PATH.USER_LIST,
  USER_INITIAL_VIEW_PATH: PATH.MY_PROJECT_LIST,
  PATH,
  API_URL: "web-api"
};

export function Logger(name: string) {
  return (environment.LOGS[name] === true)? console.log.bind(console, `${name} >`) : (...args:any[]) => {};
}
