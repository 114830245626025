<div class="row">
    <div class="col-sm-12 ml-4">
        <h4>{{'user-detail.title' | translate}}</h4>
    </div>
</div>
<hr>
<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.id' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{user?.id}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.name' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{user?.name}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.department' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{user?.department}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.email' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{user?.email}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.tel' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{user?.tel}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.note' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span class="multi-line-text">{{user?.note}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.state' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{'user-state.'+user?.state | translate}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 text-right">
            <span>{{'user.role' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <span>{{'user-role.'+user?.role | translate}}</span>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary mr-3" [disabled]="state != State.none" (click)="goBack()">
            {{'common.go-back' | translate}}
        </button>

        <div class="d-inline" [hidden]="user?.role == USER_ROLE.ADMIN">
            <button type="submit" class="btn btn-primary mr-1" [disabled]="state != State.none" (click)="update()">
                {{'user-detail.button.update' | translate}}
            </button>
            <button type="submit" class="btn btn-danger mr-1" [hidden]="!canDelete()" [disabled]="state != State.none" (click)="delete()">
                <div [hidden]="isProcessing">
                    {{'user-detail.button.delete' | translate}}
                </div>
                <div [hidden]="!isProcessing">
                    <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
                    {{'common.processing' | translate}}
                </div>

            </button>
            <button type="submit" class="btn btn-warning mr-1" [disabled]="state != State.none" (click)="resetPassword()">
                <div [hidden]="isResetting">
                    {{'user-detail.button.reset-password' | translate}}
                </div>
                <div [hidden]="!isResetting">
                    <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
                    {{'common.processing' | translate}}
                </div>
            </button>
        </div>
    </div>
</div>