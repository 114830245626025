import { Injectable } from '@angular/core';
import { environment, Logger } from "../../environments/environment";
import { CanActivate, ResolveEnd, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CollectionJson, LinkedItem, Collection, UploadCallback, Template, TemplateList } from './cj';
import { DomSanitizer } from '@angular/platform-browser';




const COOKIE = {
    TOKEN: "angelscutum-auth-token"
}

enum State {
    unknown, unauthenticated, authenticated
}

export enum PasswordChangeReason {
    unknown,
    expired
}

export const OPTIONAL_BOOLEAN = {
    TRUE: "true",
    FALSE: "false",
    NONE: "none"
}

export const REL = {
    CHANGE_PASSWORD: "change-password",
    AUTHENTICATION: "authentication",
    LOGOUT: "logout",
    USER_MANAGEMENT: "user-management",
    PROJECT_MANAGEMENT: "project-management",
    KEYSTORE_MANAGEMENT: "keystore-management",
    TASK_MANAGEMENT: "task-management",
    QNA_MANAGEMENT: "qna-management",
    IDENTITY: "identity",
    FILE: "file",
    ADD: "add",
    ICON: "icon",
    GET_PAGE: "get-page",
    KEYSTORE: "keystore",
    MANAGER: "manager",
    OPTION: "option",
    CONFIG: "config",
    PROJECT: "project",
    CREATOR: "creator",
    SEARCH: "search",
    MOBILE_APP: "mobile-app",
    FILE_UPLOAD: "file-upload",
    TASK: "task",
    RESULT: "result",
    LOG: "log",
    MAP: "map",
    WRITER: "writer",
    REPLY: "reply",
    TEMPLATE: "template",
    PROJECT_OPTION_TEMPLATE_LIST: "project-option-template-list",
    PROJECT_CONFIG_TEMPLATE_LIST: "project-config-template-list",
    RESET_PASSWORD: "reset-password",
    EXPORT_TO_CSV: "export-csv",
    FIND: "find",
    BY_PACKAGE_NAME: "by-package-name",
    BY_HASH: "by-hash",
    STATISTICS: "statistics",
    SUBMIT: "submit"
}

export const TEMPLATE = {
    ID: "id",
    PASSWORD: "password",
    NEW_PASSWORD: "new-password",
    NAME: "name",
    EMAIL: "email",
    TEL: "tel",
    DEPARTMENT: "department",
    TEMPORARY_PASSWORD: "temporary-password",
    ROLE: "role",
    NOTE: "note",
    STATE: "state",
    DATE: "date",
    COMMON_NAME: "common-name",
    ORGANIZATION_UNIT: "organization-unit",
    ORGANIZATION: "organization",
    LOCALITY: "locality",
    COUNTRY_CODE: "country-code",
    ALIAS: "alias",
    ALGORITHM: "algorithm",
    VALIDITY: "validity",
    VALID_FROM: "valid-from",
    VALID_TO: "valid-to",
    DATA: "data",
    ICON: "icon",
    PACKAGE: "package",
    STORE_PASS: "store-pass",
    KEY_PASS: "key-pass",
    KEYSTORE: "keystore",
    MANAGERS: "managers",
    HASH: "hash",
    CREATE_TIME: "create-time",
    COMPLETE_TIME: "complete-time",
    UPLOADED: "uploaded",
    DETAILED_VERSION: "detailed-version",
    MINIMUM_OS_VERSION: "minimum-os-version",
    TARGET_OS_VERSION: "target-os-version",

    BUNDLE_NAME: "bundle-name",
    SDK_NAME: "sdk-name",
    PLATFORM_NAME: "platform-name",
    PLATFORM_VERSION: "platform-version",
    SUPPORTED_PLATFORMS: "supported-platforms",
    BACKGROUND_MODES: "background-modes",
    REQUIRED_DEVICE_CAPABILITIES: "required-device-capabilities",


    ENCRYPT_CODE: "encrypt-code",
    ENCRYPT_STRING: "encrypt-string",
    ENCRYPT_RESOURCE: "encrypt-resource",
    ENCRYPT_STRING_FILTER: "encrypt-string-filter",
    ENCRYPT_CODE_SPLIT_RULE: "encrypt-code-split-rule",

    PREVENT_REPACKAGE: "prevent-repackage",
    PREVENT_DECOMPILE: "prevent-decompile",
    PREVENT_SCREEN_CAPTURE: "prevent-screen-capture",
    PREVENT_CLIPBOARD_USAGE: "prevent-clipboard-usage",
    PREVENT_CONTROL_USAGE_TIME: "prevent-control-usage-time",
    PREVENT_USAGE_TIME: "prevent-usage-time",
    PREVENT_USAGE_TIME_ZONE: "prevent-usage-time-zone",

    OBFUSCATE_CLASS_NAME: "obfuscate-class-name",
    OBFUSCATE_METHOD_NAME: "obfuscate-method-name",
    OBFUSCATE_FIELD_NAME: "obfuscate-field-name",
    OBFUSCATE_FILTER: "obfuscate-filter",
    OBFUSCATE_TARGET_PACKAGE: "obfuscate-target-package",
    OBFUSCATE_PROTOCOL_NAME: "obfuscate-protocol-name",
    OBFUSCATE_CATEGORY_NAME: "obfuscate-category-name",
    OBFUSCATE_PROPERTY_NAME: "obfuscate-property-name",

    DETECT_ROOTING: "detect-rooting-device",
    DETECT_EMULATOR: "detect-emulator",
    DETECT_DEBUGGER: "detect-debugger",
    DETECT_FRIDA: "detect-frida",
    DETECT_FORGERY: "detect-forgery",
    DETECT_FORGERY_SERVER: "detect-forgery-server",

    REMOVE_DEBUG_INFO: "remove-debug-info",
    REMOVE_LOGCAT_LOG: "remove-logcat-log",


    FILE_SIZE: "file-size",
    FILE_NAME: "file-name",
    VERSION_CODE: "version-code",
    VERSION: "version",
    SDK_VERSION: "sdk-version",
    TARGET_SDK: "target-sdk",
    DEX_COUNT: "dex-count",
    USES_PERMISSIONS: "uses-permissions",
    USES_LIBRARIES: "uses-libraries",
    USES_FEATURES: "uses-features",
    SUPPORTED_SCREENS: "supported-screens",
    NATIVE_CODES: "native-codes",
    DENSITIES: "densities",
    DEBUGGABLE: "debuggable",

    NO: "no",
    DEPTH: "depth",
    TITLE: "title",
    CONTENT: "content",
    PUBLIC: "public",
    NOTICE: "notice",
    USER_NAME: "user-name",
    DONT_SIGN: "dont-sign",
    SIGNED: "signed",

    TOTAL_APP: "total-app",
    TOTAL_TASK: "total-task",

    PLATFORM: "platform",

    CONFIG_ALERT_TITLE: "config-alert-title",
    CONFIG_ALERT_EXIT_BUTTON: "config-alert-exit-button",
    CONFIG_ALERT_RESTART_BUTTON: "config-alert-restart-button",
    CONFIG_ALERT_SHOW: "config-alert-show",
    CONFIG_MESSAGE_COMPROMISED: "config-message-compromised",
    CONFIG_MESSAGE_UNSAFE_ENVIRONMENT: "config-message-unsafe-env",
    CONFIG_MESSAGE_FRIDA_DETECTED: "config-message-frida-detected",
    CONFIG_MESSAGE_NOT_PERMITTED_TIME: "config-message-not-permitted-time",
    CONFIG_MESSAGE_INSTALLING: "config-message-installing",
    CONFIG_MESSAGE_RESTART: "config-message-restart",
    CONFIG_MESSAGE_MANUAL_RESTART: "config-message-manual-restart",
    CONFIG_USE: "config-use",
    CONFIG_BACKGROUND_IMAGE: "config-background",
    CONFIGS: "configs"
}


export const PARAM = {
    OFFSET: "offset",
    LIMIT: "limit",
    TOTAL: "total",
    PAGE: "page",
    NAME: "n",
    DEPARTMENT: "d",
    EMAIL: "e",
    TEL: "t",
    ID: "i",
    ROLE: "r",
    NOTE: "nt",
    ALIAS: "a",
    COMMON_NAME: "cn",
    ORGANIZATION_UNIT: "ou",
    ORGANIZATION: "o",
    LOCALITY: "l",
    STATE: "s",
    COUNTRY_CODE: "cc",
    MANAGER_ID: "mi",
    MANAGER_NAME: "mn",
    MANAGER_KEY: "mk",
    PACKAGE: "p",
    DATE: "dt",
    VERSION_CODE: "vc",
    VERSION: "v",
    SDK_VERSION: "sv",
    TARGET_SDK: "ts",
    TITLE: "tt",
    CONTENT: "ct",
    NO: "no",
    EXPIRE_DATE: "ed",
    ALGORITHM: "ar",
    HASH: "h",
    PROJECT_NAME: "pn",
    PLATFORM: "pf",

    EXACT: "ex",
    ALL: "all",
}

export const TASK_STATE = {
    CANCEL: "cancel",
    RUNNING: "running",
    CREATED: "created",
    COMPLETE: "completed"
}

export const USER_STATE = {
    APPROVED: "approved",
    REGISTERED: "registered",
    CHANGE_PASSWORD: "changePassword",
    BLOCKED: "blocked",
    TEMPORARILY_BLOCKED: "temporarilyBlocked",
    PASSWORD_EXPIRED: "passwordExpired"
}

export const USER_ROLE = {
    ADMIN: "admin",
    USER: "user"
}

export const PLATFORM = {
    ANDROID: "android",
    IOS: "ios"
}


export enum AuthError {
    UNKNOWN,
    NOT_FOUND,
    UNSUPPORTED_CONTENT_TYPE,
    UNKNOWN_USER_OR_INCORRECT_PASSWORD,
    UNAUTHORIZED,
    TOKEN_EXPIRED,
    BLOCKED_ACCOUNT,
    PASSWORD_CHANGE_REQUIRED,
    ACCOUNT_NOT_APPROVED,
    BAD_REQUEST,
    USE_OF_PREVIOUS_PASSWORD_AS_NEW_PASSWORD_PROHIBITED,
    MISSING_REQUIRED_FIELDS,
    ID_CONFLICT,
    ID_DOES_NOT_CONFIRM_TO_RULE,
    PASSWORD_DOES_NOT_CONFIRM_TO_RULE,
    INVALID_EMAIL,
    INVALID_PACKAGE_NAME,
    INVALID_APK_FILE,
    FILE_UNAVAILABLE,
    FILE_NOT_FOUND,
    STORAGE_SERVER_DOWN_OR_FULL,
    ENGINE_SERVER_DOWN,
    PACKAGE_NAME_NOT_MATCH,
    INVALID_STATE,
    TITLE_IS_EMPTY,
    CONTENT_IS_EMPTY,
    USER_TASK_COUNT_LIMITED,
    PROJECT_TASK_COUNT_LIMITED,
    TASK_ALREADY_DONE,
    TEMPORARILY_BLOCKED,
    TOTAL_TASK_COUNT_LIMITED,
    USER_CANCELED,
    PASSWORD_EXPIRED,
    NOT_ALLOWED,
    UNKNOWN_PLATFORM,
    CONNECTION_ERROR
}

const CONNECTION_ERROR_CODE = "E00000"



const ServerErrorToAuthErrorMap = new Map<string, AuthError>([
    [CONNECTION_ERROR_CODE, AuthError.CONNECTION_ERROR],
    ["E10000", AuthError.UNKNOWN],
    ["E10001", AuthError.NOT_FOUND],
    ["E10002", AuthError.UNSUPPORTED_CONTENT_TYPE],
    ["E10003", AuthError.UNKNOWN_USER_OR_INCORRECT_PASSWORD],
    ["E10004", AuthError.UNAUTHORIZED],
    ["E10005", AuthError.TOKEN_EXPIRED],
    ["E10006", AuthError.BLOCKED_ACCOUNT],
    ["E10007", AuthError.PASSWORD_CHANGE_REQUIRED],
    ["E10008", AuthError.ACCOUNT_NOT_APPROVED],
    ["E10009", AuthError.BAD_REQUEST],
    ["E10010", AuthError.USE_OF_PREVIOUS_PASSWORD_AS_NEW_PASSWORD_PROHIBITED],
    ["E10011", AuthError.MISSING_REQUIRED_FIELDS],
    ["E10012", AuthError.ID_CONFLICT],
    ["E10013", AuthError.ID_DOES_NOT_CONFIRM_TO_RULE],
    ["E10014", AuthError.PASSWORD_DOES_NOT_CONFIRM_TO_RULE],
    ["E10015", AuthError.INVALID_EMAIL],
    ["E10016", AuthError.INVALID_PACKAGE_NAME],
    ["E10017", AuthError.INVALID_APK_FILE],
    ["E10018", AuthError.FILE_UNAVAILABLE],
    ["E10019", AuthError.FILE_NOT_FOUND],
    ["E10020", AuthError.STORAGE_SERVER_DOWN_OR_FULL],
    ["E10021", AuthError.ENGINE_SERVER_DOWN],
    ["E10022", AuthError.PACKAGE_NAME_NOT_MATCH],
    ["E10023", AuthError.INVALID_STATE],
    ["E10024", AuthError.TITLE_IS_EMPTY],
    ["E10025", AuthError.CONTENT_IS_EMPTY],
    ["E10026", AuthError.USER_TASK_COUNT_LIMITED],
    ["E10027", AuthError.PROJECT_TASK_COUNT_LIMITED],
    ["E10028", AuthError.TASK_ALREADY_DONE],
    ["E10029", AuthError.TEMPORARILY_BLOCKED],
    ["E10030", AuthError.TOTAL_TASK_COUNT_LIMITED],
    ["E10031", AuthError.PASSWORD_EXPIRED],
    ["E10032", AuthError.NOT_ALLOWED],
    ["E10033", AuthError.UNKNOWN_PLATFORM],
]);


export class User implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly id: string;
    readonly name: string;
    readonly department: string;
    readonly email: string;
    readonly tel: string;
    readonly note: string;
    readonly role: string;
    readonly state: string;


    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);
        this.id = obj[TEMPLATE.ID];
        this.name = obj[TEMPLATE.NAME];
        this.department = obj[TEMPLATE.DEPARTMENT];
        this.email = obj[TEMPLATE.EMAIL];
        this.tel = obj[TEMPLATE.TEL];
        this.note = obj[TEMPLATE.NOTE];
        this.role = obj[TEMPLATE.ROLE];
        this.state = obj[TEMPLATE.STATE];
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public get isBlocked(): boolean {
        return this.state == USER_STATE.BLOCKED;
    }

    public get needPasswordChange(): boolean {
        return this.state == USER_STATE.CHANGE_PASSWORD;
    }

    public get isPasswordExpired(): boolean {
        return this.state == USER_STATE.PASSWORD_EXPIRED;
    }

    public static create(obj: any, href: string, links: Map<string, string>): User {
        if (!obj) {
            return null;
        }

        let user = new User(obj, href, links);
        return user;
    }
}

export class KeyStore implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly name: string;
    readonly note: string;
    readonly date: Date;
    readonly commonName: string;
    readonly organizationUnit: string;
    readonly organization: string;
    readonly locality: string;
    readonly state: string;
    readonly countryCode: string;
    readonly alias: string;
    readonly algorithm: string;
    readonly validity: number;
    readonly validFrom: string;
    readonly validTo: string;
    readonly uploaded: boolean;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);
        this.name = obj[TEMPLATE.NAME];
        this.note = obj[TEMPLATE.NOTE];
        this.date = new Date(obj[TEMPLATE.DATE]);
        this.commonName = obj[TEMPLATE.COMMON_NAME];
        this.organizationUnit = obj[TEMPLATE.ORGANIZATION_UNIT];
        this.organization = obj[TEMPLATE.ORGANIZATION];
        this.locality = obj[TEMPLATE.LOCALITY];
        this.state = obj[TEMPLATE.STATE];
        this.countryCode = obj[TEMPLATE.COUNTRY_CODE];
        this.alias = obj[TEMPLATE.ALIAS];
        this.algorithm = obj[TEMPLATE.ALGORITHM];
        this.validity = obj[TEMPLATE.VALIDITY];
        this.validFrom = obj[TEMPLATE.VALID_FROM];
        this.validTo = obj[TEMPLATE.VALID_TO];
        this.uploaded = obj[TEMPLATE.UPLOADED];
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): KeyStore {
        if (!obj) {
            return null;
        }

        let ks = new KeyStore(obj, href, links);
        return ks;
    }
}

export class Project implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly name: string;
    readonly note: string;
    readonly date: Date;
    readonly packageFilter: string;
    readonly platforms: Set<string>;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = links;
        this.name = obj[TEMPLATE.NAME];
        this.note = obj[TEMPLATE.NOTE];
        this.date = new Date(obj[TEMPLATE.DATE]);
        this.packageFilter = obj[TEMPLATE.PACKAGE];
        this.platforms = new Set(obj[TEMPLATE.PLATFORM].split(","));

        return this;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): Project {
        if (!obj) {
            return null;
        }

        let project = new Project(obj, href, links);
        return project;
    }
}

export class ProjectStatistics implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly totalApp: number;
    readonly totalTask: number;

    public getLink(name: string): string {
        return this.links.get(name);
    }

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);
        this.totalApp = obj[TEMPLATE.TOTAL_APP];
        this.totalTask = obj[TEMPLATE.TOTAL_TASK];
    }

    public static create(obj: any, href: string, links: Map<string, string>): ProjectStatistics {
        if (!obj) {
            return null;
        }

        let stats = new ProjectStatistics(obj, href, links);
        return stats;
    }
}

export class Encryption {
    code?: boolean;
    string?: boolean;
    resource?: boolean;

    stringFilter?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.stringFilter;
    }

    get isUse(): boolean {
        return this.code || this.string || this.resource;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.code = obj[TEMPLATE.ENCRYPT_CODE] === OPTIONAL_BOOLEAN.TRUE;
        this.string = obj[TEMPLATE.ENCRYPT_STRING] === OPTIONAL_BOOLEAN.TRUE;
        this.resource = obj[TEMPLATE.ENCRYPT_RESOURCE] === OPTIONAL_BOOLEAN.TRUE;
        this.stringFilter = obj[TEMPLATE.ENCRYPT_STRING_FILTER];
    }
}

export class Prevention {
    repackage?: boolean;
    decompile?: boolean;
    screenCapture?: boolean;
    clipboardUsage?: boolean;
    controlUsageTime?: boolean;

    usageTime?: string;
    usageTimeZone?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.usageTime;
    }

    get isUse(): boolean {
        return this.repackage || this.decompile || this.screenCapture || this.clipboardUsage || this.controlUsageTime;
    }

    get isNotUse(): boolean {
        return !this.isNotUse;
    }

    public read(obj: any) {
        this.repackage = obj[TEMPLATE.PREVENT_REPACKAGE] === OPTIONAL_BOOLEAN.TRUE;
        this.decompile = obj[TEMPLATE.PREVENT_DECOMPILE] === OPTIONAL_BOOLEAN.TRUE;
        this.screenCapture = obj[TEMPLATE.PREVENT_SCREEN_CAPTURE] === OPTIONAL_BOOLEAN.TRUE;
        this.clipboardUsage = obj[TEMPLATE.PREVENT_CLIPBOARD_USAGE] === OPTIONAL_BOOLEAN.TRUE;
        this.controlUsageTime = obj[TEMPLATE.PREVENT_CONTROL_USAGE_TIME] === OPTIONAL_BOOLEAN.TRUE;
        this.usageTime = obj[TEMPLATE.PREVENT_USAGE_TIME];
        this.usageTimeZone = obj[TEMPLATE.PREVENT_USAGE_TIME_ZONE];
    }
}

export class Obfuscation {
    protocolName?: boolean;
    categoryName?: boolean;
    propertyName?: boolean;
    className?: boolean;
    methodName?: boolean;
    fieldName?: boolean;

    filter?: string;
    targetPackage?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.filter && !this.targetPackage;
    }

    get isUse(): boolean {
        return this.className || this.methodName || this.fieldName || this.protocolName || this.categoryName || this.propertyName;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.protocolName = obj[TEMPLATE.OBFUSCATE_PROTOCOL_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.categoryName = obj[TEMPLATE.OBFUSCATE_CATEGORY_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.propertyName = obj[TEMPLATE.OBFUSCATE_PROPERTY_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.className = obj[TEMPLATE.OBFUSCATE_CLASS_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.methodName = obj[TEMPLATE.OBFUSCATE_METHOD_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.fieldName = obj[TEMPLATE.OBFUSCATE_FIELD_NAME] === OPTIONAL_BOOLEAN.TRUE;
        this.filter = obj[TEMPLATE.OBFUSCATE_FILTER];
        this.targetPackage = obj[TEMPLATE.OBFUSCATE_TARGET_PACKAGE];
    }
}

export class Detection {
    rootingDevice?: boolean;
    debugger?: boolean;
    emulator?: boolean;
    frida?: boolean;
    forgery?: boolean;
    forgeryServer?: string;

    get isEmpty(): boolean {
        return this.isNotUse && !this.forgeryServer;
    }

    get isUse(): boolean {
        return this.rootingDevice || this.debugger || this.emulator || this.frida || this.forgery;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.rootingDevice = obj[TEMPLATE.DETECT_ROOTING] === OPTIONAL_BOOLEAN.TRUE;
        this.debugger = obj[TEMPLATE.DETECT_DEBUGGER] === OPTIONAL_BOOLEAN.TRUE;
        this.emulator = obj[TEMPLATE.DETECT_EMULATOR] === OPTIONAL_BOOLEAN.TRUE;
        this.frida = obj[TEMPLATE.DETECT_FRIDA] === OPTIONAL_BOOLEAN.TRUE;
        this.forgery = obj[TEMPLATE.DETECT_FORGERY] === OPTIONAL_BOOLEAN.TRUE;
        this.forgeryServer = obj[TEMPLATE.DETECT_FORGERY_SERVER];
    }
}

export class Removal {
    debugInfo?: boolean;
    logCatLog?: boolean;

    get isEmpty(): boolean {
        return this.isNotUse;
    }

    get isUse(): boolean {
        return this.debugInfo || this.logCatLog;
    }

    get isNotUse(): boolean {
        return !this.isUse;
    }

    public read(obj: any) {
        this.debugInfo = obj[TEMPLATE.REMOVE_DEBUG_INFO] === OPTIONAL_BOOLEAN.TRUE;
        this.logCatLog = obj[TEMPLATE.REMOVE_LOGCAT_LOG] === OPTIONAL_BOOLEAN.TRUE;
    }
}

export class ProjectOption implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly platform: string;
    readonly encrypt = new Encryption();
    readonly obfuscate = new Obfuscation();
    readonly prevent = new Prevention();
    readonly detect = new Detection();
    readonly remove = new Removal();

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.platform = obj[TEMPLATE.PLATFORM]
        this.encrypt.read(obj);
        this.prevent.read(obj);
        this.obfuscate.read(obj);
        this.detect.read(obj);
        this.remove.read(obj);
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    get isEmpty(): boolean {
        return this.encrypt.isEmpty && this.obfuscate.isEmpty &&
            this.prevent.isEmpty && this.detect.isEmpty && this.remove.isEmpty;
    }

    public static create(obj: any, href: string, links: Map<string, string>): ProjectOption {
        if (!obj) {
            return null;
        }

        let opt = new ProjectOption(obj, href, links);
        return opt;
    }
}

function tryParseInt(s: string, defaultValue: number = 0): number {
    try {
        return parseInt(s);
    } catch (e) {
        return defaultValue;
    }
}

function tryParseCommaSeperatedString(s: string): Array<string> {
    try {
        return s.split(",").filter(x => x.trim().length > 0);
    } catch (e) {
        return [];
    }
}

export interface MobileApp extends LinkedItem {
    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    icon: any;
    readonly userName: string;
    readonly platform: string;
    readonly hash: string;
}

export class MobileAppFactory {
    public static create(obj: any, href: string, links: Map<string, string>): MobileApp {
        if (!obj) {
            return null;
        }

        console.log(`MobileAppFactory.create > object ---> ${obj}`)
        var platform = obj[TEMPLATE.PLATFORM];
        console.log(`MobileAppFactory.create > platform ---> ${platform}`)
        if (platform == PLATFORM.ANDROID) {
            return AndroidApp.create(obj, href, links);
        } else if (platform == PLATFORM.IOS) {
            return IosApp.create(obj, href, links);
        } else {
            return null;
        }
    }
}

export class IosApp implements MobileApp {
    readonly href: string;
    private links: Map<string, string>;

    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    readonly icon: any;
    readonly userName: string;
    readonly platform: string;

    readonly bundleName: string;
    readonly bundleDisplayName: string;
    readonly shortVersion: string;
    readonly sdkName: string;
    readonly platformName: string;
    readonly platformVersion: string;

    readonly supportedPlatforms = new Array<string>();
    readonly backgroundModes = new Array<string>();
    readonly requiredDeviceCapabilities = new Array<string>();
    readonly hash: string;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.date = new Date(obj[TEMPLATE.DATE]);
        this.name = obj[TEMPLATE.NAME];
        this.fileSize = tryParseInt(obj[TEMPLATE.FILE_SIZE]);
        this.fileName = obj[TEMPLATE.FILE_NAME];
        this.uniqueAppId = obj[TEMPLATE.PACKAGE];
        this.version = obj[TEMPLATE.VERSION];
        this.detailedVersion = obj[TEMPLATE.DETAILED_VERSION];
        this.minimumOsVersion = obj[TEMPLATE.MINIMUM_OS_VERSION];
        this.targetOsVersion = obj[TEMPLATE.TARGET_OS_VERSION];

        this.userName = obj[TEMPLATE.USER_NAME];
        if (obj[TEMPLATE.ICON]) {
            this.icon = obj[TEMPLATE.ICON];
        }

        this.platform = PLATFORM.IOS;

        this.bundleName = obj[TEMPLATE.BUNDLE_NAME];
        this.bundleDisplayName = this.name;
        this.shortVersion = this.detailedVersion;
        this.sdkName = obj[TEMPLATE.SDK_NAME];
        this.platformName = obj[TEMPLATE.PLATFORM_NAME];
        this.platformVersion = obj[TEMPLATE.PLATFORM_VERSION];

        this.supportedPlatforms = tryParseCommaSeperatedString(obj[TEMPLATE.SUPPORTED_PLATFORMS]);
        this.backgroundModes = tryParseCommaSeperatedString(obj[TEMPLATE.BACKGROUND_MODES]);
        this.requiredDeviceCapabilities = tryParseCommaSeperatedString(obj[TEMPLATE.REQUIRED_DEVICE_CAPABILITIES]);

        this.hash = obj[TEMPLATE.HASH];
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): IosApp {
        if (!obj) {
            return null;
        }

        let app = new IosApp(obj, href, links);
        return app;
    }
}

export class AndroidApp implements MobileApp {
    readonly href: string;
    private links: Map<string, string>;

    readonly date: Date;
    readonly name: string;
    readonly fileSize: number;
    readonly fileName: string;
    readonly uniqueAppId: string;
    readonly version: string;
    readonly detailedVersion: string;
    readonly minimumOsVersion: string;
    readonly targetOsVersion: string;
    readonly icon: any = null;
    readonly userName: string;
    readonly platform: string;

    readonly versionCode: number;
    readonly sdkVersion: number;
    readonly targetSdk: number;

    readonly hash: string;

    readonly dexCount: number;
    readonly debuggable: boolean;
    readonly usesPermissions = new Array<string>();
    readonly usesLibraries = new Array<string>();
    readonly usesFeatures = new Array<string>();
    readonly supportedScreens = new Array<string>();
    readonly nativeCodes = new Array<string>();
    readonly densities = new Array<string>();


    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.date = new Date(obj[TEMPLATE.DATE]);
        this.name = obj[TEMPLATE.NAME];
        this.fileSize = tryParseInt(obj[TEMPLATE.FILE_SIZE]);
        this.fileName = obj[TEMPLATE.FILE_NAME];
        this.uniqueAppId = obj[TEMPLATE.PACKAGE];
        this.version = obj[TEMPLATE.VERSION];
        this.detailedVersion = obj[TEMPLATE.DETAILED_VERSION];
        this.minimumOsVersion = obj[TEMPLATE.MINIMUM_OS_VERSION];
        this.targetOsVersion = obj[TEMPLATE.TARGET_OS_VERSION];

        this.userName = obj[TEMPLATE.USER_NAME];
        if (obj[TEMPLATE.ICON]) {
            this.icon = obj[TEMPLATE.ICON];
        }

        this.platform = PLATFORM.ANDROID;

        this.versionCode = tryParseInt(obj[TEMPLATE.VERSION_CODE]);
        this.sdkVersion = tryParseInt(obj[TEMPLATE.SDK_VERSION]);
        this.targetSdk = tryParseInt(obj[TEMPLATE.TARGET_SDK]);

        this.dexCount = tryParseInt(obj[TEMPLATE.DEX_COUNT]);
        this.usesPermissions = tryParseCommaSeperatedString(obj[TEMPLATE.USES_PERMISSIONS]);
        this.usesLibraries = tryParseCommaSeperatedString(obj[TEMPLATE.USES_LIBRARIES]);
        this.usesFeatures = tryParseCommaSeperatedString(obj[TEMPLATE.USES_FEATURES]);
        this.supportedScreens = tryParseCommaSeperatedString(obj[TEMPLATE.SUPPORTED_SCREENS]);
        this.nativeCodes = tryParseCommaSeperatedString(obj[TEMPLATE.NATIVE_CODES]);
        this.densities = tryParseCommaSeperatedString(obj[TEMPLATE.DENSITIES]);
        this.debuggable = obj[TEMPLATE.DEBUGGABLE];

        this.hash = obj[TEMPLATE.HASH];
    }

    public static create(obj: any, href: string, links: Map<string, string>): AndroidApp {
        if (!obj) {
            return null;
        }

        let app = new AndroidApp(obj, href, links);
        return app;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

}


export class ElapsedTime {
    h: number;
    m: number;
    s: number;
}

export class Task implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly state: string;
    readonly createTime: Date = null;
    readonly completeTime: Date = null;
    readonly hash: string;
    readonly encrypt = new Encryption();
    readonly obfuscate = new Obfuscation();
    readonly prevent = new Prevention();
    readonly detect = new Detection();
    readonly remove = new Removal();
    readonly signed: boolean;
    app: MobileApp = null;
    creator: User = null;
    configs: Array<Template> = null;
    log: string;
    private _obfuscationMap = new Map<string, string>();

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.state = obj[TEMPLATE.STATE];
        this.hash = obj[TEMPLATE.HASH];
        let st = Date.parse(obj[TEMPLATE.CREATE_TIME]);
        this.createTime = isNaN(st) ? null : new Date(st);
        let et = Date.parse(obj[TEMPLATE.COMPLETE_TIME]);
        this.completeTime = isNaN(et) ? null : new Date(et);
        this.signed = obj[TEMPLATE.SIGNED];

        this.encrypt.read(obj);
        this.prevent.read(obj);
        this.obfuscate.read(obj);
        this.detect.read(obj);
        this.remove.read(obj);
    }

    get obfuscationMap(): Map<string, string> {
        return new Map<string, string>(this._obfuscationMap);
    }

    setObfuscationMap(value: string) {
        try {
            this._obfuscationMap.clear();
            let map = JSON.parse(value);
            if (map) {
                let keys = Object.keys(map);
                keys.forEach(key => {
                    if (map[key]) {
                        let value = map[key]["translate"];
                        this._obfuscationMap.set(key, value);
                    }
                });
            }
        } catch (e) {
            log(`Task.setObfuscationMap > json parsing error ---> `, e);
        }
    }

    get isEmpty(): boolean {
        return this.encrypt.isEmpty && this.obfuscate.isEmpty &&
            this.prevent.isEmpty && this.detect.isEmpty && this.remove.isEmpty;
    }

    get isRunning(): boolean {
        return (this.state == TASK_STATE.RUNNING)
    }

    get isComplete(): boolean {
        return (this.state == TASK_STATE.COMPLETE)
    }

    get leadTime(): ElapsedTime {
        if (!this.createTime) {
            return null;
        }

        let n = 0;
        if (this.isRunning) {
            n = Date.now() - this.createTime.getTime();
        } else if (!this.completeTime || !this.createTime) {
            return null;
        } else {
            n = this.completeTime.getTime() - this.createTime.getTime();
        }

        if (Number.isNaN(n)) {
            return null;
        }

        let e = new ElapsedTime();
        e.h = Math.floor(n / 3600000);
        n -= e.h * 3600000;
        e.m = Math.floor(n / 60000);
        n -= e.m * 60000;
        e.s = Math.floor(n / 1000);
        return e;
    }

    get leadTimeStr(): string {
        let t = this.leadTime;
        // log(`leadTimeStr > t --->`, t)
        if (!t) {
            return "00:00:00";
        }
        return `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`
    }

    private pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): Task {
        if (!obj) {
            return null;
        }

        let task = new Task(obj, href, links);
        return task;
    }
}


export class RangeText {
    public text: string;
    public range: string;
}


export class Qna implements LinkedItem {
    readonly href: string;
    private links: Map<string, string>;

    readonly no: number;
    readonly _depth: number;
    readonly title: string;
    readonly content: string;
    readonly createTime: Date;
    readonly userName: string;
    readonly public: boolean;
    readonly notice: boolean;

    constructor(obj: any, href: string, links: Map<string, string>) {
        this.href = href;
        this.links = new Map<string, string>(links);

        this.no = tryParseInt(obj[TEMPLATE.NO], 0);
        this._depth = tryParseInt(obj[TEMPLATE.DEPTH], 0);
        this.createTime = new Date(obj[TEMPLATE.CREATE_TIME]);
        this.title = obj[TEMPLATE.TITLE];
        this.content = obj[TEMPLATE.CONTENT];
        this.notice = obj[TEMPLATE.NOTICE];
        this.public = obj[TEMPLATE.PUBLIC];
        this.userName = obj[TEMPLATE.USER_NAME];
    }

    get depth(): Array<number> {
        return Array(this._depth).fill(0)
    }

    get isReply(): boolean {
        return this._depth > 0;
    }

    public getLink(name: string): string {
        return this.links.get(name);
    }

    public static create(obj: any, href: string, links: Map<string, string>): Qna {
        if (!obj) {
            return null;
        }

        let q = new Qna(obj, href, links);
        return q;
    }
}


export type UserList = Collection<User>
export type KeyStoreList = Collection<KeyStore>
export type ProjectList = Collection<Project>
export type ProjectOptionList = Collection<ProjectOption>
export type MobileAppList = Collection<MobileApp>
export type TaskList = Collection<Task>
export type QnaList = Collection<Qna>





const convertError = (code: string) => {
    if (ServerErrorToAuthErrorMap.has(code) == false) {
        return AuthError.UNKNOWN;
    }

    return ServerErrorToAuthErrorMap.get(code);
}




let log = Logger('AuthService');

@Injectable()
export class AuthService {
    public static state: Subject<boolean> = new Subject<boolean>();
    private static _state: State = State.unknown;
    private cj: CollectionJson;
    public get state(): Subject<boolean> {
        return AuthService.state;
    }

    constructor(private cookies: CookieService, private http: HttpClient, private sanitizer: DomSanitizer) {
        this.cj = CollectionJson.create(environment.API_URL, http, this.handleAuthorizationError.bind(this));
        this.cj.setToken(cookies.get(COOKIE.TOKEN));
    }

    private static updateAuthenticationState(state: State) {
        log(`updateAuthenticationState > old ---> ${AuthService._state}, new ---> ${state}`)

        if (AuthService._state == state) {
            log(`updateAuthenticationState > state not changed...`)
            return;
        }

        log(`updateAuthenticationState > state changed... call observer...`)
        AuthService._state = state;
        AuthService.state.next(AuthService._state == State.authenticated);
    }




    public authenticate(
        id: string, password: string,
        passwordChangeCallback: (reason: PasswordChangeReason) => Promise<string>): Promise<User> {

        let p = new Promise<User>((resolve, reject) => {
            log(`authenticate > call login(${id}, ${password})...`);
            this.login(id, password).then(result => {
                log(`authenticate > login result ---> `, result);
                this.getIdentity().then(identity => {
                    log(`authenticate > identity ---> `, identity);
                    AuthService.updateAuthenticationState(State.authenticated);
                    resolve(identity);
                }).catch(code => reject(convertError(code)));
            }).catch(code => {
                if (code == "E10007" || code == "E10031") {
                    let reason = PasswordChangeReason.unknown;
                    if (code == "E10031") {
                        reason = PasswordChangeReason.expired;
                    }
                    return passwordChangeCallback(reason).then(newPassword => {
                        log(`authenticate > new-password ---> ${newPassword}`);
                        this.changePassword(id, password, newPassword).then(r => {
                            log(`authenticate > password changed!!`);
                            resolve(null);
                        }).catch(code => reject(convertError(code)));
                    }).catch(e => reject(AuthError.USER_CANCELED));
                }

                reject(convertError(code));
            });
        });
        return p;
    }

    public unauthenticate(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.removeToken();
            log(`unauthenticate > make unauthenticated state....`)
            AuthService.updateAuthenticationState(State.unauthenticated);
            resolve(true);
        });
    }

    public checkAuthentication(): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            if (this.hasToken() == false) {
                AuthService.updateAuthenticationState(State.unauthenticated);
                return resolve(null);
            }

            log(`check-authentication > loading identity...`);
            this.getIdentity().then(identity => {
                log(`check-authentication > identity ---> `, identity);
                AuthService.updateAuthenticationState(State.authenticated);
                resolve(identity);
            }).catch(code => reject(code));
        });
    }

    public hasToken(): boolean {
        let token = this.cookies.get(COOKIE.TOKEN);
        return (token != null);
    }

    public getUserList(page: number): Promise<UserList> {
        return this.run(this.cj.getPagedItemsFromRootRel<User>(REL.USER_MANAGEMENT, page, User.create))
    }

    public getAllUserListFromUri(uri: string): Promise<UserList> {
        return this.run(this.cj.getAllItems<User>(uri, User.create));
    }

    public getUser(uri: string): Promise<User> {
        return this.run(this.cj.getItem<User>(uri, User.create));
    }

    public getUserUpdateTemplate(uri: string): Promise<Template> {
        return this.run(this.cj.getTemplate(uri));
    }

    public getUserCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.USER_MANAGEMENT);
    }

    public updateUser(uri: string, template: Template): Promise<boolean> {
        return this.run(this.cj.updateItem(uri, template));
    }

    public deleteUser(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public createUser(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.USER_MANAGEMENT, template));
    }

    public setProjectOption(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public setProjectConfig(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public createItemsSequentially(list: Array<[string, Template]>): Promise<Array<[string, string]>> {
        let result = new Array<[string, string]>();
        let createItems = (list: Array<[string, Template]>) => {
            let p = Promise.resolve("");
            list.forEach((item, i) => {
                p = p.then(link => {
                    if (i > 0) {
                        result.push([list[i - 1][0], link]);
                    }
                    let submitUri = item[0];
                    let template = item[1];
                    return this.cj.createItem(submitUri, template);
                });
            });
            return p;
        }

        return createItems(list).then(lastLink => {
            result.push([list.lastIndexOf[0], lastLink]);
            return result;
        })
    }

    public getUserSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.USER_MANAGEMENT, REL.SEARCH));
    }

    public searchUser(params: Map<string, any>): Promise<UserList> {
        return this.run(this.cj.queryFromRootRel<User>(REL.USER_MANAGEMENT, REL.SEARCH, params, User.create));
    }

    public getUserPasswordResetTemplate(user: User): Promise<Template> {
        let link = user.getLink(REL.RESET_PASSWORD);
        return this.run(this.cj.getTemplate(link));
    }

    public resetUserPassword(user: User, template: Template): Promise<boolean> {
        let link = user.getLink(REL.RESET_PASSWORD);
        return this.run(this.cj.updateItem(link, template));
    }




    public getKeyStoreList(page: number): Promise<KeyStoreList> {
        return this.run(this.cj.getPagedItemsFromRootRel<KeyStore>(REL.KEYSTORE_MANAGEMENT, page, KeyStore.create))
    }

    public getKeyStore(uri: string): Promise<KeyStore> {
        return this.run(this.cj.getItem<KeyStore>(uri, KeyStore.create));
    }

    public getKeyStoreCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.KEYSTORE_MANAGEMENT);
    }

    public getKeyStoreAddTemplate(): Promise<Template> {
        return this.cj.getTemplateFromRootRel(REL.KEYSTORE_MANAGEMENT, REL.ADD);
    }

    public createKeyStore(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.KEYSTORE_MANAGEMENT, template));
    }

    public deleteKeyStore(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public download(uri: string, defaultFileName: string = "file"): Promise<void> {
        return this.run(this.cj.download(uri, defaultFileName).then(r => {
            log(`download ---> ${r}`)
        }));
    }

    public getKeyStoreSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.KEYSTORE_MANAGEMENT, REL.SEARCH));
    }

    public searchKeyStore(params: Map<string, any>): Promise<KeyStoreList> {
        return this.run(this.cj.queryFromRootRel<KeyStore>(REL.KEYSTORE_MANAGEMENT, REL.SEARCH, params, KeyStore.create));
    }




    public getProjectList(page: number): Promise<ProjectList> {
        return this.run(this.cj.getPagedItemsFromRootRel<Project>(REL.PROJECT_MANAGEMENT, page, Project.create))
    }

    public getProject(uri: string): Promise<Project> {
        return this.run(this.cj.getItem<Project>(uri, Project.create));
    }

    public deleteProject(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }

    public getProjectOption(uri: string): Promise<Template> {
        return this.run(this.cj.getItem<Template>(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getProjectOptionList(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllItems(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getConfigList(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllItems(uri, (obj, href, links) => {
            let items = new Map(Object.entries(obj));
            return Template.create(items, href, links);
        }));
    }

    public getProjectOptionUpdateTemplateList(uri: string): Promise<Map<string, Template>> {
        return this.run(this.cj.getItemsUpdateTemplate(uri));
    }

    public buildConfigMap(list: Iterable<Template>): Map<string, Array<Template>> {
        let result = new Map<string, Array<Template>>();
        for (let c of list) {
            let platform = c.get(TEMPLATE.PLATFORM);
            let configs = result.get(platform);
            if (!configs) {
                configs = new Array<Template>();
            }
            configs.push(c);
            result.set(platform, configs);
        }

        for (let list of result.values()) {
            list.sort((a, b) => {
                let n1 = a.get(TEMPLATE.NAME)
                let n2 = b.get(TEMPLATE.NAME)
                if (n1 > n2) {
                    return 1;
                } else if (n1 < n2) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        return result;
    }

    public getProjectConfigUpdateTemplateList(uri: string): Promise<Map<string, Array<Template>>> {
        return this.run(
            this.cj
                .getItemsUpdateTemplate(uri)
                .then(list => this.buildConfigMap(list.values())));
    }

    public getProjectCreateTemplate(): Promise<Template> {
        return this.getCreateTemplate(REL.PROJECT_MANAGEMENT);
    }

    public getProjectOptionCreateTemplates(uri: string): Promise<TemplateList> {
        return this.run(this.cj.getAllTemplate(uri));
    }

    public getProjectConfigCreateTemplates(uri: string): Promise<Map<string, Array<Template>>> {
        return this.run(this.cj.getAllTemplate(uri).then(list => this.buildConfigMap(list)));
    }

    public createProject(template: Template): Promise<string> {
        return this.run(this.cj.createItemFromRootRel(REL.PROJECT_MANAGEMENT, template));
    }

    public getProjectUpdateTemplate(uri: string): Promise<Template> {
        return this.run(this.cj.getTemplate(uri));
    }

    public updateProject(uri: string, template: Template): Promise<boolean> {
        return this.run(this.cj.updateItem(uri, template));
    }

    public updateProjectWithOptions(
        uri: string, projectTemplate: Template,
        optionTemplates: Iterable<Template>, configTemplates: Iterable<Template>): Promise<boolean> {
        return this.run(this.cj
            .updateItem(uri, projectTemplate)
            .then(result => {
                let list = new Array<Template>();
                for (let template of optionTemplates) {
                    list.push(template);
                }

                for (let template of configTemplates) {
                    list.push(template);
                }

                let updateItems = (list: Array<Template>) => {
                    var p = Promise.resolve(true);
                    list.forEach(template => {
                        p = p.then(() => {
                            let submitUri = template.getLink(REL.SUBMIT);
                            return this.cj.updateItem(submitUri, template);
                        });
                    });
                    return p;
                }

                return updateItems(list).then(result => true);
            }));
    }



    public getProjectSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.PROJECT_MANAGEMENT, REL.SEARCH));
    }

    public searchProject(params: Map<string, any>): Promise<ProjectList> {
        return this.run(this.cj.queryFromRootRel<Project>(REL.PROJECT_MANAGEMENT, REL.SEARCH, params, Project.create));
    }

    public getProjectStatistics(p: Project): Promise<ProjectStatistics> {
        let link = p.getLink(REL.STATISTICS)
        log(`getProjectStatistics > uri ---> ${link}`)
        return this.run(this.cj.getItem(link, ProjectStatistics.create));
    }



    public getMobileAppList(uri: string, page: number): Promise<MobileAppList> {
        return this.run(this.cj.getPagedItems<MobileApp>(uri, page, MobileAppFactory.create));
    }

    public getMobileAppUploadLink(uri: string): Promise<string> {
        return this.run(this.cj.getLink(REL.FILE_UPLOAD, uri));
    }

    public uploadMobileApp(uri: string, file: File, callback: UploadCallback): Promise<any> {
        return this.run(this.cj.uploadFile(uri, file, callback));
    }

    public getMobileApp(uri: string): Promise<MobileApp> {
        return this.run(this.cj.getItem<MobileApp>(uri, MobileAppFactory.create));
    }

    public getMobileAppSearchQueryData(uri: string): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMap(uri, REL.SEARCH));
    }

    public searchMobileApp(uri: string, params: Map<string, any>): Promise<MobileAppList> {
        return this.run(this.cj.query<MobileApp>(uri, REL.SEARCH, params, MobileAppFactory.create));
    }

    public loadIcon(uri: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.cj.downloadBlob(uri).then(blob => {
                let url = window.URL.createObjectURL(blob);
                log(`loadIcon > image url created from window.URL.createObjectURL() ---> ${url}`);
                let safeImage = this.sanitizer.bypassSecurityTrustUrl(url);
                log(`loadIcon > image url sanitized ---> ${safeImage}`);
                resolve(safeImage);

            }).catch(e => reject(e));
        })
    }

    public getPresetOption(app: MobileApp): Promise<Map<string, any>> {
        let link = app.getLink(REL.OPTION)
        log(`getPresetOption > uri ---> ${link}`)
        return this.run(this.cj.getItemAsMap(link));
    }

    public getTaskCreateTemplate(app: MobileApp): Promise<Template> {
        let link = app.getLink(REL.TASK)
        log(`getTaskCreateTemplate > task uri ---> ${link}`)
        return this.run(this.cj.getTemplate(link));
    }

    private loadTaskDetails(tasks: Array<Task>): Promise<Array<Task>> {
        let promises = new Array<Promise<void>>();
        tasks.forEach(task => {
            let taskCreatorLink = task.getLink(REL.CREATOR);
            log(`loadTaskDetails > load task creator --->`, taskCreatorLink);
            let p1 = this
                .getUser(taskCreatorLink)
                .then(user => {
                    log(`loadTaskDetails > task(${task.createTime}) creator --->`, user);
                    task.creator = user;
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading task creator ---> `, e);
                    task.creator = null;
                });

            let taskLogLink = task.getLink(REL.LOG);
            log(`loadTaskDetails > load task log --->`, taskLogLink);
            let p2 = this
                .getTextData(taskLogLink)
                .then(logData => {
                    task.log = logData;
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading log ---> `, e);
                    task.log = "";
                });

            let taskObfuscationMapLink = task.getLink(REL.MAP);
            log(`loadTaskDetails > load obfuscation map --->`, taskObfuscationMapLink);
            let p3 = this
                .getTextData(taskObfuscationMapLink)
                .then(mapData => {
                    task.setObfuscationMap(mapData);
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading obfuscation map ---> `, e);
                });

            let taskConfigListLink = task.getLink(REL.CONFIG);
            log(`loadTaskDetails > load config list --->`, taskConfigListLink);
            let p4 = this
                .getConfigList(taskConfigListLink)
                .then(list => {
                    task.configs = new Array<Template>();
                    for (let template of list.items) {
                        task.configs.push(template);
                    }
                })
                .catch(e => {
                    log(`loadTaskDetails > error occurred while loading config list ---> `, e);
                });

            promises.push(p1, p2, p3, p4);
        });

        return Promise.all(promises).then(_ => tasks);
    }

    public createTask(app: MobileApp, template: Template): Promise<string> {
        let link = app.getLink(REL.TASK)
        log(`createTask > task uri ---> ${link}`)
        return this.run(this.cj.createItem(link, template));
    }

    public getTaskList(uri: string, page: number): Promise<TaskList> {
        let p = this.cj.getPagedItems<Task>(uri, page, Task.create)
            .then(list => this.loadTaskDetails(list.items).then(_ => list));

        return this.run(p);
    }

    public getTask(uri: string): Promise<Task> {
        let p = this.cj.getItem<Task>(uri, Task.create)
            .then(task => this.loadTaskDetails(new Array(task)).then(list => list[0]));
        return this.run(p);
    }

    public searchTask(params: Map<string, any>): Promise<TaskList> {
        let p = this.cj.queryFromRootRel<Task>(REL.TASK_MANAGEMENT, REL.SEARCH, params, Task.create)
            .then(list => this.loadTaskDetails(list.items).then(_ => list));
        return this.run(p);
    }


    public getAppliedConfigNames(configs: Array<Template>): Array<string> {
        let result = new Array<string>();
        if (!configs || configs.length == 0) {
            return result;
        }
        configs.forEach((template, i) => {
            if (template.get(TEMPLATE.CONFIG_USE) === true) {
                result.push(template.get(TEMPLATE.NAME));
            }
        })
        return result;
    }

    public getTextData(uri: string): Promise<string> {
        return this.cj.get(uri).then(data => {
            return !data ? "" : JSON.stringify(data);
        }).catch(e => {
            if (e.status == 200) {
                return e.error.text;
            }

            throw e;
        });
    }

    public getRangedTextData(uri: string, range: string): Promise<RangeText> {
        let headers = new HttpHeaders();
        headers = headers.set("Range", `bytes=${range}`);

        return this.run(this.cj.get(uri, headers).catch(e => {
            log('getRangedTextData > e ---> ', e)
            log('getRangedTextData > e.status ---> ', e.status)
            if (e.status == 206) {
                let data = new RangeText();
                data.text = e.error.text;
                let range = e.headers.get("Content-Range")
                log('getRangedTextData > Content-Range header ---> ', range)
                data.range = range;
                return data;
            }

            throw e;
        }));
    }


    public getQnaList(page: number): Promise<QnaList> {
        return this.run(this.cj.getPagedItemsFromRootRel<Qna>(REL.QNA_MANAGEMENT, page, Qna.create))
    }

    public getQna(uri: string): Promise<Qna> {
        return this.run(this.cj.getItem<Qna>(uri, Qna.create));
    }

    public getQnaCreateTemplate(templateLink: string): Promise<Template> {
        return this.cj.getTemplate(templateLink);
    }

    public createQna(uri: string, template: Template): Promise<string> {
        return this.run(this.cj.createItem(uri, template));
    }

    public deleteQna(uri: string): Promise<boolean> {
        return this.run(this.cj.deleteItem(uri));
    }


    public getReplyList(uri: string, page: number): Promise<QnaList> {
        return this.run(this.cj.getPagedItems<Qna>(uri, page, Qna.create))
    }

    public getQnaSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.QNA_MANAGEMENT, REL.SEARCH));
    }

    public searchQna(params: Map<string, any>): Promise<QnaList> {
        return this.run(this.cj.queryFromRootRel<Qna>(REL.QNA_MANAGEMENT, REL.SEARCH, params, Qna.create));
    }


    public cancelTask(uri: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            log(`cancelTask > get template ---->`, uri);
            this.cj.getTemplate(uri).then(template => {
                log(`cancelTask > template ---->`, template);
                template.set(TEMPLATE.STATE, TASK_STATE.CANCEL);
                log(`cancelTask > template to send ---->`, template);

                this.cj.updateItem(uri, template).then(result => {
                    log(`cancelTask > result --->`, result);
                    resolve(result);
                }).catch(e => {
                    log(`cancelTask > error while updateItem() ---> `, e)
                    this.rejectWithErrorCode(reject, e);
                })
            }).catch(e => {
                log(`cancelTask > error while getTemplateasMap() ---> `, e)
                this.rejectWithErrorCode(reject, e);
            })
        });
    }

    public getRunningTaskList(page: number): Promise<TaskList> {
        return new Promise<TaskList>((resolve, reject) => {
            log(`getRunningTaskList > get query data...`);
            this.getTaskSearchQueryData().then(data => {
                log(`getRunningTaskList > query data ---->`, data);
                data.set(PARAM.STATE, `${TASK_STATE.CREATED},${TASK_STATE.RUNNING}`);
                data.set(PARAM.PAGE, page);
                log(`getRunningTaskList > query data to send ---->`, data);

                this.searchTask(data)
                    .then(list => resolve(list))
                    .catch(code => reject(code))
            }).catch(code => reject(code))
        });
    }

    public getTaskSearchQueryData(): Promise<Map<string, any>> {
        return this.run(this.cj.getQueryDataAsMapFromRootRel(REL.TASK_MANAGEMENT, REL.SEARCH));
    }

    public getTaskFindLink(): Promise<string> {
        return this.run(this.cj.getLinkRecursively([REL.TASK_MANAGEMENT, REL.FIND]));
    }

    public extractMobileAppInfo(uri: string, file: File, callback: UploadCallback): Promise<AndroidApp> {
        return new Promise<AndroidApp>((resolve, reject) => {
            this.cj.uploadFile(uri, file, callback).then(response => {
                let app = this.cj.createObjectFromCollectionItem(response.body, 0, AndroidApp.create);
                resolve(app);
            }).catch(e => {
                log(`cancelTask > error while extractMobileAppInfo() ---> `, e)
                this.rejectWithErrorCode(reject, e);
            });
        });
    }


    private run<T>(p: Promise<T>): Promise<T> {
        return p.catch(e => {
            let res = e.response || e.error;
            let code = this.cj.readErrorCode(res);
            let error = convertError(code);
            throw error;
        });
    }

    private handleAuthorizationError(cj: CollectionJson, e: any): void {
        log(`handleAuthorizationError > remove cookie and make unauthenticated state!!`)
        this.removeToken();
    }

    private removeToken(): void {
        this.cookies.remove(COOKIE.TOKEN);
        this.cj.removeToken();
        AuthService.updateAuthenticationState(State.unauthenticated);
    }

    private setToken(token: string): void {
        log(`setToken > save....`)
        this.cookies.put(COOKIE.TOKEN, token);
        this.cj.setToken(token);
    }

    private rejectWithError(reject: any, e: any) {
        log(`rejectWithError > error ---> `, e);
        let code = this.cj.readErrorCode(e.error);
        let error = convertError(code);
        log(`rejectWithError > error ---> ${error}`);
        reject(error);
    }

    private rejectWithErrorCode(reject: any, e: any) {
        try {
            log(`rejectWithErrorCode > error ---> `, e);
            let code = (e.status == 0) ? CONNECTION_ERROR_CODE : this.cj.readErrorCode(e.error);
            log(`rejectWithErrorCode > error code ---> ${code}`);
            reject(code);
        } catch (e) {
            log(`rejectWithErrorCode > error while reading error code --->`, e);
            reject("E10000")
        }
    }


    private getIdentity(): Promise<User> {
        let token = this.cookies.get(COOKIE.TOKEN);
        log(`getIdentity > token --->`, token);
        return new Promise<User>((resolve, reject) => {
            this.cj.getLinkValue(REL.IDENTITY).then(r => {
                let data = this.cj.readItemAsObject(r.response, 0);
                let links = this.cj.readItemLinkAsMap(r.response, 0);
                let identity = User.create(data, r.link, links);
                resolve(identity);
            }).catch(e => this.rejectWithErrorCode(reject, e));
        });
    }

    public logout(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.cj.getLinkValue(REL.LOGOUT).then(r => {
                resolve(true);
            }).catch(e => {
                resolve(true);
            })
        })
    }

    private login(id: string, password: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.cj.getLinkValue(REL.AUTHENTICATION).then(r => {
                let template = this.cj.readTemplate(r.response);
                let c = this.cj.createFilledTemplate(template, {
                    [TEMPLATE.ID]: id,
                    [TEMPLATE.PASSWORD]: password
                });

                this.cj.post(r.link, c).catch(e => {
                    if (e.status == 200) {
                        let token = e.headers.get("Access-Token");
                        log(`login > token ---> `, token);
                        this.setToken(token);
                        resolve(true);
                    } else {
                        this.rejectWithErrorCode(reject, e);
                    }
                });
            }).catch(e => this.rejectWithErrorCode(reject, e))
        })
    }

    private changePassword(id: string, password: string, newPassword: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.cj.getLinkValue(REL.AUTHENTICATION).then(r => {
                let url = this.cj.readLink(r.response, REL.CHANGE_PASSWORD);
                log(`change-password > api url ---> ${url}`);
                this.cj.get(url).then(r => {
                    log(`change-password > response ---> `, r);
                    let template = this.cj.readTemplate(r);
                    let c = this.cj.createFilledTemplate(template, {
                        [TEMPLATE.ID]: id,
                        [TEMPLATE.PASSWORD]: password,
                        [TEMPLATE.NEW_PASSWORD]: newPassword
                    });

                    this.cj.post(url, c).catch(e => {
                        if (e.status == 200) {
                            log(`change-password > response ---> `, r);
                            resolve(true);
                        } else {
                            this.rejectWithErrorCode(reject, e)
                        }
                    });
                }).catch(e => this.rejectWithErrorCode(reject, e));
            }).catch(e => this.rejectWithErrorCode(reject, e));
        });
    }



    private getCreateTemplate(rel: string): Promise<Template> {
        return this.run(this.cj.getTemplateFromRootRel(rel));
    }


}


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return Promise.resolve(this.auth.hasToken());
    }
}
