<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{'running-task-list.title' | translate}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>

<div [hidden]="state == State.loading">
  <table class="table table-sm table-hover table-rounded">
    <thead class="thead thead-light">
      <tr class="row m-0" scope="row">
        <th class="col-sm-3">{{'running-task-list.label.app-name' | translate}}</th>
        <th class="col-sm-3">{{'running-task-list.label.app-package-name' | translate}}</th>
        <th class="col-sm-2">{{'running-task-list.label.task-creator' | translate}}</th>
        <th class="col-sm-2">{{'running-task-list.label.task-start-time' | translate}}</th>
        <th class="col-sm-1">{{'running-task-list.label.task-elapsed-time' | translate}}</th>
        <th class="col-sm"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let task of tasks" class="row m-0 h-100" scope="row">
        <td class="col-sm-3 d-flex align-items-center">
          <img [src]="task?.app?.icon" [width]="50" [height]="50" [hidden]="!task?.app?.icon">
          <i class="fa fa-android fa-3x ml-2 mr-2" [hidden]="task?.app?.icon"></i>
          <span class="ml-2 wrap">{{task?.app?.name}} v{{task?.app?.version}}({{task?.app?.versionCode}})</span>
        </td>
        <td class="col-sm-3 d-flex align-items-center">
          <span class="wrap">{{task?.app?.package}}</span>
        </td>
        <td class="col-sm-2 d-flex align-items-center">
          <span class="wrap">{{task?.creator?.name}} ({{task?.creator?.id}})</span>
        </td>
        <td class="col-sm-2 d-flex align-items-center">
          <span>{{task?.createTime | date:'yyyy-MM-dd HH:mm'}}</span>
        </td>
        <td class="col-sm-1 d-flex align-items-center">
          <button class="btn btn-dark btn-sm pt-0 pb-0">
            <small class="h-100 d-flex align-items-center text-warning">
              <strong>
                {{task?.leadTimeStr}}
              </strong>
            </small>
          </button>
        </td>
        <td class="col-sm d-flex flex-row-reverse align-items-center">
          <button class="btn btn-outline-danger btn-sm pt-0 pb-0 mr-1" [disabled]="state != State.none" (click)="cancelTask(task)">
            {{'running-task-list.button.cancel-task' | translate}}
          </button>
        </td>
      </tr>
    </tbody>
  </table>


  <!-- <div class="row"> -->
  <div class="d-flex justify-content-between pb-5">
    <div>
    </div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'running-task-list.label.total-task'|translate:tasks?.page}}</span>
  </div>

  <br>
  <div class="row">
  </div>

</div>