import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Logger } from '../../../environments/environment';
import { CommonService, TimeZoneItem } from '../../service/common.service';

let log = Logger('TimeZoneSelector');

@Component({
  selector: 'timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.css']
})
export class TimezoneSelectorComponent implements OnInit {
  private _selectedTimeZone: TimeZoneItem = null;
  public timeZones: Array<TimeZoneItem> = null;
  private timeZoneMap: Map<string, TimeZoneItem> = new Map<string, TimeZoneItem>();


  public get timeZone(): TimeZoneItem {
    return this._selectedTimeZone;
  }

  public get selectedTimeZone(): TimeZoneItem {
    return this._selectedTimeZone;
  }

  public set selectedTimeZone(newValue: TimeZoneItem) {
    let changed = newValue && (this._selectedTimeZone != newValue);
    if (!changed) {
      return;
    }

    this._selectedTimeZone = newValue;
    this.onChange.emit(this._selectedTimeZone);
  }

  @Input()
  public readOnly: boolean = false;
  @Input()
  private set guess(guess: boolean) {
    if (!guess) {
      return;
    }
    this.selectedTimeZone = this.common.getCurrentTimeZoneItem();
  }
  @Output()
  private onChange = new EventEmitter<TimeZoneItem>();

  @Input()
  private set value(tz: string) {
    console.log(`timezone-selector > value set ---> ${tz}`)
    this.selectTimeZoneById(tz) ;
  }

  constructor(private common: CommonService) { 
    this.timeZones = this.common.getTimeZoneList();
    this.updateTimeZoneMap();
  }

  private updateTimeZoneMap() {
    this.timeZoneMap.clear();
    for (let tz of this.timeZones) {
      this.timeZoneMap.set(tz.id, tz);
    }
  }

  private selectTimeZoneById(tz: string) {
    if (!this.timeZoneMap.has(tz)) {
      return;
    }

    this._selectedTimeZone = this.timeZoneMap.get(tz);
  }

  ngOnInit(): void {
  }
}
