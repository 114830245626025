import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { Task, ElapsedTime, REL, AuthService, AuthError, OPTIONAL_BOOLEAN } from '../../service/auth.service';
import { Logger } from '../../../environments/environment';
import { Template } from '../../service/cj';

let log = Logger("TaskOptionView");

export interface TaskOptionHandler {
    isOptionLocked(opt: string): boolean;
    isOptionChecked(opt: string): boolean;
    isOptionReadOnly(opt: string): boolean;
    isOptionExist(opt: string): boolean;
    setOption(opt: string, value: boolean): void;
}

@Component({
    selector: 'task-option',
    templateUrl: './task-option-view.component.html',
    styleUrls: ['./task-option-view.component.css']
  })
  export class TaskOptionViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    public handler?: TaskOptionHandler;
    @Input()
    public fieldName?: string;
    @Input()
    public displayName?: string;
    @Input()
    get id(): string {
        return "task-option-"+this.fieldName;
    }

    public OPTIONAL_BOOLEAN = OPTIONAL_BOOLEAN;

    constructor(
        private renderer:Renderer2,
        private elem:ElementRef) {

    }
  
    ngAfterViewInit(): void {
        this.renderer.setStyle(this.elem.nativeElement, 'display', this.isExist? 'visible' : 'none')
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    public onClick(e: any): void {
        if (this.handler.isOptionReadOnly(this.fieldName)) {
            e.preventDefault();
            return;
        }

        this.setOption(e.target.checked);
        e.target.checked = this.isChecked;
        log(`e.target.checked ---> ${e.target.checked}, isChecked ---> ${this.isChecked}`);
    }

    public setOption(value: boolean): void {
        this.handler.setOption(this.fieldName, value);
    }        

    get isExist(): boolean {
        return this.handler.isOptionExist(this.fieldName);
    }

    get isLocked(): boolean {
        return this.handler.isOptionLocked(this.fieldName);
    }

    get isChecked(): boolean {
        return this.handler.isOptionChecked(this.fieldName);
    }
  }  