<div class="row">
    <div class="col-sm-12 ml-4">
        <h4>{{'user-update.title' | translate}}</h4>
    </div>
</div>
<hr>
<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">
    <div class="row mb-2" [hidden]="!has(TEMPLATE.ID)">
        <div class="col-sm-2 text-right">
            <span>{{'user.id' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="userId" [disabled]="isProcessing">
        </div>
    </div>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.NAME)">
        <div class="col-sm-2 text-right">
            <span>{{'user.name' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="userName" [disabled]="isProcessing">
        </div>
    </div>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.DEPARTMENT)">
        <div class="col-sm-2 text-right">
            <span>{{'user.department' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="dept" [disabled]="isProcessing">
        </div>
    </div>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.EMAIL)">
        <div class="col-sm-2 text-right">
            <span>{{'user.email' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <input type="text" maxlength="100" class="form-control" [(ngModel)]="email" [disabled]="isProcessing">
        </div>
    </div>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.TEL)">
        <div class="col-sm-2 text-right">
            <span>{{'user.tel' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="tel" [disabled]="isProcessing">
        </div>
    </div>
    <div class="row" [hidden]="!has(TEMPLATE.NOTE)">
        <div class="col-sm-2 text-right">
            <span>{{'user.note' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <textarea rows="3" maxlength="200" class="form-control" [(ngModel)]="note" [disabled]="isProcessing"></textarea>
        </div>
    </div>
    <hr>
    <div class="row mb-2" [hidden]="!has(TEMPLATE.STATE)">
        <div class="col-sm-2 text-right">
            <span>{{'user.state' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle [disabled]="isProcessing">
                    {{'user-state.'+get(TEMPLATE.STATE) | translate}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button class="dropdown-item" (click)="set(TEMPLATE.STATE, USER_STATE.APPROVED)">{{'user-state.approved' | translate}}</button>
                    <button class="dropdown-item" (click)="set(TEMPLATE.STATE, USER_STATE.REGISTERED)">{{'user-state.registered' | translate}}</button>
                    <button class="dropdown-item" (click)="set(TEMPLATE.STATE, USER_STATE.CHANGE_PASSWORD)">{{'user-state.changePassword' | translate}}</button>
                    <button class="dropdown-item" (click)="set(TEMPLATE.STATE, USER_STATE.BLOCKED)">{{'user-state.blocked' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="!has(TEMPLATE.ROLE)">
        <div class="col-sm-2 text-right">
            <span>{{'user.role' | translate}}</span>
        </div>
        <div class="col-sm-9">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle [disabled]="isProcessing">
                    {{'user-role.'+get(TEMPLATE.ROLE) | translate}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button class="dropdown-item" (click)="set(TEMPLATE.ROLE, USER_ROLE.USER)">{{'user-role.user' | translate}}</button>
                    <button class="dropdown-item" (click)="set(TEMPLATE.ROLE, USER_ROLE.ADMIN)">{{'user-role.admin' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
            {{'common.go-back' | translate}}
        </button>
        <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="update()">
            <div [hidden]="isProcessing">
                {{'user-update.button.update' | translate}}
            </div>
            <div [hidden]="!isProcessing">
                <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
                {{'common.processing' | translate}}
            </div>
        </button>
    </div>
</div>