<div [hidden]="state != State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div [hidden]="state == State.loading">
    <div class="row">
        <div class="col-sm-1">
        </div>
        <div class="col-sm-10 wrap">
            <h4>{{qna?.title}}</h4>
            <div class="row d-flex align-items-center">
                <div class="col-sm">
                    <small class="mr-3">
                        <i class="fa fa-user-circle fa-1x mr-1"></i>
                        {{qna?.userName}}
                    </small>
                    <small>{{qna?.createTime | date:'yyyy-MM-dd HH:mm'}}</small>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-2 mb-2">
    <div class="row mb-2">
        <div class="col-sm-9 offset-sm-1">
            <div class="card border-0">
                <div class="card-body p-0">
                    <span class="multi-line-text">{{qna?.content}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <hr> -->

    <div *ngFor="let r of replies">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 p-0">
                <hr class="m-0 mb-1 style3">
            </div>
        </div>
        <reply-view [reply]="r" (delete)="onDeleteReply($event)"></reply-view>
    </div>


    <div [hidden]="!replyTemplate || !showReplyInput || qna.notice">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 p-0">
                <hr class="m-0 mb-1 style3">
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-10 offset-sm-1">
                <textarea #replyInput row="10" class="form-control" [(ngModel)]="replyContent" [disabled]="isProcessing"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <button type="submit" class="btn btn-primary mr-1" [disabled]="state != State.none || !hasReplyInput" (click)="writeReply()">
                    {{'qna-detail.button.reply' | translate}}
                </button>
            </div>
        </div>
    </div>


</div>





<hr class="mt-2">
<div class="row pb-5">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary mr-3" [disabled]="state != State.none" (click)="goBack()">
            {{'common.go-back' | translate}}
        </button>
        <!-- <button type="submit" class="btn btn-primary mr-1" [disabled]="state != State.none">
            {{'qna-detail.button.update' | translate}}
        </button> -->
        <button type="submit" class="btn btn-outline-info mr-1" [disabled]="state != State.none" [class.active]="showReplyInput"
            [hidden]="qna.notice" (click)="toggleReplyInput()">
            {{'qna-detail.button.show-reply-input' | translate}}
        </button>
        <button type="submit" class="btn btn-danger mr-1" [disabled]="state != State.none" (click)="delete()">
            <div [hidden]="isProcessing">
                {{'qna-detail.button.delete' | translate}}
            </div>
            <div [hidden]="!isProcessing">
                <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
                {{'common.processing' | translate}}
            </div>
        </button>
    </div>
</div>