<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{'keystore-detail.title' | translate}}</h4>
  </div>
</div>
<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.name' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.name}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.date' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.date | date:'yyyy-MM-dd HH:mm'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.common-name' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.commonName}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.organization-unit' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.organizationUnit}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.organization' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.organization}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.locality' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.locality}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.state' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.state}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.country-code' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.countryCode}}</span>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.alias' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.alias}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.algorithm' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.algorithm}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.validity' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.validity}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.valid-from' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.validFrom | date:'yyyy-MM-dd HH:mm'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.valid-to' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{keystore?.validTo | date:'yyyy-MM-dd HH:mm'}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.type' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span>{{(keystore?.uploaded?'keystore.uploaded':'keystore.created') | translate}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">
      <span>{{'keystore.note' | translate}}</span>
    </div>
    <div class="col-sm-9">
      <span class="multi-line-text">{{keystore?.note}}</span>
    </div>
  </div>
</div>
<hr>
<div class="row pb-5">
  <div class="col-sm-1 text-right">
  </div>
  <div class="col-sm-10">
    <button type="submit" class="btn btn-secondary" [disabled]="state != State.none" (click)="goBack()">
      {{'common.go-back' | translate}}
    </button>
    <button type="submit" class="btn btn-primary ml-3" [disabled]="state != State.none" (click)="download()">
      {{'keystore-detail.button.download' | translate}}
    </button>
    <button type="submit" class="btn btn-danger ml-1" [disabled]="state != State.none" (click)="delete()">
      <div [hidden]="isProcessing">
        {{'keystore-detail.button.delete' | translate}}
      </div>
      <div [hidden]="!isProcessing">
        <i class="fa fa-refresh fast-spin fa-1x fa-fw"></i>
        {{'common.processing' | translate}}
      </div>

    </button>
  </div>
</div>