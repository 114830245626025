<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{'project-list.title' | translate}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">

  <table class="table table-sm table-hover table-rounded">
    <thead class="thead thead-light">
      <tr class="row m-0" scope="row">
        <th class="col-sm-1">{{'project.platform' | translate}}</th>
        <th class="col-sm-2">{{'project.date' | translate}}</th>
        <th class="col-sm-3">{{'project.name' | translate}}</th>
        <th class="col-sm-2">{{'project.packageFilter' | translate}}</th>
        <th class="col-sm-2">{{'project.note' | translate}}</th>
        <th class="col-sm-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let project of projects" class="row m-0" scope="row" (click)="loadItem(project)">
        <td class="col-sm-1">
          <i *ngFor="let platform of project.platforms" class="text-primary fa fa-2x fa-fw {{'platform-icon.' + platform|translate}}"></i>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{project?.date | date:'yyyy-MM-dd HH:mm'}}</span>
        </td>
        <td class="col-sm-3">
          <span class="text-wrap">{{project?.name}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{project?.packageFilter}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{project?.note}}</span>
        </td>
        <td class="col-sm-2 text-right">
          <button class="btn btn-outline-secondary btn-sm pt-0 pb-0 mr-1" [disabled]="state != State.none" (click)="updateProject(project)">
            {{'project-list.button.update-project' | translate}}
          </button>
          <button class="btn btn-outline-info btn-sm pt-0 pb-0" [disabled]="state != State.none" (click)="viewAppList(project)">
            {{'project-list.button.view-app-list' | translate}}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <div class="row"> -->
  <div class="d-flex justify-content-between">
    <div></div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'project-list.label.total-project'|translate:projects?.page}}</span>
  </div>
  <!-- </div> -->
  <br>

  <div class="row d-flex justify-content-between pb-5">
    <div></div>
    <div class="col-sm-10 d-flex">
      <div class="input-group">
        <div class="input-group-prepend">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
              {{'project.'+selectedFieldName | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button [hidden]="!has(PARAM.MANAGER_ID)" class="dropdown-item" (click)="set(PARAM.MANAGER_ID)">{{'project.manager-id'
                | translate}}</button>
              <button [hidden]="!has(PARAM.MANAGER_NAME)" class="dropdown-item" (click)="set(PARAM.MANAGER_NAME)">{{'project.manager-name'
                | translate}}</button>
              <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'project.name' |
                translate}}</button>
              <button [hidden]="!has(PARAM.NOTE)" class="dropdown-item" (click)="set(PARAM.NOTE)">{{'project.note' |
                translate}}</button>
              <button [hidden]="!has(PARAM.PACKAGE)" class="dropdown-item" (click)="set(PARAM.PACKAGE)">{{'project.packageFilter'
                | translate}}</button>
              <button [hidden]="!has(PARAM.DATE)" class="dropdown-item" (click)="set(PARAM.DATE)">{{'project.date' |
                translate}}</button>
            </div>
          </div>
        </div>
        <div [hidden]="searchField == PARAM.DATE" class="col-sm pl-0 pr-0">
          <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)" [(ngModel)]="searchValue" [disabled]="state != State.none">
        </div>
        <div [hidden]="searchField != PARAM.DATE" class="col-sm pl-0 pr-0">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate"
                  readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
            <span class="ml-2 mr-2">~</span>
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="input-group-append mr-4">
          <button class="btn btn-primary pr-4 pl-4 text-nowrap" type="button" [disabled]="state != State.none" (click)="loadPage(0)">{{'common.search'|translate}}</button>
        </div>
      </div>

      <button type="submit" class="btn btn-primary text-nowrap" [disabled]="state != State.none" (click)="createItem()">
        {{'project-list.button.create-project' | translate}}
      </button>
    </div>
  </div>


</div>