<small class="badge badge-pill badge-primary mr-1" [hidden]="task?.encrypt.code != true">{{'encrypt.code' | translate}}</small>
<small class="badge badge-pill badge-primary mr-1" [hidden]="task?.encrypt.string != true">{{'encrypt.string' | translate}}</small>
<small class="badge badge-pill badge-primary mr-1" [hidden]="task?.encrypt.resource != true">{{'encrypt.resource' | translate}}</small>

<small class="badge badge-pill badge-pink mr-1" [hidden]="task?.detect.rootingDevice != true">{{'detect.rootingDevice' | translate}}</small>
<small class="badge badge-pill badge-pink mr-1" [hidden]="task?.detect.debugger != true">{{'detect.debugger' | translate}}</small>
<small class="badge badge-pill badge-pink mr-1" [hidden]="task?.detect.emulator != true">{{'detect.emulator' | translate}}</small>
<small class="badge badge-pill badge-pink mr-1" [hidden]="task?.detect.frida != true">{{'detect.frida' | translate}}</small>
<small class="badge badge-pill badge-pink mr-1" [hidden]="task?.detect.forgery != true">{{'detect.forgery' | translate}}</small>

<small class="badge badge-pill badge-success mr-1" [hidden]="task?.prevent.decompile != true">{{'prevent.decompile' | translate}}</small>
<small class="badge badge-pill badge-success mr-1" [hidden]="task?.prevent.repackage != true">{{'prevent.repackage' | translate}}</small>

<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.className != true">{{'obfuscate.class-name' | translate}}</small>
<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.methodName != true">{{'obfuscate.method-name' | translate}}</small>
<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.fieldName != true">{{'obfuscate.field-name' | translate}}</small>
<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.protocolName != true">{{'obfuscate.protocol-name' | translate}}</small>
<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.categoryName != true">{{'obfuscate.category-name' | translate}}</small>
<small class="badge badge-pill badge-warning mr-1" [hidden]="task?.obfuscate.propertyName != true">{{'obfuscate.property-name' | translate}}</small>

<small class="badge badge-pill badge-danger mr-1" [hidden]="task?.prevent.clipboardUsage != true">{{'prevent.clipboard' | translate}}</small>
<small class="badge badge-pill badge-danger mr-1" [hidden]="task?.prevent.screenCapture != true">{{'prevent.screen-capture' | translate}}</small>
<small class="badge badge-pill badge-danger mr-1" [hidden]="task?.prevent.controlUsageTime != true">{{'prevent.control-usage' | translate}}</small>

<small class="badge badge-pill badge-info mr-1" [hidden]="task?.remove.logCatLog != true">{{'remove.logcat-log' | translate}}</small>
<small class="badge badge-pill badge-info mr-1" [hidden]="task?.remove.debugInfo != true">{{'remove.debug-info' | translate}}</small>
