<div *ngIf="state == State.loading">
    <div class="media">
        <div class="media-left">
            <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
        </div>
        <div class="media-body">
            <h4 class="media-heading">{{'common.loading' | translate}}</h4>
            {{'common.loading-please-wait' | translate}}
        </div>
    </div>
    <hr>
</div>
<div *ngIf="state != State.loading">
    <android-app-detail-view #android_detail [mobileApp]="app" [createUser]="createUser" [project]="project"
        *ngIf="app.platform == PLATFORM.ANDROID"></android-app-detail-view>
    <ios-app-detail-view #ios_detail [mobileApp]="app" [createUser]="createUser" [project]="project"
        *ngIf="app.platform == PLATFORM.IOS"></ios-app-detail-view>
    <hr>


    <div class="d-flex row">
        <div class="col-sm-2">
            <ul ngbNav #mainTab="ngbNav" (activeIdChange)="onActiveTabChange($event)" orientation="vertical"
                class="nav nav-tabs nav-tabs--vertical nav-tabs--left text-right m-0 h-100">
                <li [ngbNavItem]="Tab.createTask">
                    <a class="text-wrap" ngbNavLink>{{"mobile-app-detail.label.create-task" | translate}}</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="Tab.history">
                    <a class="text-wrap" ngbNavLink>{{"mobile-app-detail.label.history" | translate}}</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="Tab.keyStore">
                    <a class="text-wrap" ngbNavLink>{{"mobile-app-detail.label.keystore" | translate}}</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
        </div>

        <div class="col-sm-10 pl-0">

            <div [hidden]="mainTab.activeId != Tab.createTask">

                <ul ngbNav #platformConfigTab="ngbNav" [activeId]="0" class="nav-tabs mt-0 mb-4">
                    <li [ngbNavItem]="0">
                        <a ngbNavLink>
                            <i class="fa fa-shield fa-1x"></i>&nbsp;
                            {{"config.security-option-config"|translate}}
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                    <li *ngFor="let config of configs; let j = index" [ngbNavItem]="j+1">
                        <a ngbNavLink>
                            <i class="fa fa-window-restore fa-1x"></i>&nbsp;
                            {{"config." + config.get(TEMPLATE.NAME)|translate}}
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                </ul>

                <div *ngFor="let config of configs; let j = index" class="ml-2"
                    [hidden]="platformConfigTab.activeId != j+1">
                    <task-config-view [template]="config" [readOnly]="false"></task-config-view>
                </div>

                <div class="ml-2" [hidden]="platformConfigTab.activeId != 0">

                    <div
                        [hidden]="!existAny(TEMPLATE.DETECT_ROOTING, TEMPLATE.DETECT_EMULATOR, TEMPLATE.DETECT_DEBUGGER, TEMPLATE.DETECT_FRIDA ,TEMPLATE.DETECT_FORGERY)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.detect' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div *ngIf="app.platform == PLATFORM.ANDROID">
                                    <div class="row mb-1">
                                        <div class="col-sm 12">
                                            <div class="media ml-2">
                                                <div class="media-left">
                                                    <i class="fa fa-info-circle mr-2 text-primary"></i>
                                                </div>
                                                <div class="media-body">
                                                    <span class="text-primary mr-1">{{'detect.messages.following-option'
                                                        |
                                                        translate}}</span>
                                                    <span>{{'detect.messages.is-default' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-1 style3">
                                </div>
                                <div class="row mt-1">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.DETECT_ROOTING" displayName="detect.rootingDevice">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.DETECT_DEBUGGER" displayName="detect.debugger">
                                    </task-option>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        [hidden]="!existAny(TEMPLATE.ENCRYPT_CODE, TEMPLATE.ENCRYPT_STRING, TEMPLATE.ENCRYPT_RESOURCE)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.encrypt' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.ENCRYPT_CODE" displayName="encrypt.code"></task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.ENCRYPT_STRING" displayName="encrypt.string">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.ENCRYPT_RESOURCE" displayName="encrypt.resource">
                                    </task-option>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="!existAny(TEMPLATE.PREVENT_DECOMPILE, TEMPLATE.PREVENT_REPACKAGE)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.prevent' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.PREVENT_DECOMPILE" displayName="prevent.decompile">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.PREVENT_REPACKAGE" displayName="prevent.repackage">
                                    </task-option>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        [hidden]="!existAny(TEMPLATE.OBFUSCATE_PROTOCOL_NAME, TEMPLATE.OBFUSCATE_CATEGORY_NAME, TEMPLATE.OBFUSCATE_PROPERTY_NAME, TEMPLATE.OBFUSCATE_CLASS_NAME, TEMPLATE.OBFUSCATE_METHOD_NAME, TEMPLATE.OBFUSCATE_FIELD_NAME)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.obfuscate' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row"
                                    [hidden]="!existAny(TEMPLATE.OBFUSCATE_PROTOCOL_NAME, TEMPLATE.OBFUSCATE_CATEGORY_NAME, TEMPLATE.OBFUSCATE_PROPERTY_NAME)">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_PROTOCOL_NAME"
                                        displayName="obfuscate.protocol-name"></task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_CATEGORY_NAME"
                                        displayName="obfuscate.category-name"></task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_PROPERTY_NAME"
                                        displayName="obfuscate.property-name"></task-option>
                                </div>
                                <div class="row"
                                    [hidden]="!existAny(TEMPLATE.OBFUSCATE_CLASS_NAME, TEMPLATE.OBFUSCATE_METHOD_NAME, TEMPLATE.OBFUSCATE_FIELD_NAME)">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_CLASS_NAME" displayName="obfuscate.class-name">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_METHOD_NAME"
                                        displayName="obfuscate.method-name">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.OBFUSCATE_FIELD_NAME" displayName="obfuscate.field-name">
                                    </task-option>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        [hidden]="!existAny(TEMPLATE.PREVENT_CLIPBOARD_USAGE, TEMPLATE.PREVENT_SCREEN_CAPTURE, TEMPLATE.PREVENT_CONTROL_USAGE_TIME, TEMPLATE.REMOVE_DEBUG_INFO, TEMPLATE.REMOVE_API_CALL, TEMPLATE.REMOVE_LOGCAT_LOG)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.reinforce' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row mb-2">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.PREVENT_CLIPBOARD_USAGE" displayName="prevent.clipboard">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.PREVENT_SCREEN_CAPTURE"
                                        displayName="prevent.screen-capture">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.PREVENT_CONTROL_USAGE_TIME"
                                        displayName="prevent.control-usage"></task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.REMOVE_DEBUG_INFO" displayName="remove.debug-info">
                                    </task-option>
                                </div>

                                <div class="row">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.REMOVE_API_CALL" displayName="remove.api-call">
                                    </task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.REMOVE_LOGCAT_LOG" displayName="remove.logcat-log">
                                    </task-option>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="!existAny(TEMPLATE.ENCRYPT_STRING)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="form-check-label text-success">
                                    <strong>{{'option-category.string-filter' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mt-2 mb-3">
                            <div class="col-sm-10">
                                <textarea rows="2" class="form-control"
                                    [value]="has(TEMPLATE.ENCRYPT_STRING_FILTER)?get(TEMPLATE.ENCRYPT_STRING_FILTER):''"
                                    (input)="set(TEMPLATE.ENCRYPT_STRING_FILTER, $event.target.value)"></textarea>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="doNotUseObfuscation()">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="form-check-label text-success">
                                    <strong>{{'option-category.obfuscate-filter' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mt-2 mb-3">
                            <div class="col-sm-10">
                                <textarea rows="2" class="form-control"
                                    [value]="has(TEMPLATE.OBFUSCATE_FILTER)?get(TEMPLATE.OBFUSCATE_FILTER):''"
                                    (input)="set(TEMPLATE.OBFUSCATE_FILTER, $event.target.value)"></textarea>
                            </div>
                        </div>
                    </div>


                    <div [hidden]="doNotUseObfuscation() && !get(TEMPLATE.ENCRYPT_STRING)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="form-check-label text-success">
                                    <strong>{{'option-category.obfuscate-target-package' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mt-2 mb-3">
                            <div class="col-sm-10">
                                <textarea rows="2" class="form-control"
                                    [value]="has(TEMPLATE.OBFUSCATE_TARGET_PACKAGE)?get(TEMPLATE.OBFUSCATE_TARGET_PACKAGE):''"
                                    (input)="set(TEMPLATE.OBFUSCATE_TARGET_PACKAGE, $event.target.value)"></textarea>
                            </div>
                        </div>
                    </div>


                    <div [hidden]="!existAny(TEMPLATE.REMOVE_API_CALL)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="form-check-label text-success">
                                    <strong>{{'option-category.remove-api-call-target' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 mt-1 style3">
                        <div class="row mt-2 mb-3">
                            <div class="col-sm-10">
                                <textarea rows="2" class="form-control"
                                    [value]="has(TEMPLATE.REMOVE_TARGET_API)?get(TEMPLATE.REMOVE_TARGET_API):''"
                                    (input)="set(TEMPLATE.REMOVE_TARGET_API, $event.target.value)"></textarea>
                            </div>
                        </div>
                    </div>


                    <div [hidden]="!existAny(TEMPLATE.PREVENT_CONTROL_USAGE_TIME)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="form-check-label text-success">
                                    <strong>{{'option-category.control-usage-time' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-7 mt-1 mb-0">
                                <timezone-selector [value]="timezone" [readOnly]="get(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) == OPTIONAL_BOOLEAN.FALSE"
                                    (onChange)="onChangeTimeZone($event)">
                                </timezone-selector>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row h-100">
                                    <div class="ml-3 mr-3 d-flex align-items-center">
                                        <span class="mr-3 text-nowrap">{{'mobile-app-detail.label.usage-start-time' |
                                            translate}}
                                        </span>
                                        <ngb-timepicker [disabled]="get(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) == OPTIONAL_BOOLEAN.FALSE"
                                            [(ngModel)]="startTime" (ngModelChange)="updateUsageTime()"
                                            [meridian]="true" [minuteStep]="10"></ngb-timepicker>
                                    </div>
                                    <div class="ml-3 mr-3 d-flex align-items-center">
                                        <span class="mr-3 text-nowrap">{{'mobile-app-detail.label.usage-end-time' |
                                            translate}} </span>
                                        <ngb-timepicker [disabled]="get(TEMPLATE.PREVENT_CONTROL_USAGE_TIME) == OPTIONAL_BOOLEAN.FALSE"
                                            [(ngModel)]="endTime" (ngModelChange)="updateUsageTime()" [meridian]="true"
                                            [minuteStep]="10"></ngb-timepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="!existAny(TEMPLATE.ETC_ZIP_ALIGN, TEMPLATE.DONT_SIGN)">
                        <div class="row">
                            <div class="col-sm-12">
                                <small class="text-success">
                                    <strong>{{'option-category.etc' | translate}}</strong>
                                </small>
                            </div>
                        </div>
                        <hr class="m-0 mb-1 style3">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <div class="row">
                                    <task-option class="col-sm-3 pr-0" [handler]="this"
                                        [fieldName]="TEMPLATE.ETC_ZIP_ALIGN" displayName="etc.zip-align"></task-option>
                                    <task-option class="col-sm-3 pr-0" [handler]="this" [fieldName]="TEMPLATE.DONT_SIGN"
                                        displayName="etc.dont-sign"></task-option>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary" [disabled]="state != State.none"
                            (click)="createTask()">
                            {{'mobile-app-detail.button.create-task' | translate}}
                        </button>
                    </div>
                </div>

                <br>

            </div>







            <div [hidden]="mainTab.activeId != Tab.history" class="fill h-100">
                <div [hidden]="taskListState != State.loading" class="h-100">
                    <div class="h-100">
                        <div class="card h-100">
                            <div class="card-body h-100">
                                <div class="row w-100 h-100">
                                    <div class="col-sm-12 d-flex align-items-center">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
                                            </div>
                                            <div class="media-body">
                                                <h4 class="media-heading">{{'common.loading' | translate}}</h4>
                                                {{'common.loading-please-wait' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div [hidden]="taskListState == State.loading" class="h-100">
                    <div [hidden]="taskExist" class="h-100">
                        <div class="card h-100">
                            <div class="card-body h-100">
                                <div class="row w-100 h-100">
                                    <div class="col-sm-12 d-flex align-items-center">
                                        <span class="text-danger multi-line-text">{{"mobile-app-detail.label.no-task" |
                                            translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div [hidden]="!taskExist" class="h-100">
                        <div *ngFor="let task of taskList">
                            <task-view [task]="task" (downloadTaskResult)="onDownloadTaskResult($event)"
                                (downloadObfuscationMap)="onDownloadObfuscationMap($event)"></task-view>
                        </div>
                        <div class="d-flex justify-content-between">
                            <pagenation class="text-center" #pnTask (pageClick)="loadTaskList($event)" small=true>
                            </pagenation>
                            <div>
                            </div>
                            <small
                                class="float-right">{{'mobile-app-detail.label.total-task'|translate:taskList?.page}}</small>
                        </div>
                        <br>
                    </div>
                </div>

            </div>














            <div [hidden]="mainTab.activeId != Tab.keyStore" class="fill h-100">
                <div [hidden]="keystore" class="h-100">
                    <div class="card h-100">
                        <div class="card-body h-100">
                            <div class="row w-100 h-100">
                                <div class="col-sm-12 d-flex align-items-center">
                                    <span class="text-danger multi-line-text">{{"mobile-app-detail.label.no-keystore" |
                                        translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [hidden]="!keystore">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.date' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.date | date:'yyyy-MM-dd HH:mm'}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.common-name' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.commonName}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.organization-unit' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.organizationUnit}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.organization' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.organization}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.locality' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.locality}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.state' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.state}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.country-code' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.countryCode}}</span>
                                </div>
                            </div>

                            <hr class="mt-2 mb-2">

                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.validity' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.validity}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.valid-from' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.validFrom | date:'yyyy-MM-dd HH:mm'}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 text-right pr-0">
                                    <span>{{'keystore.valid-to' | translate}}</span>
                                </div>
                                <div class="col-sm-9">
                                    <span>{{keystore?.validTo | date:'yyyy-MM-dd HH:mm'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






        </div>
    </div>

</div>
<hr>

<div class="row pb-5">
    <div class="col-sm-1 text-right">
    </div>
    <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary text-nowrap" [disabled]="state != State.none" (click)="goBack()">
            {{'common.go-back' | translate}}
        </button>
        <button type="submit" class="btn btn-primary ml-3 text-nowrap" [disabled]="state != State.none"
            (click)="download()">
            {{'mobile-app-detail.button.download-apk' | translate}}
        </button>
    </div>
</div>