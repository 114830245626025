<div class="modal-header">
  <h4 class="modal-title">{{"mobile-app-upload.title" | translate}}</h4>
  <span class="close" aria-label="Close" (click)="activeModal.close(false)">&times;</span>
</div>
<div class="modal-body">

  <div [hidden]="state != State.processing">
    <div class="media">
      <div class="media-left">
        <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
      </div>
      <div class="media-body">
        <h4 class="media-heading mb-1">{{'mobile-app-upload.uploading' | translate}}</h4>
        {{'mobile-app-upload.uploading-please-wait' | translate}}
        <p class="mt-1">
          <ngb-progressbar type="success" [value]="uploadPercent" [striped]="true" [animated]="true" height="30px">
            <h5 class="m-0">{{uploadPercentMsg}}</h5>
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>
  <div [hidden]="state != State.loading">
    <div class="media">
      <div class="media-left">
        <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
      </div>
      <div class="media-body">
        <h4 class="media-heading">{{'common.loading' | translate}}</h4>
        {{'common.loading-please-wait' | translate}}
      </div>
    </div>
  </div>

  <div [hidden]="state != State.none">
    <div class="media">
      <div class="media-left pr-3">
        <i class="fa fa-cloud-upload fa-4x blue"></i>
      </div>
      <div class="media-body">

        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="mb-2">
              <span>{{'mobile-app-upload.file' | translate}}</span>
            </div>
            <div class="custom-file" id="customFile">
              <input #keyStoreFile type="file" class="custom-file-input" id="file" (change)="onFileChange($event)">
              <label class="custom-file-label" for="file">
                {{fileName}}
              </label>
            </div>
            <div class="text-right mt-2" [hidden]="!fileExist">
              <span>{{'mobile-app-upload.file-size' | translate}} : {{fileSize | filesize}}</span>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="state != State.none" class="btn btn-primary pl-4 pr-4" (click)="upload()">{{"common.ok"
    | translate}}</button>
  <button type="button" [disabled]="state != State.none" class="btn btn-secondary pl-4 pr-4" (click)="activeModal.close(false)">{{"common.cancel"
    | translate}}</button>
</div>