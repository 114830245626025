import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService, UserList, User, TaskList, Task, AndroidApp, REL, MobileApp } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';


let log = Logger('RunningTaskListView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-running-task-list-view',
  templateUrl: './running-task-list-view.component.html',
  styleUrls: ['./running-task-list-view.component.css']
})
export class RunningTaskListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public state: State = State.none;
  public tasks: TaskList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;
  private taskleadTimeUpdateTimer: number;
  private taskListUpdateTimer: number;
  private temp: string;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService,
    private router: Router) {

    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    window.clearInterval(this.taskleadTimeUpdateTimer);
    window.clearInterval(this.taskListUpdateTimer);
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${this.page}`);
    this.getList(this.page);
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.getList(this.page);
  }

  ngOnInit() {
    this.taskleadTimeUpdateTimer = window.setInterval(() => this.temp = "", 1000);
    this.taskListUpdateTimer = window.setInterval(() => this.getList(), environment.TASK_LIST_UPDATE_INTERVAL*1000);
  }

  public loadPage(page: number) {
    this.router.navigate([environment.PATH.RUNNING_TASK_LIST], { queryParams: { page } });
  }

  // public loadItem(task: Task) {
  //   this.router.navigate([environment.PATH.RUNNING_TASK_LIST, task.href]);
  // }

  public cancelTask(task: Task) {
    this.common.question("running-task-list.label.confirm-cancel-task-title", "running-task-list.label.confirm-cancel-task-message").then(result => {
      if (!result) {
        return;
      }
      
      this.common.showProcessingStatus("running-task-list.label.cancel-task-in-progress")
      this.state = State.processing;
      this.common.anyway(this.auth.cancelTask(task.href)).then(result => {
        log(`cancelTask > complete ---> ${result}`);
        this.common.anyway(this.getList()).then(_ => {
          this.common.hideProcessingStatus();
        });
      });
    });
  }

  public getList(pageIndex: number = 0) {
    this.state = State.loading;
    return new Promise<TaskList>((resolve, reject) => {
      this.auth.getRunningTaskList(pageIndex).then(list => {
        let complete = () => {
          this.state = State.none;
          this.pg.update(this.tasks.page.pageIndex, this.tasks.page.itemsPerPage, this.tasks.page.totalItems);
          this.updated.emit({
            pageIndex: this.tasks.page.pageIndex, total: this.tasks.page.totalItems
          });

          resolve(this.tasks);
        }

        log(`getList > result ---> `, list);
        this.tasks = list;

        let count = this.tasks.items.length * 3;

        let creatorHandler = (e, task?: Task, user?: User) => {
          count--;
          if (e) {
            log(`getList > error occurred while loading task creator ---> `, e);
          } else {
            task.creator = user;
            log(`getList > task(${task.createTime}) creator --->`, user);
          }

          if (count <= 0) {
            log(`loadTaskList > loading complete`);
            complete();
          }
        }

        let appHandler = (e, task?: Task, app?: MobileApp) => {
          count--;
          if (e) {
            count--;
            log(`getList > error occurred while loading app ---> `, e);
          } else {
            task.app = app;
            log(`getList > task(${task.createTime}) app --->`, app);
            let link = app.getLink(REL.ICON);
            log(`getList > loading icon of app --->`, link)
            this.common.anyway(this.auth.loadIcon(link)).then(icon => {
              task.app.icon = icon;
              count--
              if (count <= 0) {
                log(`loadTaskList > loading complete`);
                complete();
              }
            });
          }

          if (count <= 0) {
            log(`loadTaskList > loading complete`);
            complete();
          }
        }

        if (count == 0) {
          return complete();
        }


        this.tasks.items.forEach(task => {
          let link = task.getLink(REL.CREATOR);
          log(`getList > load task creator --->`, link);
          this.auth.getUser(link).then(user => creatorHandler(null, task, user)).catch(e => creatorHandler(e));

          link = task.getLink(REL.MOBILE_APP);
          log(`getList > load task app --->`, link);
          this.auth.getMobileApp(link).then(app => appHandler(null, task, app)).catch(e => appHandler(e));
        })


      }).catch(e => {
        this.state = State.none;
        log(`getList > error ---> ${e}`);
      })
    })
  }

}
