<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{'task-find.title' | translate}}</h4>
  </div>
</div>

<hr>

<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
</div>

<div class="row mb-2">
  <div class="col-sm-7">
    <div [hidden]="state != State.none" class="h-100">
      <div class="row d-flex align-items-center">
        <div class="col-sm-2 text-right">
          <div class="">
            <span>{{'task-find.label.file' | translate}}</span>
          </div>
        </div>
        <div class="col-sm-10 custom-file" id="customFile">
          <input type="file" class="custom-file-input" id="file" placeholder="{{'task-find.label.file' | translate}}" (change)="onFileChange($event)">
          <label class="custom-file-label" for="file">
            {{fileName}}
            <div class="text-success d-inline-block">
              <small [hidden]="!fileExist">({{fileSize | filesize}})</small>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div [hidden]="state != State.processing" class="h-100">
      <div class="row d-flex align-items-center h-100">
        <div class="col-sm-2 text-right">
          <div class="">
            <span>{{'task-find.label.uploading' | translate}}</span>
          </div>
        </div>
        <div class="col-sm-10 pl-0 pr-0">
          <ngb-progressbar type="success" [value]="uploadPercent" [striped]="true" [animated]="true" height="30px">
            <h5 class="m-0">{{uploadPercentMsg}}</h5>
          </ngb-progressbar>
        </div>
      </div>
    </div>
  </div>



  <div class="col-sm d-flex">
    <button class="btn btn-primary pr-4 pl-4 mr-2" type="button" [disabled]="state != State.none || !fileExist" (click)="upload($event)">
      {{'task-find.button.find' | translate}}
    </button>
  </div>
</div>

<hr>


<div class="row pb-5">
  <div class="col-sm-6 border-right">
    <div class="row mb-2">
      <div class="col-sm-10 media">
        <div class="col-sm-3 pl-0 pr-0 text-right media-left">
          <img src="{{'data:image/jpg;base64,' + app?.icon}}" [width]="50" [height]="50" [hidden]="!app?.icon">
          <i class="fa fa-android fa-3x ml-2 mr-2" [hidden]="app?.icon"></i>
        </div>
        <div class="media-body ml-3 h-100 d-flex align-items-center">
          <div [class.text-danger]="app?.debuggable">
            <h2 class="media-heading mb-0">{{app?.name}}
              <small [hidden]="!app?.debuggable">({{'mobile-app.debuggable'|translate}})</small>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 text-right">
        <span class="pr-0">{{'mobile-app.uniqueAppId' | translate}}</span>
      </div>
      <div class="col-sm pl-0 wrap">
        <span [hidden]="app?.uniqueAppId" class="text-muted">
          {{'common.none' | translate}}
        </span>
        <span [hidden]="!app?.uniqueAppId">
          {{app?.uniqueAppId}}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 text-right">
        <span class="pr-0">{{'mobile-app.hash' | translate}}</span>
      </div>
      <div class="col-sm pl-0 wrap">
        <span [hidden]="app?.hash" class="text-muted">
          {{'common.none' | translate}}
        </span>
        <span class="wrap text-primary" [hidden]="!app?.hash">
          {{app?.hash}}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 text-right">
        <span class="pr-0">{{'mobile-app.version' | translate}}</span>
      </div>
      <div class="col-sm pl-0">
        <span [hidden]="app?.version" class="text-muted">
          {{'common.none' | translate}}
        </span>
        <span [hidden]="!app?.version">
          {{app?.version}} ({{app?.detailedVersion}})
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 text-right">
        <span class="pr-0">{{'mobile-app.sdk-version' | translate}}</span>
      </div>
      <div class="col-sm pl-0">
        <span [hidden]="app?.minimumOsVersion" class="text-muted">
          {{'common.none' | translate}}
        </span>
        <span [hidden]="!app?.minimumOsVersion">
          {{app?.minimumOsVersion}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 text-right">
        <span class="pr-0">{{'mobile-app.target-sdk' | translate}}</span>
      </div>
      <div class="col-sm pl-0">
        <span [hidden]="app?.targetOsVersion" class="text-muted">
          {{'common.none' | translate}}
        </span>
        <span [hidden]="!app?.targetOsVersion">
          {{app?.targetOsVersion}}
        </span>
      </div>
    </div>


  </div>

  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm">
        <small>
          <strong>
            {{'task-find.label.hash-matched-task' | translate}}
          </strong>
        </small>
      </div>
    </div>
    <hr class="m-0 mb-2 style3">
    <div class="row">
      <div class="col-sm">
        <table class="table table-sm table-hover table-rounded">
          <thead class="thead thead-light">
            <tr class="row m-0" scope="row">
              <th class="col-sm-2">
                <small>
                  <strong>{{'task-find.label.task-start-time' | translate}}</strong>
                </small>
              </th>
              <th class="col-sm">
                <small>
                  <strong>{{'task-find.label.task-hash' | translate}}</strong>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="row m-0 h-100" scope="row" [hidden]="!hashMatchedTask">
              <td class="col-sm-2 border-right pt-0 pb-0">
                <div class=" text-primary wrap">
                  <span>{{hashMatchedTask?.createTime | date:'yyyy-MM-dd HH:mm'}}</span>
                </div>
              </td>
              <td class="col-sm pt-0 pb-0">
                <div class=" text-primary wrap">
                  <span>{{hashMatchedTask?.hash}}</span>
                  <br>
                  <hr class="m-0">
                  <task-option-badge-view [task]="hashMatchedTask"></task-option-badge-view>
                </div>
              </td>
            </tr>
            <tr class="row m-0 h-100" scope="row" [hidden]="hashMatchedTask">
              <td class="col-sm text-muted">
                <span>{{'common.none' | translate}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br>
    <br>
    <div class="row">
      <div class="col-sm">
        <small>
          <strong>
            {{'task-find.label.same-package-named-task' | translate}}
          </strong>
        </small>
      </div>
    </div>
    <hr class="m-0 mb-2 style3">
    <div class="row">
      <div class="col-sm">
        <table class="table table-sm table-hover table-rounded">
          <thead class="thead thead-light">
            <tr class="row m-0" scope="row">
              <th class="col-sm-2">
                <small>
                  <strong>{{'task-find.label.task-start-time' | translate}}</strong>
                </small>
              </th>
              <th class="col-sm">
                <small>
                  <strong>{{'task-find.label.task-hash' | translate}}</strong>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let task of taskList" class="row m-0 h-100" scope="row" [hidden]="!taskListExist()">
              <td class="col-sm-2 border-right pt-0 pb-0">
                <div class="text-secondary wrap h-100">
                  <span>{{task?.createTime | date:'yyyy-MM-dd HH:mm'}}</span>
                </div>
              </td>
              <td class="col-sm pt-0 pb-0">
                <div class="text-muted wrap">
                  <span>{{task?.hash}}</span>
                  <br>
                  <hr class="m-0">
                  <task-option-badge-view [task]="task"></task-option-badge-view>
                </div>
              </td>
            </tr>
            <tr class="row m-0 h-100" scope="row" [hidden]="taskListExist()">
              <td class="col-sm text-muted">
                <span>{{'common.none' | translate}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div>
      </div>
      <pagenation class="text-center" #pg (pageClick)="loadPage($event)" [hidden]="!taskListExist()"></pagenation>
      <span class="float-right" [hidden]="!taskListExist()">{{'task-find.label.total-app'|translate:taskList?.page}}</span>
    </div>
  </div>

</div>
<br>