import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { User } from '../../service/auth.service';

@Component({
  selector: 'user-profile-popover',
  templateUrl: './user-profile-popover.component.html',
  styleUrls: ['./user-profile-popover.component.css']
})
export class UserProfilePopoverComponent implements OnInit {
  @Input()
  public showLogout: boolean = false;
  @Input()
  public user: User
  @ViewChild("template")
  public template: any
  @Output()
  public logout = new EventEmitter<void>();

  
  
  constructor() { }

  ngOnInit() {
  }

  public onLogoutClick() {
    this.logout.emit();
  }
}
