import { Component, OnInit, Input } from '@angular/core';
import { AndroidApp, MobileApp, PLATFORM, User, Project } from '../../service/auth.service';

enum ActiveInfo {
  none,
  permission,
  library,
  feature,
  supportedScreen,
  nativeCode
}

@Component({
  selector: 'android-app-detail-view',
  templateUrl: './android-app-detail-view.component.html',
  styleUrls: ['./android-app-detail-view.component.css']
})
export class AndroidAppDetailViewComponent implements OnInit {
  @Input()
  get mobileApp(): MobileApp {
    return this.app;
  }
  set mobileApp(mobileApp: MobileApp) {
    this.app = mobileApp as AndroidApp;
  }

  @Input()
  public createUser: User = null;
  @Input()
  public project: Project = null;

  public app: AndroidApp
  public ActiveInfo = ActiveInfo;
  public info: ActiveInfo = ActiveInfo.none;
  public etcInfoList = new Array<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public setInfo(newInfo: ActiveInfo) {
    if (this.info == newInfo) {
      this.info = ActiveInfo.none;
      return;
    }

    this.info = newInfo;
    if (this.info == ActiveInfo.permission) {
      this.etcInfoList = this.app.usesPermissions;
    } else if (this.info == ActiveInfo.library) {
      this.etcInfoList = this.app.usesLibraries;
    } else if (this.info == ActiveInfo.feature) {
      this.etcInfoList = this.app.usesFeatures;
    } else if (this.info == ActiveInfo.supportedScreen) {
      this.etcInfoList = this.app.supportedScreens;
    } else if (this.info == ActiveInfo.nativeCode) {
      this.etcInfoList = this.app.nativeCodes;
    } else {
      this.etcInfoList = null;
    }
  }

}
