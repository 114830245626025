import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService, UserList, User, TaskList, Task, AndroidApp, REL, PARAM, MobileApp, TEMPLATE } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, Logger } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CommonService, DateValue } from '../../service/common.service';
import { Template } from '../../service/cj';


let log = Logger('TaskListView');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.MANAGER_ID, "creator-id"],
  [PARAM.MANAGER_NAME, "creator-name"],
  [PARAM.PROJECT_NAME, "project-name"],
  [PARAM.DATE, "create-time"],
]);


enum State {
  none,
  loading,
  processing
}
@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html',
  styleUrls: ['./task-list-view.component.css']
})
export class TaskListViewComponent implements OnInit, OnDestroy {
  @Output()
  public updated = new EventEmitter<any>();

  public State = State;
  public TEMPLATE = TEMPLATE;
  public PARAM = PARAM;
  public state: State = State.none;
  public tasks: TaskList;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;


  public searchItems: Map<string, any> = null;
  public userSearchField: string;
  public userSearchValue: string;

  public startDate: DateValue;
  public endDate: DateValue;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService,
    private router: Router) {

    log(`parameter ---> `, route.queryParams);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${this.page}`);

    SEARCH_FIELDS.forEach((v, field) => {
      if (params.hasOwnProperty(field) == false) {
        return;
      }

      let value = params[field];

      if (field == PARAM.DATE) {
        let s = value.split("~");
        if (s.length >= 1) {
          this.startDate = DateValue.readDateValue(s[0]);
        }
        if (s.length >= 2) {
          this.endDate = DateValue.readDateValue(s[1]);
        }
      } else if (field == PARAM.MANAGER_ID || field == PARAM.MANAGER_NAME) {
        this.userSearchField = field;
        this.userSearchValue = value;
      }
    });
    this.load();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  ngOnInit() {
  }

  public loadPage(page: number) {
    if ((this.common.isDateSpecified(this.startDate) || this.common.isDateSpecified(this.endDate)) && !this.common.isDateRangeValid(this.startDate, this.endDate)) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.invalid-date-range")
      return;
    }

    let queryParams = { page };
    queryParams[PARAM.DATE] = this.common.dateValueToDateRange(this.startDate, this.endDate);
    queryParams[this.userSearchField] = this.userSearchValue;
    log(`loadPage > queryParams --->`, queryParams);
    this.router.navigate([environment.PATH.TASK_LIST], { queryParams });
  }


  public load() {
    this.state = State.loading;
    this.common.anyway(this.auth.getTaskSearchQueryData()).then(data => {
      this.state = State.none;
      log(`load > task-search query data ---> `, data);
      this.searchItems = data;
      this.selectUserSearchField();
      this.search(this.page);

    }).catch(e => {
      this.state = State.none;
      log(`load > error ---> ${e}`);
    })
  }

  public selectUserSearchField() {
    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);

    let findInSearchField = (k: string) => this.userSearchField == k;
    let findInSearchItems = (k: string) => this.searchItems.has(k);

    let find = (this.userSearchField) ? findInSearchField : findInSearchItems;
    let found = false;
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && find(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }





  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.DATE, this.common.dateValueToDateRange(this.startDate, this.endDate));
    this.searchItems.set(this.userSearchField, this.userSearchValue);
    this.searchItems.set(PARAM.PAGE, page);
    return new Promise<TaskList>((resolve, reject) => {
      this.auth
        .searchTask(this.searchItems)
        .then(list => {
          log(`search > result --->`, list);
          this.tasks = list;

          let promises = Array<Promise<void>>();

          this.tasks.items.forEach(task => {
            let taskAppLink = task.getLink(REL.MOBILE_APP);
            log(`search > load task app --->`, taskAppLink);
            let p2 = this.auth
              .getMobileApp(taskAppLink)
              .then(app => {
                task.app = app;
                log(`search > task(${task.createTime}) app --->`, app);
                let appIconLink = app.getLink(REL.ICON);
                log(`search > loading icon of app --->`, appIconLink)
                return this.common.anyway(this.auth.loadIcon(appIconLink)).then(icon => task.app.icon = icon);
              })
              .catch(e => {
                log(`search > error while get app of task ---> ${e}`);
                task.app = null;
              });
            promises.push(p2);
          })
          return Promise.all(promises);
        })
        .then(_ => {
          this.state = State.none;
          this.pg.update(this.tasks.page.pageIndex, this.tasks.page.itemsPerPage, this.tasks.page.totalItems);
          this.updated.emit({
            pageIndex: this.tasks.page.pageIndex, total: this.tasks.page.totalItems
          });

          resolve(this.tasks);
        })
        .catch(e => {
          this.state = State.none;
          log(`search > error ---> ${e}`);
        })
    })
  }

  public appliedConfigNames(task: Task): Array<string> {
    return this.auth.getAppliedConfigNames(task.configs);
  }

  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    this.userSearchField = f;
  }

  public get selectedUserSearchFieldName(): string {
    return SEARCH_FIELDS.get(this.userSearchField);
  }

  public isEmptyString(s: string): boolean {
    return (!s || s.length == 0)
  }

  public download() {
    let link = this.tasks.getLink(REL.EXPORT_TO_CSV);
    log(`download > link ---> ${link}`);
    this.auth.download(link);
  }
}
