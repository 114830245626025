import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordChangeReason } from '../../service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public PasswordChangeReason = PasswordChangeReason;
  public changeEnabled: boolean;
  public password: string;
  public reason = PasswordChangeReason.unknown;

  constructor(public activeModal: NgbActiveModal) {
    this.changeEnabled = true;
    this.password = "";
  }

  ngOnInit() {
  }

  checkInputLengthThenClose() {
    if (this.password.trim().length == 0) {
      return;
    }

    this.close();
  }

  close() {
    this.activeModal.close(this.password.trim());
  }
}
