<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{'task-list.title' | translate}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>

<div [hidden]="state == State.loading">
  <div class="row d-flex justify-content-between mb-4">
    <div class="col-sm-12 d-flex">
      <div class="col-sm-4">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle
                [disabled]="state != State.none">
                {{'task.'+selectedUserSearchFieldName | translate}}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button [hidden]="!has(PARAM.MANAGER_ID)" class="dropdown-item"
                  (click)="set(PARAM.MANAGER_ID)">{{'task.creator-id'
                  | translate}}</button>
                <button [hidden]="!has(PARAM.MANAGER_NAME)" class="dropdown-item"
                  (click)="set(PARAM.MANAGER_NAME)">{{'task.creator-name'
                  | translate}}</button>
                <button [hidden]="!has(PARAM.PROJECT_NAME)" class="dropdown-item"
                  (click)="set(PARAM.PROJECT_NAME)">{{'task.project-name'
                  | translate}}</button>
              </div>
            </div>
          </div>
          <div class="col-sm pl-0 pr-0">
            <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)"
              [(ngModel)]="userSearchValue" [disabled]="state != State.none">
          </div>
        </div>
      </div>

      <div class="col-sm-5 d-flex pl-0">
        <div class="flex-fill">
          <div class="input-group">
            <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select"
              outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate"
              readonly="true">
            <span class="input-group-btn">
              <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null">
                <i class="fa fa-times"></i>
              </a>
            </span>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
          </div>
        </div>
        <span class="ml-2 mr-2">~</span>
        <div class="flex-fill">
          <div class="input-group">
            <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select"
              outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate"
              readonly="true">
            <span class="input-group-btn">
              <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null">
                <i class="fa fa-times"></i>
              </a>
            </span>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm d-flex">
        <button class="btn btn-primary pr-4 pl-4 mr-2 text-nowrap" type="button" [disabled]="state != State.none"
          (click)="loadPage(0)">{{'task-list.button.search'
          | translate}}</button>
        <button class="btn btn-primary pr-4 pl-4 text-nowrap" type="button" [disabled]="state != State.none"
          (click)="download()">{{'task-list.button.save'
          | translate}}</button>
      </div>
    </div>
  </div>


  <hr>




  <table class="table table-sm table-hover table-rounded">
    <thead class="thead thead-light">
      <tr class="row m-0" scope="row">
        <th class="col-sm-3">{{'task-list.label.app-name' | translate}}</th>
        <th class="col-sm-2">{{'task-list.label.task-creator' | translate}}</th>
        <th class="col-sm-2">{{'task-list.label.task-start-time' | translate}}</th>
        <th class="col-sm">{{'task-list.label.task-option' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let task of tasks" class="row m-0 h-100" scope="row">
        <td class="col-sm-3">
          <div class="media">
            <div class="media-left">
              <img [src]="task?.app?.icon" [width]="50" [height]="50" [hidden]="!task?.app?.icon">
              <i class="fa fa-android fa-3x ml-2 mr-2" [hidden]="task?.app?.icon"></i>
            </div>
            <div class="media-body ml-2">
              <span class="wrap">{{task?.app?.name}} v{{task?.app?.version}}({{task?.app?.versionCode}})</span>
              <br>
              <span class="wrap text-secondary">{{task?.app?.package}}</span>
            </div>
          </div>
        </td>

        <td class="col-sm-2">
          <span class="wrap">{{task?.creator?.name}} ({{task?.creator?.id}})</span>
        </td>

        <td class="col-sm-2">
          <div class="text-secondary">
            <span>{{'task.state' | translate}}: {{'task-state.'+task.state | translate}}</span>
            <br>
            <span>{{'task.create-time' | translate}}: {{task?.createTime | date:'yyyy-MM-dd HH:mm'}}</span>
            <br>
            <span>{{'task.complete-time' | translate}}: {{task?.completeTime | date:'yyyy-MM-dd HH:mm'}}</span>
            <br>
            <span>{{'task.lead-time' | translate}}: {{task?.leadTimeStr}}</span>
          </div>
        </td>

        <td class="col-sm">
          <div class="row mr-0 ml-0 mb-1">
            <task-option-badge-view [task]="task"></task-option-badge-view>
          </div>
          <div class="row mr-0 ml-0">
            <samll class="badge badge-pill badge-primary mr-1" *ngFor="let n of appliedConfigNames(task)">{{'config.'+n | translate}}</samll>
          </div>

          <div [ngbCollapse]="true">
            <div class="row mr-0 ml-0"
              [hidden]="task.encrypt.string != true || isEmptyString(task.encrypt.stringFilter)">
              <span>{{'task.string-filter' | translate}}</span>
              <br>
              <span class="multi-line-text wrap text-secondary">{{task.encrypt.stringFilter}}</span>
            </div>

            <div class="row mr-0 ml-0"
              [hidden]="task.obfuscate.isNotUse || isEmptyString(task.obfuscate.targetPackage)">
              <div>
                <span>{{'task.obfuscation-target-package' | translate}}</span>
                <br>
                <span class="multi-line-text wrap text-secondary">{{task.obfuscate.targetPackage}}</span>
              </div>
            </div>

            <div class="row mr-0 ml-0" [hidden]="task.obfuscate.isNotUse || isEmptyString(task.obfuscate.filter)">
              <span>{{'task.obfuscation-filter' | translate}}</span>
              <br>
              <span class="multi-line-text wrap text-secondary">{{task.obfuscate.filter}}</span>
            </div>

            <div class="row mr-0 ml-0"
              [hidden]="task.prevent.controlUsageTime != true || isEmptyString(task.prevent.usageTime)">
              <span>{{'task.usage-time' | translate}}</span>
              <br>
              <span class="multi-line-text wrap text-secondary">{{appUsageStartTime}} ~ {{appUsageEndTime}}</span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <!-- <div class="row"> -->
  <div class="d-flex justify-content-between pb-5">
    <div>
    </div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'task-list.label.total-task'|translate:tasks?.page}}</span>
  </div>

  <br>




</div>