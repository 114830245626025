import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Logger } from '../../../environments/environment';
import { Qna } from '../../service/auth.service';


let log = Logger("ReplyView");

@Component({
  selector: 'reply-view',
  templateUrl: './reply-view.component.html',
  styleUrls: ['./reply-view.component.css']
})
export class ReplyViewComponent implements OnInit, OnDestroy {
  @Input()
  public reply: Qna;
  @Output()
  public delete = new EventEmitter<Qna>();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  public onDelete() {
    log(`onDelete`);
    this.delete.emit(this.reply);
  }

}
