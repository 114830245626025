<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{project?.name}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>

<div [hidden]="state == State.loading">
  <table class="table table-sm table-hover table-rounded">
    <thead class="thead thead-light">
      <tr class="row m-0" scope="row">
        <th class="col-sm-4">{{'mobile-app.name' | translate}}</th>
        <th class="col-sm-2">{{'mobile-app.date' | translate}}</th>
        <th class="col-sm-2">{{'mobile-app.version' | translate}}</th>
        <th class="col-sm-2">{{'mobile-app.creator' | translate}}</th>
        <th class="col-sm-1">{{'mobile-app.file-size' | translate}}</th>
        <th class="col-sm-1 d-flex justify-content-center">{{'mobile-app.platform' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let app of mobileApps" class="row m-0 h-100" [class.text-danger]="app?.debuggable" scope="row"
        (click)="loadItem(app)">
        <td class="col-sm-4 d-flex align-items-center">
          <img [src]="app.icon" [width]="50" [height]="50" [hidden]="!app.icon">
          <i class="fa fa-3x fa-fw {{'platform-icon.' + app?.platform|translate}} ml-0 mr-0" [hidden]="app.icon"></i>
          <span class="ml-2">
            <i class="fa fa-bug" [hidden]="!app?.debuggable"></i>
            {{app?.name}}
          </span>
        </td>
        <td class="col-sm-2 d-flex align-items-center">
          <span class="text-wrap">{{app?.date | date:'yyyy-MM-dd HH:mm'}}</span>
        </td>
        <td class="col-sm-2 d-flex align-items-center">
          <span class="text-wrap">{{app?.version}} ({{app?.detailedVersion}})</span>
        </td>
        <td class="col-sm-2 d-flex align-items-center">
          <span class="text-wrap">{{app?.userName}}</span>
        </td>
        <td class="col-sm-1 d-flex align-items-center">
          <span class="text-wrap">{{app?.fileSize | filesize}}</span>
        </td>
        <td class="col-sm-1 d-flex align-items-center justify-content-center">
          <i class="text-primary fa fa-2x fa-fw {{'platform-icon.' + app?.platform|translate}}"></i>
        </td>
      </tr>
    </tbody>
  </table>


  <!-- <div class="row"> -->
  <div class="d-flex justify-content-between">
    <div>
    </div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'mobile-app-list.label.total-mobile-app'|translate:mobileApps?.page}}</span>
  </div>

  <br>

  <div class="row d-flex justify-content-between pb-5">
    <div></div>
    <div class="col-sm-10 d-flex">
      <div class="row input-group">
        <div class="input-group-prepend">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
              {{'mobile-app.'+selectedFieldName | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button [hidden]="!has(PARAM.MANAGER_ID)" class="dropdown-item" (click)="set(PARAM.MANAGER_ID)">{{'mobile-app.upload-user-id'
                | translate}}</button>
              <button [hidden]="!has(PARAM.MANAGER_NAME)" class="dropdown-item" (click)="set(PARAM.MANAGER_NAME)">{{'mobile-app.upload-user-name'
                | translate}}</button>
              <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'mobile-app.name' |
                translate}}</button>
              <button [hidden]="!has(PARAM.PACKAGE)" class="dropdown-item" (click)="set(PARAM.PACKAGE)">{{'mobile-app.package'
                | translate}}</button>
              <button [hidden]="!has(PARAM.DATE)" class="dropdown-item" (click)="set(PARAM.DATE)">{{'mobile-app.date' |
                translate}}</button>
              <button [hidden]="!has(PARAM.VERSION_CODE)" class="dropdown-item" (click)="set(PARAM.VERSION_CODE)">{{'mobile-app.version-code'
                | translate}}</button>
              <button [hidden]="!has(PARAM.VERSION)" class="dropdown-item" (click)="set(PARAM.VERSION)">{{'mobile-app.version'
                | translate}}</button>
              <button [hidden]="!has(PARAM.SDK_VERSION)" class="dropdown-item" (click)="set(PARAM.SDK_VERSION)">{{'mobile-app.sdk-version'
                | translate}}</button>
              <button [hidden]="!has(PARAM.TARGET_SDK)" class="dropdown-item" (click)="set(PARAM.TARGET_SDK)">{{'mobile-app.target-sdk'
                | translate}}</button>
            </div>
          </div>
        </div>
        <div [hidden]="searchField == PARAM.DATE" class="col-sm pl-0 pr-0">
          <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)" [(ngModel)]="searchValue" [disabled]="state != State.none">
        </div>
        <div [hidden]="searchField != PARAM.DATE" class="col-sm pl-0 pr-0">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate"
                  readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
            <span class="ml-2 mr-2">~</span>
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="input-group-append mr-4">
          <button class="btn btn-primary pr-4 pl-4 text-nowrap" type="button" [disabled]="state != State.none" (click)="loadPage(0)">{{'common.search'|translate}}</button>
        </div>
      </div>

      <button type="submit" class="btn btn-secondary text-nowrap" [disabled]="state != State.none" (click)="goBack()">
        {{'common.go-back' | translate}}
      </button>
      <button type="submit" class="btn btn-primary ml-3 text-nowrap" [disabled]="state != State.none || !mobileApps" (click)="upload()">
        {{'mobile-app-list.button.upload' | translate}}
      </button>
    </div>
  </div>

</div>