import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, UserList, AuthError, User, KeyStoreList, KeyStore } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Logger, environment } from '../../../environments/environment';

let log = Logger('MobileAppUploadDialog');


enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-mobile-app-upload-dialog',
  templateUrl: './mobile-app-upload-dialog.component.html',
  styleUrls: ['./mobile-app-upload-dialog.component.css']
})
export class MobileAppUploadDialogComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  private uri: string;
  private uploadLink: string;
  public fileName: string;
  private authSubscription: Subscription = null;
  public fileSize: number;
  private uploadSize: number;
  public uploadPercent: number;
  public uploadPercentMsg: string;
  public fileExist: boolean = false;
  private file: File = null;


  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService) {

    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`close....`)
    this.cancel();
  }

  private load() {
    this.state = State.loading;
    this.auth.getMobileAppUploadLink(this.uri).then(uploadLink => {
      log(`load > uploadLink ---> `, uploadLink);
      this.uploadLink = uploadLink;
      this.state = State.none;

    }).catch(e => {
      log(`load > loading template error ---> ${e}`);
      this.common.showError("common.error", e).then(_ => {
        this.close();
      });
    });
  }

  public init(uri: string) {
    this.uri = uri;
    this.load();
  }

  ngOnInit() {
  }

  public close(): void {
    this.activeModal.close(true);
  }

  public cancel(): void {
    this.activeModal.close(false);
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.fileSize = this.file.size;
      this.uploadSize = 0;
      this.fileExist = true;
    }
  }

  private updateProgress(current: number, total: number) {
    this.uploadSize = current;
    this.fileSize = total;
    this.uploadPercent = (current / total) * 100;
    this.uploadPercentMsg = `${this.uploadPercent.toFixed(0)} %`
  }

  upload() {
    log(`upload > upload link ---> `, this.uploadLink);
    if (!this.common.isValidFileExtension(this.file.name)) {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.unsupported-app")
      return;
    }
    this.state = State.processing;
    this.auth.uploadMobileApp(this.uploadLink, this.file, (current, total) => {
      log(`upload progress ---> ${current} / ${total}`);
      this.updateProgress(current, total);

    }).then(response => {
      this.state = State.none;
      this.updateProgress(this.fileSize, this.fileSize);
      log(`upload response ---> `, response);
      this.close();

    }).catch(e => {
      this.state = State.none;
      this.uploadSize = 0;
      log(`upload error ---> `, e);
      this.common.showError("common.error", e);
    });
  }
}
