<div class="row">
  <div class="col-sm-12 ml-4">
    <h4 class="mb-0">{{'keystore-list.title' | translate}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>
<div [hidden]="state == State.loading">

  <table class="table table-sm table-hover table-rounded">
    <thead class="thead thead-light">
      <tr class="row m-0" scope="row">
        <th class="col-sm-2">{{'keystore.date' | translate}}</th>
        <th class="col-sm-2">{{'keystore.name' | translate}}</th>
        <th class="col-sm-2">{{'keystore.common-name' | translate}}</th>
        <th class="col-sm-2">{{'keystore.organization-unit' | translate}}</th>
        <th class="col-sm-2">{{'keystore.organization' | translate}}</th>
        <th class="col-sm-2">{{'keystore.algorithm' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ks of keystores" class="row m-0" [class.text-success]="ks?.uploaded" scope="row"
        (click)="loadItem(ks)">
        <td class="col-sm-2">
          <span class="text-wrap">{{ks?.date | date:'yyyy-MM-dd HH:mm'}}</span>
        </td>
        <td class="col-sm-2">
          <!-- <i class="fa fa-asterisk mr-1" [hidden]="ks?.uploaded"></i> -->
          <i class="fa fa-cloud-upload mr-1" [hidden]="!ks?.uploaded"></i>
          <span class="text-wrap">{{ks?.name}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{ks?.commonName}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{ks?.organizationUnit}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{ks?.organization}}</span>
        </td>
        <td class="col-sm-2">
          <span class="text-wrap">{{ks?.algorithm}}</span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between">
    <div></div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'keystore-list.label.total-keystore'|translate:keystores?.page}}</span>
  </div>

  <br>


  <div class="row d-flex justify-content-between pb-5">
    <div></div>
    <div class="col-sm-10 d-flex">
      <div class="input-group">
        <div class="input-group-prepend">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle
              [disabled]="state != State.none">
              {{'keystore.'+selectedFieldName | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'keystore.name' |
                translate}}</button>
              <button [hidden]="!has(PARAM.NOTE)" class="dropdown-item" (click)="set(PARAM.NOTE)">{{'keystore.note' |
                translate}}</button>
              <button [hidden]="!has(PARAM.COMMON_NAME)" class="dropdown-item"
                (click)="set(PARAM.COMMON_NAME)">{{'keystore.common-name'
                | translate}}</button>
              <button [hidden]="!has(PARAM.ORGANIZATION_UNIT)" class="dropdown-item"
                (click)="set(PARAM.ORGANIZATION_UNIT)">{{'keystore.organization-unit'
                | translate}}</button>
              <button [hidden]="!has(PARAM.ORGANIZATION)" class="dropdown-item"
                (click)="set(PARAM.ORGANIZATION)">{{'keystore.organization'
                | translate}}</button>
              <button [hidden]="!has(PARAM.LOCALITY)" class="dropdown-item"
                (click)="set(PARAM.LOCALITY)">{{'keystore.locality'
                | translate}}</button>
              <button [hidden]="!has(PARAM.STATE)" class="dropdown-item" (click)="set(PARAM.STATE)">{{'keystore.state'
                | translate}}</button>
              <button [hidden]="!has(PARAM.COUNTRY_CODE)" class="dropdown-item"
                (click)="set(PARAM.COUNTRY_CODE)">{{'keystore.country-code'
                | translate}}</button>
              <button [hidden]="!has(PARAM.ALIAS)" class="dropdown-item" (click)="set(PARAM.ALIAS)">{{'keystore.alias'
                | translate}}</button>
              <button [hidden]="!has(PARAM.DATE)" class="dropdown-item" (click)="set(PARAM.DATE)">{{'keystore.date' |
                translate}}</button>
              <button [hidden]="!has(PARAM.EXPIRE_DATE)" class="dropdown-item"
                (click)="set(PARAM.EXPIRE_DATE)">{{'keystore.expire-date'
                | translate}}</button>
              <button [hidden]="!has(PARAM.ALGORITHM)" class="dropdown-item"
                (click)="set(PARAM.ALGORITHM)">{{'keystore.algorithm'
                | translate}}</button>
            </div>
          </div>
        </div>
        <div [hidden]="[PARAM.DATE, PARAM.EXPIRE_DATE].includes(searchField)" class="col-sm pl-0 pr-0">
          <input type="text" maxlength="100" class="form-control" (keyup.enter)="loadPage(0)" [(ngModel)]="searchValue"
            [disabled]="state != State.none">
        </div>
        <div [hidden]="!([PARAM.DATE, PARAM.EXPIRE_DATE].includes(searchField))" class="col-sm pl-0 pr-0">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths=1 navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="startDate"
                  readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="startDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
            <span class="ml-2 mr-2">~</span>
            <div class="flex-fill">
              <div class="input-group">
                <input class="form-control" placeholder="" name="dp" displayMonths="1" navigation="select"
                  outsideDays="visible" showWeekNumbers="true" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="endDate" readonly="true">
                <span class="input-group-btn">
                  <a class="btn" style="color: rgb(78, 78, 78); text-decoration: none; " (click)="endDate = null">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar-check-o"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="input-group-append mr-4">
          <button class="btn btn-primary pr-4 pl-4 text-nowrap" type="button" [disabled]="state != State.none"
            (click)="loadPage(0)">{{'common.search'|translate}}</button>
        </div>
      </div>

      <button type="submit" class="btn btn-primary text-nowrap" [disabled]="state != State.none" (click)="createItem()">
        {{'keystore-list.button.create-keystore' | translate}}
      </button>
      <button type="submit" class="btn btn-primary ml-2 text-nowrap" [disabled]="state != State.none" (click)="addItem()">
        {{'keystore-list.button.add-keystore' | translate}}
      </button>
    </div>
  </div>


</div>