import { Component, Input } from '@angular/core';
import { TemplateView } from '../../common/template-view/template-view';
import { PLATFORM, TEMPLATE } from '../../service/auth.service';
import { Template } from '../../service/cj';
import { CommonService } from '../../service/common.service';

@Component({
  template: ''
})
export class ConfigViewComponent extends TemplateView {
  public TEMPLATE = TEMPLATE;
  public PLATFORM = PLATFORM;
  @Input()
  public readOnly: boolean = false;
  @Input()
  public template: Template = null;

  constructor(protected common: CommonService) {
    super();
  }

  public clickOption(fieldName: string, e: any) {
    if (this.readOnly) {
      e.preventDefault();
      return;
    }

    let value = this.get(fieldName);
    this.set(fieldName, !value);
  }

  public getImageSource(fieldName: string): string {
    return "data:image/png;base64," + this.get(fieldName)
  }

  public isImageExist(fieldName: string): boolean {
    return this.exist(fieldName) && this.get(fieldName).length > 0;
  }

  public onFileChange(fieldName: string, event: any) {
    if (!event.target.files || event.target.files.length <= 0) {
      return;
    }

    let reader = new FileReader();
    let file = event.target.files[0];
    let fileType = file.type;
    if (fileType != "image/png") {
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.unsupported-image")
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      let data = reader.result.toString().split(',')[1]
      this.set(fieldName, data);
    }; 
  }
}
