import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, User, KeyStore, REL } from '../../service/auth.service';
import { environment, Logger } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';

let log = Logger('KeyStoreDetailView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-key-store-detail-view',
  templateUrl: './key-store-detail-view.component.html',
  styleUrls: ['./key-store-detail-view.component.css']
})
export class KeyStoreDetailViewComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  public keystore: KeyStore = null;
  private itemUri: string;
  private authSubscription: Subscription = null;

  constructor(
    route: ActivatedRoute, 
    private location: Location, 
    private router: Router,
    private auth: AuthService,
    private common: CommonService) {
      
    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getKeyStore(this.itemUri).then(keystore => {
      this.keystore = keystore;
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  ngOnInit() {
  }

  public goBack() {
    this.location.back();
  }

  get isProcessing():boolean {
    return this.state == State.processing;
  }

  public delete() {
    this.common.question("keystore-detail.label.delete-title", "keystore-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.auth.deleteKeyStore(this.itemUri).then(_ => {
        this.state = State.none;
        this.goBack();
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });
  }

  public download() {
    let uri = this.keystore.getLink(REL.FILE);
    this.auth.download(uri);
  }
}
