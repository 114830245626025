<div class="row">
  <div class="col-sm-12 ml-4">
    <h4>{{'project-list.title' | translate}}</h4>
  </div>
</div>

<hr>
<div [hidden]="state != State.loading">
  <div class="media">
    <div class="media-left">
      <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
    </div>
    <div class="media-body">
      <h4 class="media-heading">{{'common.loading' | translate}}</h4>
      {{'common.loading-please-wait' | translate}}
    </div>
  </div>
  <hr>
</div>

<div [hidden]="state == State.loading">
  <div class="row">
    <div class="offset-1 col-10 no-padding d-flex flex-wrap">
      <div *ngFor="let project of projects" class="col-6">
        <div class="card mb-3" hover-class="btn-primary" (click)="loadItem(project)">
          <div class="card-body">
            <div class="media">
              <div class="media-left pr-2">
                <i *ngFor="let platform of project.platforms" class="text-primary fa fa-2x fa-fw {{'platform-icon.' + platform|translate}}"></i>
              </div>
              <div class="media-body">
                <h4 class="card-title no-overflow-x mb-0">
                  {{project.name}}
                </h4>
                <p class="card-text no-overflow-x">
                  {{project.packageFilter}}
                  <br>
                  <small class="no-overflow-x">
                    {{project.date | date:'yyyy-MM-dd HH:mm'}}
                  </small>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-0">
  <!-- <div class="row"> -->
  <div class="d-flex justify-content-between">
    <div>
    </div>
    <pagenation class="text-center" #pg (pageClick)="loadPage($event)"></pagenation>
    <span class="float-right">{{'project-list.label.total-project'|translate:projects?.page}}</span>
  </div>
  <!-- </div> -->
  <br>
</div>