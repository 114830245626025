import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, UserList, AuthError, User, KeyStoreList, KeyStore, PARAM } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { Subscription } from 'rxjs';
import { Logger } from '../../../environments/environment';

let log = Logger('UserSearchDialog');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.NAME, "name"],
  [PARAM.NOTE, "note"],
  [PARAM.COMMON_NAME, "common-name"],
  [PARAM.ORGANIZATION_UNIT, "organization-unit"],
  [PARAM.ORGANIZATION, "organization"],
  [PARAM.LOCALITY, "locality"],
  [PARAM.STATE, "state"],
  [PARAM.COUNTRY_CODE, "country-code"],
  [PARAM.ALIAS, "alias"],
]);

enum State {
  none,
  loading,
  processing
}
@Component({
  selector: 'app-key-store-search-dialog',
  templateUrl: './key-store-search-dialog.component.html',
  styleUrls: ['./key-store-search-dialog.component.css']
})
export class KeyStoreSearchDialogComponent implements OnInit, OnDestroy {
  @ViewChild("pg")
  public pg: PagenationComponent;
  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;

  public State = State;
  public PARAM = PARAM;
  public state: State = State.none;
  public keystores: KeyStoreList;

  private selectedKeyStore = new Map<string, KeyStore>();
  private authSubscription: Subscription = null;

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService) {

    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`close....`)
    this.cancel();
  }

  private load() {
    this.state = State.loading;
    this.auth.getKeyStoreSearchQueryData().then(data => {
      log(`keystore-search query data ---> `, data);
      this.searchItems = data;
      this.selectSearchField();
      this.search();
    });
  }

  public setSelectedKeyStore(ks: KeyStore): void {
    if (!ks) {
      return;
    }

    this.selectedKeyStore.clear();
    this.selectedKeyStore.set(ks.name, ks);
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set("page", page)
    this.searchItems.set("r", "user")
    this.auth.searchKeyStore(this.searchItems).then(keystores => {
      log(`keystore-search result ---> `, keystores);
      this.keystores = keystores;
      this.pg.update(keystores.page.pageIndex, keystores.page.itemsPerPage, keystores.page.totalItems);
      this.state = State.none;
    }).catch(e => {
      if (e == AuthError.UNAUTHORIZED) {
        log(`user-search close ........`)
        this.activeModal.close();
      }
    });
  }

  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }

  public updateSearch(value: string): void {
    this.searchValue = value;
    this.set(this.searchField);
  }

  public selectSearchField() {
    let found = false;

    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && this.searchItems.has(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  public isSelected(ks: KeyStore): boolean {
    return this.selectedKeyStore.has(ks.name);
  }

  public select(ks: KeyStore, select: boolean): void {
    this.selectedKeyStore.clear();
    if (select) {
      this.selectedKeyStore.set(ks.name, ks);
    }
  }

  public toggleSelection(ks: KeyStore): void {
    if (this.selectedKeyStore.has(ks.name) == false) {
      this.selectedKeyStore.clear();
      this.selectedKeyStore.set(ks.name, ks);
    } else {
      this.selectedKeyStore.clear();
    }
  }

  public close(): void {
    let keystores: Array<KeyStore> = Array.from(this.selectedKeyStore.values());
    this.activeModal.close(keystores);
  }

  public cancel(): void {
    this.activeModal.close(null);
  }

  ngOnInit() {
  }

}
