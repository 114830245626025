<div class="card mb-4">
    <div class="card-body p-2">

        <div class="row d-flex align-items-center">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>
                    {{'task.creator' | translate}}
                </small>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
                <button class="btn btn-outline-secondary btn-sm pt-0 pb-0" placement="right"
                    #profilePopover="ngbPopover" triggers="hover" [ngbPopover]="userProfile.template" container="body">
                    <small class="h-100 d-flex align-items-center">
                        <i class="fa fa-user-circle fa-1x mr-1"></i>
                        {{task?.creator?.name}}
                    </small>
                    <user-profile-popover #userProfile [user]="task.creator" [showLogout]="false">
                    </user-profile-popover>
                </button>
            </div>
            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>
                    {{'task.state' | translate}}
                </small>
            </div>
            <div class="col-sm-3">
                <small>
                    {{'task-state.'+task.state | translate}}
                </small>

            </div>
        </div>


        <div class="row d-flex align-items-center">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.create-time' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small>{{task?.createTime | date:'yyyy-MM-dd HH:mm:ss'}}</small>
            </div>

            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>{{'task.lead-time' | translate}}</small>
            </div>
            <div class="col-sm-3 h-100 d-flex align-items-center">
                <div [hidden]="task?.isRunning">
                    <small class="h-100 d-flex align-items-center">
                        {{task?.leadTimeStr}}
                    </small>
                </div>
                <button class="btn btn-dark btn-sm pt-0 pb-0" [hidden]="!task?.isRunning">
                    <small class="h-100 d-flex align-items-center text-warning">
                        <strong>
                            {{task?.leadTimeStr}}
                        </strong>
                    </small>
                </button>
                <i class="fa fa-refresh fast-spin fa-1x fa-fw mr-1" [hidden]="!task?.isRunning"></i>
            </div>
        </div>



        <div class="row" *ngIf="!task.isRunning">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.complete-time' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small>{{task?.completeTime | date:'yyyy-MM-dd HH:mm:ss'}}</small>
            </div>
            <div class="col-sm-1 pl-0 pr-0 text-right">
                <small>{{'task.sign' | translate}}</small>
            </div>
            <div class="col-sm-3">
                <small [hidden]="task?.signed" class="text-danger">
                    <strong>{{'task.unsigned' | translate}}</strong>
                </small>
                <small [hidden]="!task?.signed">{{'task.signed' | translate}}</small>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{"common.setting"|translate}}</small>
            </div>
            <div class="col-sm-10">
                <ul ngbNav #platformConfigTab="ngbNav" [activeId]="0" class="nav-pills mt-1 mb-2">
                    <li [ngbNavItem]="0">
                        <small>
                            <a ngbNavLink class="pt-0 pb-0">
                                <i class="fa fa-shield fa-1x"></i>&nbsp;
                                {{"config.security-option-config"|translate}}
                            </a>
                        </small>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-sm-2 pl-0 pr-0 text-right">
                                    <small>{{'task.option' | translate}}</small>
                                </div>
                                <div class="col-sm-10 h-100 align-items-center pb-2">
                                    <task-option-badge-view [task]="task"></task-option-badge-view>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li *ngFor="let config of task?.configs; let j = index" [ngbNavItem]="j+1">
                        <small>
                            <a ngbNavLink class="pt-0 pb-0">
                                <i class="fa fa-window-restore fa-1x"></i>&nbsp;
                                {{"config." + config.get(TEMPLATE.NAME)|translate}}
                            </a>
                        </small>
                        <ng-template ngbNavContent>
                            <task-history-config-view [template]="config" [readOnly]="true"></task-history-config-view>
                        </ng-template>
                    </li>
                </ul>
                <hr class="m-0">
            </div>
        </div>
        <div [ngbNavOutlet]="platformConfigTab" class="mt-1">
        </div>

        <div class="row" [hidden]="task.encrypt.string != true || isEmptyString(task.encrypt.stringFilter)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.string-filter' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small>{{task.encrypt.stringFilter}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task.obfuscate.isNotUse || isEmptyString(task.obfuscate.targetPackage)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.obfuscation-target-package' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small class="multi-line-text">{{task.obfuscate.targetPackage}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task.obfuscate.isNotUse || isEmptyString(task.obfuscate.filter)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.obfuscation-filter' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small class="multi-line-text">{{task.obfuscate.filter}}</small>
            </div>
        </div>

        <div class="row" [hidden]="task.prevent.controlUsageTime != true || isEmptyString(task.prevent.usageTime)">
            <div class="col-sm-2 pl-0 pr-0 text-right">
                <small>{{'task.usage-time' | translate}}</small>
            </div>
            <div class="col-sm-10">
                <small>{{appUsageStartTime}} ~ {{appUsageEndTime}}</small>
                <small [hidden]="!task.prevent.usageTimeZone">&nbsp;({{task.prevent.usageTimeZone}})</small>
            </div>
        </div>

        <div [hidden]="task.isRunning">
            <hr class="mt-2 mb-2">
            <div class="row">
                <div class="col-sm-10 offset-1">
                    <button class="btn btn-outline-primary btn-sm mr-2" (click)="onDownloadTaskResult()"
                        [hidden]="!task?.isComplete">
                        {{"task-view.button.download" | translate}}
                    </button>
                    <button class="btn btn-outline-secondary btn-sm mr-2" [class.active]="info==ActiveInfo.log"
                        (click)="toggleInfo(ActiveInfo.log)">
                        {{"task-view.button.view-log" | translate}}
                    </button>
                    <button class="btn btn-outline-secondary btn-sm mr-2" [hidden]="task.obfuscationMap.size == 0"
                        [class.active]="info==ActiveInfo.map" (click)="toggleInfo(ActiveInfo.map)">
                        {{"task-view.button.view-map" | translate}}
                    </button>
                </div>

            </div>
        </div>

        <div class="mt-2" [hidden]="info!=ActiveInfo.log">
            <textarea #taskLogView rows="12" class="form-control" [value]="task?.log"></textarea>
        </div>

        <div class="mt-2" [hidden]="info!=ActiveInfo.map">
            <div class="row">
                <div class="col-sm">
                    <table class="table table-sm table-hover table-rounded table-fixed mb-1">
                        <thead class="thead thead-light">
                            <tr class="row m-0" scope="row">
                                <th class="col-sm-6 p-0 pl-3 pr-3">
                                    <span>{{'task-view.id' | translate}}</span>
                                </th>
                                <th class="col-sm-6 p-0">
                                    <span>{{'task-view.translated-id' | translate}}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let key of currentObfuscationItems" class="row m-0" scope="row">
                                <td class="col-sm-6">
                                    <span class="pl-3">{{key}}</span>
                                </td>
                                <td class="col-sm-6">
                                    <span>{{task.obfuscationMap.get(key)}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm offset-1">
                    <pagenation #pn (pageClick)="loadObfuscationMapPage($event)" small=true></pagenation>
                </div>
            </div>
            <div class="row h-100 d-flex align-items-center mb-3">
                <div class="col-sm-1 text-right text-primary pr-0">
                    <span>{{'task-view.filter' | translate}}</span>
                </div>
                <div class="col-sm-6 d-flex">
                    <input #obfuscationFilter type="text" class="form-control mr-2"
                        (keyup.enter)="filterObfucationMap($event.target.value)">
                    <button type="button" class="btn btn-primary text-nowrap mr-2"
                        (click)="filterObfucationMap(obfuscationFilter.value)">
                        {{"task-view.button.filter" | translate}}
                    </button>
                    <button type="button" class="btn btn-primary text-nowrap" (click)="onDownloadObfuscationMap()">
                        {{"task-view.button.download-obfuscation-map" | translate}}
                    </button>
                </div>
                <div class="col-sm-1">
                    <span>{{filteredItems?.length}}{{'task-view.count'|translate}}</span>
                </div>
            </div>


        </div>

    </div>
</div>