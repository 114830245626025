import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, User, Project, KeyStore, ProjectOption, UserList, REL, TEMPLATE, PLATFORM, ProjectOptionList } from '../../service/auth.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Template, TemplateList } from '../../service/cj';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

let log = Logger('ProjectDetailView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-project-detail-view',
  templateUrl: './project-detail-view.component.html',
  styleUrls: ['./project-detail-view.component.css']
})
export class ProjectDetailViewComponent implements OnInit,  OnDestroy {
  public State = State;
  public PLATFORM = PLATFORM;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public project: Project = null;
  public keyStore: KeyStore = null;
  public managers: UserList = null;

  public options: TemplateList = null;
  private configs: TemplateList = null;
  private platformConfigMap: Map<string, Array<Template>> = null;
  private itemUri: string;
  private authSubscription: Subscription = null;
  public startTime: any = null;
  public endTime: any = null;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private common: CommonService,
    private translate: TranslateService) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getProject(this.itemUri).then(project => {
      this.project = project;

      log(`project ---> `, project);
      // this.allowAndroidApp = (project.platform == PLATFORM.ANDROID) || (project.platform == '');
      // this.allowIosApp = (project.platform == PLATFORM.IOS) || (project.platform == '');

      log(`loading keystore, manager, option...`);
      this.common.anyway(this.auth.getKeyStore(this.project.getLink(REL.KEYSTORE))).then(keyStore => {
        log(`keystore --->`, keyStore);
        this.keyStore = keyStore;
        let link = this.project.getLink(REL.MANAGER);
        log(`project manager link --->`, link);
        return this.common.anyway(this.auth.getAllUserListFromUri(link));

      }).then(managers => {
        if (managers) {
          this.managers = managers;
        }
        log(`managers --->`, this.managers);

        let link = this.project.getLink(REL.OPTION);
        log(`project option link --->`, link);
        return this.common.anyway(this.auth.getProjectOptionList(link));

      }).then(options => {
        this.options = options;
        log(`options --->`, this.options);
        let link = this.project.getLink(REL.CONFIG);
        log(`project config link --->`, link);
        return this.common.anyway(this.auth.getConfigList(link));

      }).then(configs => {
        log(`configs --->`, configs);
        this.configs = configs;
        this.platformConfigMap = this.auth.buildConfigMap(this.configs);
        this.state = State.none;
      });
    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  update() {
    this.router.navigate([environment.PATH.PROJECT_LIST, this.itemUri, "update"]);
  }

  viewAppList() {
    this.router.navigate([environment.PATH.MY_PROJECT_LIST, this.project.href]);
  }

  delete() {
    this.state = State.processing;
    this.auth.getProjectStatistics(this.project).then(stats => {
      this.state = State.none;
      //must handle that there is not app and task

      let deleteProject = () => {
        this.state = State.processing;
        this.auth.deleteProject(this.itemUri).then(result => {
          log(`delete > delete result ---> ${result}`);
          this.state = State.none;
          this.goBack();
        }).catch(code => {
          this.state = State.none;
          log(`delete > delete error --->`, code);
          this.common.showError("common.error", code);
        });
      }

      let msg = (stats.totalApp == 0 && stats.totalTask == 0) ?
        "project-detail.label.simple-delete-question" : this.translate.instant("project-detail.label.delete-question", stats);

      this.common.question("project-detail.label.delete-title", msg).then(confirm => {
        log(`delete > confirm ---> ${confirm}`);
        if (!confirm) {
          return;
        }

        deleteProject();
      });
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  public isPlatformSupported(platform: string): boolean {
    if (!this.project) {
      return false;
    }

    return this.project.platforms.has(platform);
  }

  public getConfigs(platform: string): Array<Template> {
    if (!this.platformConfigMap || !this.platformConfigMap.has(platform)) {
      return new Array<Template>();
    }

    return this.platformConfigMap.get(platform);
  }
 }
