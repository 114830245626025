<div class="row d-flex align-items-center text-secondary">
  <div class="col-sm-9 offset-sm-1">
    <small class="mr-3">
      <i class="fa fa-user-circle fa-1x mr-1"></i>
      {{reply?.userName}}
    </small>
    <small>
      {{reply?.createTime | date:'yyyy-MM-dd HH:mm'}}
    </small>
  </div>
  <div class="col-sm-1 text-right">
    <button class="btn btn-outline-secondary btn-sm pt-0 pb-0" (click)="onDelete()">
        {{'common.delete' | translate}}
    </button>

  </div>
</div>
<div class="row text-dark">
  <div class="col-sm-10 offset-sm-1">
    <div class="card border-0 mb-4">
      <div class="card-body p-0">
        <span class="multi-line-text">{{reply?.content}}</span>
      </div>
    </div>
  </div>
</div>