import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, UserList, AuthError, User, PARAM, USER_ROLE } from '../../service/auth.service';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';
import { Subscription } from 'rxjs';
import { Logger } from '../../../environments/environment';

let log = Logger('UserSearchDialog');

const SEARCH_FIELDS = new Map<string, string>([
  [PARAM.ID, "id"],
  [PARAM.NAME, "name"],
  [PARAM.DEPARTMENT, "department"],
  [PARAM.EMAIL, "email"],
  [PARAM.TEL, "tel"]
]);

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-user-search-dialog',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.css']
})
export class UserSearchDialogComponent implements OnInit, OnDestroy {
  @ViewChild("pg")
  public pg: PagenationComponent;
  public searchItems: Map<string, any> = null;
  public searchField: string;
  public searchValue: string;

  public State = State;
  public PARAM = PARAM;
  public state: State = State.none;
  public users: UserList;

  private selectedUsers = new Map<string, User>();
  private authSubscription: Subscription = null;

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService) {

    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`close....`)
    this.cancel();
  }

  private load() {
    this.state = State.loading;
    this.auth.getUserSearchQueryData().then(data => {
      log(`user-search query data ---> `, data);
      this.searchItems = data;
      this.selectSearchField();
      this.search();
    });
  }

  public setSelectedUser(users: Array<User>): void {
    if (!users) {
      return;
    }

    users.forEach(user => this.selectedUsers.set(user.id, user));
  }

  public search(page: number = 0) {
    this.state = State.loading;
    this.searchItems.set(PARAM.PAGE, page)
    this.searchItems.set(PARAM.ROLE, USER_ROLE.USER)
    this.auth.searchUser(this.searchItems).then(users => {
      log(`user-search result ---> `, users);
      this.users = users;
      this.pg.update(users.page.pageIndex, users.page.itemsPerPage, users.page.totalItems);
      this.state = State.none;
    }).catch(e => {
      if (e == AuthError.UNAUTHORIZED) {
        log(`user-search close ........`)
        this.activeModal.close();
      }
    });
  }

  public has(f: string): boolean {
    if (!this.searchItems) {
      return false;
    }

    return this.searchItems.has(f);
  }

  public set(f: string): void {
    SEARCH_FIELDS.forEach((v, k) => this.searchItems.set(k, null));
    this.searchField = f;
    this.searchItems.set(f, this.searchValue);
  }

  public get selectedFieldName(): string {
    return SEARCH_FIELDS.get(this.searchField);
  }

  public updateSearch(value: string): void {
    this.searchValue = value;
    this.set(this.searchField);
  }

  public selectSearchField() {
    let found = false;

    log(`find first matching field in ---->`, this.searchItems);
    log(`SEARCH_FIELDS ---->`, SEARCH_FIELDS);
    SEARCH_FIELDS.forEach((v, k) => {
      if (!found && this.searchItems.has(k)) {
        this.set(k);
        found = true;
        log(`selected search field ---> ${k}`);
      }
    });
  }

  public isSelectedUser(user: User): boolean {
    return this.selectedUsers.has(user.id);
  }

  public selectUser(user: User, select: boolean): void {
    if (select) {
      this.selectedUsers.set(user.id, user);
    } else {
      this.selectedUsers.delete(user.id);
    }
  }

  public toggleSelection(user: User): void {
    if (this.selectedUsers.has(user.id)) {
      this.selectedUsers.delete(user.id);
    } else {
      this.selectedUsers.set(user.id, user);
    }
  }

  public close(): void {
    let users: Array<User> = Array.from(this.selectedUsers.values());
    this.activeModal.close(users);
  }

  public cancel(): void {
    this.activeModal.close(null);
  }

  ngOnInit() {
  }

}
