import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, User, TEMPLATE, USER_ROLE } from '../../service/auth.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../environments/environment';
import { UserPasswordResetDialogComponent } from '../user-password-reset-dialog/user-password-reset-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

let log = Logger('UserDetailView');

enum State {
  none,
  loading,
  processing,
  resetting
}

@Component({
  selector: 'app-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.css']
})
export class UserDetailViewComponent implements OnInit, OnDestroy {
  public State = State;
  public USER_ROLE = USER_ROLE;
  public state: State = State.none;
  public user: User = null;
  private itemUri: string;
  private authSubscription: Subscription = null;

  constructor(
    route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private common: CommonService,
    private modal: NgbModal) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getUser(this.itemUri).then(user => {
      this.user = user;
      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  get isResetting(): boolean {
    return this.state == State.resetting;
  }

  update() {
    this.router.navigate([environment.PATH.USER_LIST, this.itemUri, "update"]);
  }

  delete() {
    this.common.question("user-detail.label.delete-title", "user-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.auth.deleteUser(this.itemUri).then(_ => {
        this.state = State.none;
        this.goBack();
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });
  }

  resetPassword() {
    this.state = State.resetting;
    this.auth.getUserPasswordResetTemplate(this.user).then(template => {
      log(`resetPassword > password reset template --->`, template);
      const modal = this.modal.open(UserPasswordResetDialogComponent, { centered: true });
      modal.result.then(tempPassword => {
        log(`resetPassword > temporary password --->`, tempPassword);

        template.set(TEMPLATE.PASSWORD, tempPassword);
        this.auth.resetUserPassword(this.user, template).then(_ => {
          this.state = State.none;
          this.common.showMessage("check-circle-o", "common.ok", "common.inform", "user-detail.label.password-reset-complete");

        }).catch(code => {
          log(`resetPassword > error code --->`, code);
          this.state = State.none;
          this.common.showError("common.error", code);
        });
    
      }).catch(e => {
        this.state = State.none;
        log(`resetPassword > user canceled....`);
      });

    }).catch(code => {
      log(`resetPassword > error code --->`, code);
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  public canDelete(): boolean {
    if (environment.canDeleteAdminAccount === true) {
      return true;
    }

    return (this.user && this.user.role != USER_ROLE.ADMIN);
  }

}
