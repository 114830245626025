import { Component } from '@angular/core';
import { Logger } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { TaskConfigViewComponent } from '../../task/task-config-view/task-config-view.component';

let log = Logger("ProjectConfigView");

@Component({
  selector: 'project-config-view',
  templateUrl: './project-config-view.component.html',
  styleUrls: ['./project-config-view.component.css']
})
export class ProjectConfigViewComponent extends TaskConfigViewComponent {
  constructor(protected common: CommonService) {
    super(common);
  }
}
