<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_TITLE)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.alert-title' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_TITLE)"
            (change)="set(TEMPLATE.CONFIG_ALERT_TITLE, $event.target.value)" [readonly]="readOnly">
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.alert-exit-button' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)"
            (change)="set(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON, $event.target.value)" [readonly]="readOnly">
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.alert-restart-button' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <input type="text" maxlength="100" class="form-control" [value]="get(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)"
            (change)="set(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON, $event.target.value)" [readonly]="readOnly">
    </div>
</div>


<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-compromised' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_COMPROMISED, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-unsafe-env' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control"
            [value]="get(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-frida-detected' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-not-permitted-time' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control"
            [value]="get(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_INSTALLING)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-installing' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_INSTALLING)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_INSTALLING, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_RESTART)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-restart' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_RESTART)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_RESTART, $event.target.value)" [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.msg-manual-restart' | translate}}
        </span>
    </div>
    <div class="col-sm-8">
        <textarea rows="2" maxlength="200" class="form-control" [value]="get(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)"
            (change)="set(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART, $event.target.value)"
            [readonly]="readOnly"></textarea>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_USE, TEMPLATE.CONFIG_ALERT_SHOW)">
    <div class="col-sm-2 text-right mt-2">
    </div>
    <div class="col-sm-2" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_SHOW)">
        <div class="media abc-checkbox abc-checkbox-primary pl-2">
            <input id="config_alert_show" class="media-left" type="checkbox" [checked]="get(TEMPLATE.CONFIG_ALERT_SHOW)"
                (click)="clickOption(TEMPLATE.CONFIG_ALERT_SHOW, $event)">
            <label for="config_alert_show" class="media-body form-check-label">
                {{'config.alert-show' | translate}}
            </label>
        </div>
    </div>
    <div class="col-sm-2" [hidden]="!existAny(TEMPLATE.CONFIG_USE)">
        <div class="media abc-checkbox abc-checkbox-primary pl-2">
            <input id="config_use" class="media-left" type="checkbox" [checked]="get(TEMPLATE.CONFIG_USE)"
                (click)="clickOption(TEMPLATE.CONFIG_USE, $event)">
            <label for="config_use" class="media-body form-check-label">
                {{'config.use' | translate}}
            </label>
        </div>
    </div>
</div>

<div class="row mb-2" [hidden]="!existAny(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">
    <div class="col-sm-2 text-right mt-2">
        <span class="form-check-label">
            {{'config.bg-image' | translate}}
        </span>
    </div>
    <div class="col-sm-5">
        <ul class="list-group col-sm p-0">
            <li class="list-group-item p-2 pl-3 pr-3 d-flex justify-content-between align-items-center">
                <img [src]="getImageSource(TEMPLATE.CONFIG_BACKGROUND_IMAGE)" width="100%"
                    [hidden]="!isImageExist(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">
                <span [hidden]="isImageExist(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">{{"common.none" | translate}}</span>
            </li>
        </ul>
    </div>
    <div class="col-sm-2 pl-0 pt-2" [hidden]="readOnly">
        <input type="file" id="bg-image-file" class="custom-file-input" (change)="onFileChange(TEMPLATE.CONFIG_BACKGROUND_IMAGE, $event)" [hidden]="true">
        <label class="btn btn-primary" for="bg-image-file">
            {{'config.change-image' | translate}}
        </label>
    </div>
</div>