<div class="modal-header">
  <h4 class="modal-title">{{'change-password.title' | translate}}</h4>
  <span class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">&times;
  </span>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="password" [hidden]="reason != PasswordChangeReason.unknown">{{'change-password.reason-unknown' | translate}}</label>
    <label for="password" [hidden]="reason != PasswordChangeReason.expired">{{'change-password.reason-password-expired' | translate}}</label>
    <input type="password" class="form-control" id="password" [value]="password" (input)="password=$event.target.value"
      [disabled]="!changeEnabled" focus="true" (keyup.enter)="checkInputLengthThenClose()">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">{{'change-password.button.ok' | translate}}</button>
</div>