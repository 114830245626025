export const PATH = {
    USER_LIST: "users",
    KEYSTORE_LIST: "keystores",
    QUESTION_LIST: "questions",
    QUESTION: "question",
    PROJECT: "project",
    PROJECT_LIST: "projects",
    MY_PROJECT_LIST: "my-projects",
    MOBILE_APP_LIST: "mobile-apps",
    RUNNING_TASK_LIST: "running-tasks",
    TASK_LIST: "tasks",
    FIND_TASK: "find",
  }
  