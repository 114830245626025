import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './service/auth.service';
import { UserListViewComponent } from './user/user-list-view/user-list-view.component';
import { UserDetailViewComponent } from './user/user-detail-view/user-detail-view.component';
import { UserUpdateViewComponent } from './user/user-update-view/user-update-view.component';
import { UserCreateViewComponent } from './user/user-create-view/user-create-view.component';
import { KeyStoreListViewComponent } from './keystore/key-store-list-view/key-store-list-view.component';
import { KeyStoreDetailViewComponent } from './keystore/key-store-detail-view/key-store-detail-view.component';
import { KeyStoreCreateViewComponent } from './keystore/key-store-create-view/key-store-create-view.component';
import { KeyStoreAddViewComponent } from './keystore/key-store-add-view/key-store-add-view.component';
import { ProjectListViewComponent } from './project/project-list-view/project-list-view.component';
import { ProjectDetailViewComponent } from './project/project-detail-view/project-detail-view.component';
import { ProjectCreateViewComponent } from './project/project-create-view/project-create-view.component';
import { ProjectUpdateViewComponent } from './project/project-update-view/project-update-view.component';
import { MyProjectListViewComponent } from './project/my-project-list-view/my-project-list-view.component';
import { MobileAppListViewComponent } from './mobile-app/mobile-app-list-view/mobile-app-list-view.component';
import { BlankViewComponent } from './common/blank-view/blank-view.component';
import { MobileAppDetailViewComponent } from './mobile-app/mobile-app-detail-view/mobile-app-detail-view.component';
import { QnaListViewComponent } from './qna/qna-list-view/qna-list-view.component';
import { QnaDetailViewComponent } from './qna/qna-detail-view/qna-detail-view.component';
import { PATH } from '../environments/config';
import { RunningTaskListViewComponent } from './task/running-task-list-view/running-task-list-view.component';
import { QnaCreateViewComponent } from './qna/qna-create-view/qna-create-view.component';
import { TaskListViewComponent } from './task/task-list-view/task-list-view.component';
import { environment } from '../environments/environment';
import { TaskFindViewComponent } from './task/task-find-view/task-find-view.component';


let routes = [
  { path: "blank", component: BlankViewComponent },  
  { path: `${PATH.USER_LIST}`, component: UserListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.USER_LIST}/create`, component: UserCreateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.USER_LIST}/:itemUri`, component: UserDetailViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.USER_LIST}/:itemUri/update`, component: UserUpdateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.KEYSTORE_LIST}`, component: KeyStoreListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.KEYSTORE_LIST}/create`, component: KeyStoreCreateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.KEYSTORE_LIST}/add`, component: KeyStoreAddViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.KEYSTORE_LIST}/:itemUri`, component: KeyStoreDetailViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.PROJECT_LIST}/create`, component: ProjectCreateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.PROJECT_LIST}`, component: ProjectListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.PROJECT_LIST}/:itemUri`, component: ProjectDetailViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.PROJECT_LIST}/:itemUri/update`, component: ProjectUpdateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.MY_PROJECT_LIST}`, component: MyProjectListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.MY_PROJECT_LIST}/:itemUri`, component: MobileAppListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.MOBILE_APP_LIST}/:itemUri`, component: MobileAppDetailViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.QUESTION_LIST}`, component: QnaListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.QUESTION_LIST}/:itemUri`, component: QnaDetailViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.QUESTION_LIST}/create/:itemUri`, component: QnaCreateViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.RUNNING_TASK_LIST}`, component: RunningTaskListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.TASK_LIST}`, component: TaskListViewComponent, canActivate: [AuthGuard] },
  { path: `${PATH.FIND_TASK}`, component: TaskFindViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
  ]
})
export class RoutesModule { }
