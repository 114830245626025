import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, User, Qna, QnaList, REL, TEMPLATE } from '../../service/auth.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../environments/environment';
import { Template } from '../../service/cj';

let log = Logger('QnaDetailView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-qna-detail-view',
  templateUrl: './qna-detail-view.component.html',
  styleUrls: ['./qna-detail-view.component.css']
})
export class QnaDetailViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public qna: Qna = null;
  public replies: QnaList = null;
  private itemUri: string;
  private replyPageNo: number = 0;
  public replyTemplate: Template = null;
  private authSubscription: Subscription = null;
  public showReplyInput: boolean = false;
  @ViewChild("replyInput")
  public replyInput: ElementRef;
  
  public replyContent: string;



  constructor(
    route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private common: CommonService) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`item uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getQna(this.itemUri).then(qna => {
      this.qna = qna;
      let link = qna.getLink(REL.REPLY);
      log(`load > load replies link ---> `, link);
      this.common.anyway(this.auth.getReplyList(link, this.replyPageNo)).then(replies => {
        log(`load > replies ---> `, replies);
        this.replies = replies;
        let link = replies.getLink(REL.TEMPLATE);
        log(`load > reply template link ---> `, link);
        return this.common.anyway(this.auth.getQnaCreateTemplate(link));
      }).then(template => {
        log(`load > reply template ---> `, template);
        this.replyTemplate = template;
        this.replyContent = this.get(TEMPLATE.CONTENT);
        this.state = State.none;
      });

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  private loadReplyList(page: number = 0) {
    this.replyPageNo = page;
    let link = this.qna.getLink(REL.REPLY);
    log(`loadReplyList > load replies link ---> `, link);
    this.auth.getReplyList(link, this.replyPageNo).then(replies => {
      log(`loadReplyList > replies ---> `, replies);
      this.replies = replies;
    }).catch(code => {
      log(`loadReplyList > error code ---> `, code);
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  toggleReplyInput() {
    this.showReplyInput = !this.showReplyInput;
    if (this.showReplyInput) {
      window.setTimeout(_ => {
        this.replyInput.nativeElement.focus();
        this.replyInput.nativeElement.scrollIntoView(true);
      }, 300);
    }
  }

  get hasReplyInput(): boolean {
    return this.replyContent && this.replyContent.trim().length > 0;
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }

  has(f: string): boolean {
    if (!this.replyTemplate) {
      return false;
    }

    return this.replyTemplate.has(f);
  }

  get(f: string): any {
    if (!this.replyTemplate) {
      return;
    }

    let value = this.replyTemplate.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.replyTemplate) {
      return;
    }

    if (!this.replyTemplate.has(f)) {
      return;
    }

    this.replyTemplate.set(f, value);
  }

  writeReply() {
    this.state = State.processing;

    this.set(TEMPLATE.CONTENT, this.replyContent);
    log(`template --->`, this.replyTemplate);

    let link = this.qna.getLink(REL.REPLY);
    log(`reply write link --->`, link);

    this.auth.createQna(link, this.replyTemplate).then(uri => {
      this.showReplyInput = false;
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "qna-create.create-completed").then(_ => {
        this.load();
      });
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

  /*
    update() {
      this.router.navigate([environment.PATH.QUESTION_LIST, this.itemUri, "update"]);
    }
  */

  delete() {
    this.common.question("qna-detail.label.delete-title", "qna-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.auth.deleteQna(this.itemUri).then(_ => {
        this.state = State.none;
        this.goBack();
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });
  }

  onDeleteReply(reply: Qna) {
    this.common.question("qna-detail.label.delete-title", "qna-detail.label.delete-question").then(confirm => {
      log(`delete > confirm ---> ${confirm}`);
      if (!confirm) {
        return;
      }

      this.state = State.processing;
      this.auth.deleteQna(reply.href).then(_ => {
        this.state = State.none;
        this.loadReplyList();
      }).catch(code => {
        this.state = State.none;
        this.common.showError("common.error", code);
      });
    });
  }
}
