import { Injectable } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent } from '../common/message-box/message-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthError, User, KeyStore } from './auth.service';
import { UserSearchDialogComponent } from '../user/user-search-dialog/user-search-dialog.component';
import { KeyStoreSearchDialogComponent } from '../keystore/key-store-search-dialog/key-store-search-dialog.component';
import { ProcessingStatusViewComponent } from '../common/processing-status-view/processing-status-view.component';
import { Logger, environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { tz } from 'moment-timezone';


let log = Logger('CommonService');

export class DateValue {
  year: number;
  month: number;
  day: number;

  public static readDateValue(s: string): DateValue {
    let r;

    try {
      let d = new Date(s);
      r = new DateValue();
      r.year = d.getUTCFullYear();
      r.month = d.getUTCMonth() + 1;
      r.day = d.getUTCDate();
    } catch (e) {
    }

    return r;
  }
}

export class TimeValue {
  public hour: number;
  public minute: number;

  private constructor(hour: number, minute: number) {
    this.hour = hour;
    this.minute = minute;
  }

  private pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public get format(): string {
    return this.pad(this.hour, 2) + this.pad(this.minute, 2);
  }

  public static create(hour: number, minute: number): TimeValue {
    if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      return null;
    }

    return new TimeValue(hour, minute);
  }
}

export class TimeZoneItem {
  private _id: string;
  private _offset: number;

  public get id(): string {
    return this._id;
  }

  public get offset(): number {
    return this._offset;
  }

  public get displayName(): string {
    return `${this.id} ${this.formattedOffset}`;
  }

  public get formattedOffset(): string {
    return TimeZoneItem.offsetAsString(this.offset);
  }

  private constructor(zone: string, offset: number) {
    this._id = zone;
    this._offset = offset;
  }

  public static create(zone: string): TimeZoneItem {
    let t = tz(zone);
    if (!t) {
      return null;
    }

    let item = new TimeZoneItem(zone, t.utcOffset());
    return item;
  }

  private static offsetAsString(offset: number): string {
    const neg = offset < 0;
    if (neg) {
      offset = -1 * offset;
    }
    const hours = Math.floor(offset / 60);
    const minutes = (offset / 60 - hours) * 60;
    return `${neg ? '-' : '+'}${TimeZoneItem.rightJustify(hours.toString(), 2)}:${TimeZoneItem.rightJustify(
      minutes.toString(),
      2
    )}`;
  }

  private static rightJustify(value: string, width: number, padding = '0'): string {
    padding = padding || ' ';
    padding = padding.substr(0, 1);
    if (value.length < width) {
      return padding.repeat(width - value.length) + value;
    } else {
      return value;
    }
  }
}


@Injectable()
export class CommonService {
  static statusView: NgbModalRef = null;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService) {
  }

  public showMessage(icon: string, ok: string, title: string, ...contents: string[]): Promise<boolean> {
    const modal = this.modalService.open(MessageBoxComponent, { centered: true });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = contents.map(msg => this.translate.instant(msg)).join("\n");
    c.icon = icon;
    c.ok = this.translate.instant(ok);

    return modal.result.catch(e => false);
  }

  public showError(title: string, e: AuthError): Promise<boolean> {
    const modal = this.modalService.open(MessageBoxComponent, { centered: true });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = this.getErrorMsg(e);
    c.icon = "exclamation-triangle";
    c.ok = this.translate.instant("common.ok");

    return modal.result.catch(e => false);
  }


  public showProcessingStatus(message: string) {
    if (CommonService.statusView == null) {
      CommonService.statusView = this.modalService.open(ProcessingStatusViewComponent, { centered: true });
    }

    let c: ProcessingStatusViewComponent = <ProcessingStatusViewComponent>CommonService.statusView.componentInstance;
    c.message = this.translate.instant(message);
  }

  public hideProcessingStatus() {
    if (CommonService.statusView == null) {
      return;
    }

    CommonService.statusView.dismiss();
    CommonService.statusView = null;
  }

  public question(title: string, ...contents: string[]): Promise<boolean> {
    const modal = this.modalService.open(MessageBoxComponent, { centered: true });
    let c: MessageBoxComponent = <MessageBoxComponent>modal.componentInstance;
    c.title = this.translate.instant(title);
    c.contents = contents.map(msg => this.translate.instant(msg)).join("\n");
    c.icon = "fa-question-circle";
    c.ok = this.translate.instant("common.ok");
    c.cancel = this.translate.instant("common.cancel");

    return modal.result.catch(e => false);
  }

  public getErrorMsg(error: AuthError): string {
    let key = `error.${AuthError[error]}`;
    log(`getErrorMsg > key ---> ${key}`)
    return this.translate.instant(key);
  }

  public anyway<T>(p: Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      p.then(result => resolve(result)).catch(e => {
        log(`anyway error ---> `, e)
        resolve(null);
      })
    });
  }



  public selectUser(users: Array<User>): Promise<Array<User>> {
    const modal = this.modalService.open(UserSearchDialogComponent, { centered: true });
    let c: UserSearchDialogComponent = <UserSearchDialogComponent>modal.componentInstance;
    c.setSelectedUser(users);
    return modal.result.catch(e => null);
  }

  public selectKeyStore(keystore: KeyStore): Promise<Array<KeyStore>> {
    const modal = this.modalService.open(KeyStoreSearchDialogComponent, { centered: true });
    let c: KeyStoreSearchDialogComponent = <KeyStoreSearchDialogComponent>modal.componentInstance;
    c.setSelectedKeyStore(keystore);
    return modal.result.catch(e => null);
  }

  public getStartDate(d: DateValue): Date {
    return new Date(Date.UTC(d.year, d.month - 1, d.day, 0, 0, 0))
  }

  public getEndDate(d: DateValue): Date {
    return new Date(Date.UTC(d.year, d.month - 1, d.day, 23, 59, 59, 999))
  }

  public isDateRangeValid(startDate: DateValue, endDate: DateValue): boolean {
    try {
      let s = this.getStartDate(startDate)
      let e = this.getEndDate(endDate)
      return s <= e
    } catch (e) {
      log(`isDateRangeValid > error --->`, e)
      return false;
    }
  }

  public isDateSpecified(d: DateValue): boolean {
    return d && (typeof(d) == "object") && (!isNaN(d.year) || !isNaN(d.month) || !isNaN(d.day));
  }

  public dateValueToDateRange(startDate: DateValue, endDate: DateValue): string {
    log(`start --->`, startDate)
    log(`end --->`, endDate)
    let sd: Date, ed: Date;

    if (startDate) {
      sd = this.getStartDate(startDate)
      if (!(sd instanceof Date && !Number.isNaN(sd.getTime()))) {
        sd = null;
      }
      log(`sd ---> `, sd);
    }

    if (endDate) {
      ed = this.getEndDate(endDate)
      if (!(ed instanceof Date && !Number.isNaN(ed.getTime()))) {
        ed = null;
      }
      log(`ed ---> `, ed);
    }

    let range: string = ""

    if (sd && ed) {
      range = `${sd.toISOString()}~${ed.toISOString()}`;
    } else if (sd && !ed) {
      range = `${sd.toISOString()}~`;
    } else if (!sd && ed) {
      range = `~${ed.toISOString()}`;
    } else {
      range = "";
    }

    log(`range --->`, range)
    return range;
  }


  public isValidFileExtension(s: string): Boolean {
    let re = /(?:\.(\S+))?$/;
    let ext = re.exec(s)[0].toLowerCase();
    for (let i = 0; i < environment.VALID_APP_EXTENSIONS.length; i++) {
      if (ext.endsWith(environment.VALID_APP_EXTENSIONS[i].toLowerCase())) {
        return true
      }
    }
    return false
  }

  public encode(s: string): string {
    var salt = CryptoJS.enc.Hex.parse("d22672ac964b4606ad5440dc63059e5b");
    var iv = CryptoJS.enc.Hex.parse("a4113be55d0b42c484ee2d705df301b6");
    var key128Bits100Iterations = CryptoJS.PBKDF2("89ec4a7eda6b42d9b47b86d24ad1a9eb", salt, {keySize: 128 / 32, iterations: 100});
    var encrypted = CryptoJS.AES.encrypt(s, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString()
  }

  public getTimeZoneList(): Array<TimeZoneItem> {
    return tz.names().map((n, i) => TimeZoneItem.create(n));
  }

  public getCurrentTimeZoneItem(): TimeZoneItem {
    return TimeZoneItem.create(tz.guess());
  }

  public parseTimeValue(t: string): Array<TimeValue> {
    try {
      let startTime = TimeValue.create(parseInt(t.substr(0, 2)), parseInt(t.substr(2, 2)));
      let endTime = TimeValue.create(parseInt(t.substr(4, 2)), parseInt(t.substr(6, 2)));
      return new Array(startTime, endTime);
    } catch (e) {
      return null;
    }
  }

  public isTimeRangeValid(t: string): boolean {
    let list = this.parseTimeValue(t);
    return list && list[0].format < list[1].format;
  }
}


