import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment, Logger } from '../environments/environment';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from './login/change-password/change-password.component'
import { AuthService, AuthError, User, PasswordChangeReason } from './service/auth.service';
import { CommonService } from './service/common.service';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { PATH } from '../environments/config';

let log = Logger('AppComponent');

enum State {
  ready,
  connecting,
  authenticating
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public State = State;
  public state: State = State.connecting;
  public userId: string;
  public password: string;
  public isProcessing: boolean;
  public identity: User = null;
  public name: string;
  public version: string;
  @ViewChild("profilePopover") public profilePopover: NgbPopover;

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private common: CommonService,
    private titleService: Title,
    private router: Router,
    private modal: NgbModal) {

    this.setLanguage();
    this.setTitle();
    this.registerAuthStatusObserver();
    this.resetAccountInfo();

    this.showSplashAndCheckAuthentication();
  }

  private setTitle() {
    this.translate.get(["app.name", "app.version"]).subscribe((values) => {
      log(`setTitle > translated values --->`, values)
      this.name = values["app.name"];
      this.version = values["app.version"];
      let title = this.name;
      if (this.version != "") {
        title += `v${this.version}`
      }
      
      this.titleService.setTitle(title);
    });
  }

  private setLanguage() {
    this.translate.setDefaultLang(environment.DEFAULT_LANGUAGE);
    let lang = this.translate.getBrowserLang();
    log(`navigator lang = ${navigator.language}`);
    log(`browser lang = ${lang}`);
    this.translate.use(lang);
  }

  private showSplashAndCheckAuthentication() {
    this.state = State.connecting;

    setTimeout(() => {
      this.auth.checkAuthentication().then(identity => {
        log(`showSplashAndCheckAuthentication > result --->`, identity);
        this.identity = identity;
        log(`current route ---> ${this.router.url}`);

        // this.redirectToHome();
      }).catch(e => {
        log(`showSplashAndCheckAuthentication > check-authentication error --->`, e);
        this.logout();
      });
    }, environment.SPLASH_DELAY * 1000);
  }

  private redirectToHome() {
    let home = [environment.ADMIN_INITIAL_VIEW_PATH];
    if (this.identity.role == "user") {
      home = [environment.USER_INITIAL_VIEW_PATH];
    }

    log(`redirectToHome ---> ${home}`);
    this.router.navigate(home);
  }

  private registerAuthStatusObserver() {
    AuthService.state.subscribe(authenticated => {
      if (this.profilePopover.isOpen()) {
        this.profilePopover.close();
      }

      log(`registerAuthStatusObserver > auth status changed. authenticated? ---> ${authenticated}`);
      if (authenticated) {
        log(`registerAuthStatusObserver > auth ready`);
        this.state = State.ready;
        if (this.router.url == "" || this.router.url == "/") {
          this.router.navigate([environment.ADMIN_INITIAL_VIEW_PATH]);
        }
        return;
      } else {
        log(`registerAuthStatusObserver > navigate to blank`);
        this.router.navigate(["blank"]);
      }

      this.state = State.authenticating;
    });
  }

  public isUserLoggedIn(): boolean {
    return this.auth.hasToken();
  }

  public logout() {
    log(`logout > call logout api...`)
    this.auth.logout().then(r => {
      log(`logout > make unauthenticated state...`);
      this.resetAccountInfo();
      this.auth.unauthenticate();
    })
  }

  private resetAccountInfo() {
    this.userId = "";
    this.password = "";
  }

  login(e) {
    this.isProcessing = true;
    e.preventDefault();

    let id = this.common.encode(this.userId);
    let pwd = this.common.encode(this.password);
    this.resetAccountInfo();
    log("id ---> ", id);
    this.auth.authenticate(
      id, pwd,
      (reason) => this.changePassword(reason)).then(identity => {
        log(`login > current status ---> ${this.state}`);
        this.identity = identity;

        if (identity == null) {
          this.common.showMessage("check-circle-o", "common.ok", "common.inform", "error.LOGIN_USING_CHANGED_PASSWORD")
          return;
        }

        this.redirectToHome();

      }).catch(e => {
        log(`login > error ---> `, e);
        if (e != AuthError.USER_CANCELED) {
          this.common.showError("common.error", e);
        }
      }).then(_ => {
        log(`login > isProcessing = false`)
        this.isProcessing = false;
      });
  }

  changePassword(reason: PasswordChangeReason): Promise<string> {
    let p = new Promise<string>((resolve, reject) => {
      const modal = this.modal.open(ChangePasswordComponent, { centered: true });
      modal.componentInstance.reason = reason;
      modal.result.then(result => {
        resolve(this.common.encode(result));
      }).catch(e => reject(e));
    });

    return p;
  }
}
