import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-password-reset-dialog',
  templateUrl: './user-password-reset-dialog.component.html',
  styleUrls: ['./user-password-reset-dialog.component.css']
})
export class UserPasswordResetDialogComponent implements OnInit {
  public changeEnabled: boolean;
  public password: string;

  constructor(public activeModal: NgbActiveModal) {
    this.changeEnabled = true;
    this.password = "";
  }

  ngOnInit() {
  }

  checkInputLengthThenClose() {
    if (this.password.trim().length == 0) {
      return;
    }

    this.close();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close(this.password.trim());
  }
}
