import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Task, ElapsedTime, REL, AuthService, AuthError, OPTIONAL_BOOLEAN, TEMPLATE } from '../../service/auth.service';
import { Logger } from '../../../environments/environment';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';


let log = Logger("TaskView");

enum ActiveInfo {
  none,
  log,
  map
}

@Component({
  selector: 'task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.css']
})
export class TaskViewComponent implements OnInit, OnDestroy, AfterViewInit {
  public OPTIONAL_BOOLEAN = OPTIONAL_BOOLEAN
  public TEMPLATE = TEMPLATE
  
  @Input()
  public task: Task
  @Output()
  public downloadTaskResult = new EventEmitter<Task>();
  @Output()
  public downloadObfuscationMap = new EventEmitter<Task>();
  @ViewChild("pn")
  public pn: PagenationComponent;
  @ViewChild("obfuscationFilter")
  public obfuscationFilterInput: ElementRef;
  @ViewChild("taskLogView")
  public taskLogView: ElementRef;

  public ActiveInfo = ActiveInfo;
  public info: ActiveInfo = ActiveInfo.none;
  private elapseTimeUpdateTimer: number;
  private taskLogUpdateTimer: number;
  public leadTime: ElapsedTime = null;
  public filteredItems: Array<string> = null;
  private itemsPerPage: number = 10;
  public obfuscationMapPage: number = 0;
  public currentObfuscationItems: Array<string>;

  constructor(private auth: AuthService) {
    this.currentObfuscationItems = new Array<string>();
    this.filteredItems = new Array<string>();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    log('onInit > task set ---> ', this.task);
    log('onInit > task obfuscationmap size ---> ', this.task.obfuscationMap.size);
    this.filterObfucationMap("");
    this.leadTime = this.task.leadTime;
    if (this.task.isRunning) {
      this.elapseTimeUpdateTimer = window.setInterval(() => this.leadTime = this.task.leadTime, 1000);
      this.startTaskLogUpdateTimer();
    }
  }

  ngOnDestroy() {
    log(`ngOnDestroy > cancel timers...`)
    window.clearInterval(this.elapseTimeUpdateTimer);
    window.clearInterval(this.taskLogUpdateTimer);
  }

  private startTaskLogUpdateTimer() {
    const UPDATE_INTERVAL = 5 * 1000;
    const INITIAL_UPDATE_INTERVAL = 1 * 1000;

    this.toggleInfo(ActiveInfo.log);

    let uri = this.task.getLink(REL.LOG)
    log(`startTaskLogUpdateTimer > task log uri --->`, uri)

    let logUpdateHandler = () => {
      let s = 0;
      if (this.task.log) {
        s = this.task.log.length;
      }

      let range = `${s}-`
      log(`startTaskLogUpdateTimer > loading task log (range=${range})`);
      this.auth.getRangedTextData(uri, range).then(data => {
        log(`startTaskLogUpdateTimer > task log --->`, data)
        if (data) {
          if (!this.task.log) {
            this.task.log = data.text;
          } else {
            this.task.log += data.text;
          }

          this.scrollTaskLogToBottom();
        }
        this.taskLogUpdateTimer = window.setTimeout(logUpdateHandler, UPDATE_INTERVAL);
      }).catch(code => {
        log(`startTaskLogUpdateTimer > error while get ranged-text-data --->`, code)
        if (code == AuthError.TASK_ALREADY_DONE) {
          log(`startTaskLogUpdateTimer > task already done!!`);
          this.reloadTask();
          this.toggleInfo(ActiveInfo.none);
        }
      });

    }

    this.taskLogUpdateTimer = window.setTimeout(logUpdateHandler, INITIAL_UPDATE_INTERVAL);
  }

  private scrollTaskLogToBottom() {
    try {
      window.setTimeout(() => {
        this.taskLogView.nativeElement.scrollTop = this.taskLogView.nativeElement.scrollHeight;
      }, 200);

    } catch (e) {

    }
  }

  private reloadTask() {
    let uri = this.task.href;
    log(`reloadTask > reload uri ---> `, uri);
    this.auth.getTask(uri).then(task => {
      log(`reloadTask > reloaded task ---> `, task);
      this.task = task;
      this.filterObfucationMap("");
    }).catch(e => {
      log(`reloadTask > error while get task ---> `, e);
    });
  }

  public get appUsageStartTime(): string {
    let t = this.task.prevent.usageTime
    if (!t || t.length < 8) {
      return ""
    }

    let h = t.substr(0, 2)
    let m = t.substr(2, 2)
    return `${h}:${m}`
  }

  public get appUsageEndTime(): string {
    let t = this.task.prevent.usageTime
    if (!t || t.length < 8) {
      return ""
    }

    let h = t.substr(4, 2)
    let m = t.substr(6, 2)
    return `${h}:${m}`
  }

  public onDownloadTaskResult() {
    this.downloadTaskResult.emit(this.task);
  }

  public onDownloadObfuscationMap() {
    this.downloadObfuscationMap.emit(this.task);
  }

  public isEmptyString(s: string): boolean {
    return (!s || s.length == 0)
  }

  public toggleInfo(newInfo: ActiveInfo) {
    if (this.info == newInfo) {
      this.info = ActiveInfo.none;
      return;
    }

    this.info = newInfo;
  }

  public filterObfucationMap(filter: string) {
    log(`filterObfucationMap > start filtering --->`, filter)
    this.filteredItems = new Array<string>();
    this.task.obfuscationMap.forEach((value, key) => {
      if (filter.length == 0 || key.includes(filter) || value.includes(filter)) {
        this.filteredItems.push(key);
      }
    });


    log(`filterObfucationMap > start sorting....`)
    this.filteredItems = this.filteredItems.sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    this.loadObfuscationMapPage(0);

    log(`filterObfucationMap > complete....`)
  }

  public loadObfuscationMapPage(page: number) {
    try {
      this.obfuscationMapPage = page;
      this.currentObfuscationItems = this.readCurrentObfuscationMap();
      this.pn.update(this.obfuscationMapPage, this.itemsPerPage, this.filteredItems.length);
    } catch (e) {
      // log('loadObfuscationMapPage > error ---> ', e)
    }
  }

  private readCurrentObfuscationMap(): Array<string> {
    let from = this.obfuscationMapPage * this.itemsPerPage;
    let to = from + this.itemsPerPage;
    if (to > this.filteredItems.length) {
      to = this.filteredItems.length;
    }

    let result = new Array<string>();
    for (let i = from; i < to; i++) {
      result.push(this.filteredItems[i]);
    }
    return result;
  }

}
