import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, AndroidApp, Task, TaskList, REL } from '../../service/auth.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { PagenationComponent } from '../../common/pagenation/pagenation.component';

let log = Logger('TaskFindView');


enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-task-find-view',
  templateUrl: './task-find-view.component.html',
  styleUrls: ['./task-find-view.component.css']
})
export class TaskFindViewComponent implements OnInit, OnDestroy {
  public State = State;
  public state: State = State.none;
  private uploadLink: string;
  public fileName: string;
  private authSubscription: Subscription = null;
  private paramSubscription: Subscription = null;
  @ViewChild("pg")
  public pg: PagenationComponent;
  private page: number;

  public fileSize: number = 0;
  public uploadPercent: number;
  public uploadPercentMsg: string;
  public fileExist: boolean = false;
  private file: File = null;

  public app: AndroidApp;
  public hashMatchedTask: Task;
  public taskList: TaskList;


  constructor(
    route: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService,
    private router: Router,
    private translate: TranslateService) {

    log(`parameter ---> ${JSON.stringify(route.queryParams, null, 2)}`);
    this.paramSubscription = route.queryParams.subscribe(this.onParamChanged.bind(this));
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private onParamChanged(params: any) {
    this.page = parseInt(params["page"] || 0);
    log(`page parameter ---> ${JSON.stringify(this.page, null, 2)}`);
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getTaskFindLink().then(uploadLink => {
      log(`load > uploadLink ---> ${uploadLink}`);
      this.uploadLink = uploadLink;
      this.state = State.none;

    }).catch(e => {
      log(`load > loading template error ---> ${e}`);
      this.common.showError("common.error", e);
    });
  }

  ngOnInit() {
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.fileSize = this.file.size;
      this.fileExist = true;
    }
  }

  private updateProgress(current: number, total: number) {
    this.fileSize = total;
    if (total == 0 || current == 0) {
      this.uploadPercent = 0.0;
    } else {
      this.uploadPercent = (current / total) * 100;
    }
    this.uploadPercentMsg = `${this.uploadPercent.toFixed(0)} %`
  }


  upload(e) {
    log(`upload > upload link ---> ${this.uploadLink}`);
    if (!this.common.isValidFileExtension(this.file.name)) {
      e.preventDefault();
      this.common.showMessage("exclamation-triangle", "common.ok", "common.ok", "common.message.unsupported-app")
      return;
    }

    this.state = State.processing;
    this.auth.extractMobileAppInfo(this.uploadLink, this.file, (current, total) => {
      log(`upload > upload progress ---> ${current} / ${total}`);
      this.updateProgress(current, total);

    }).then(app => {
      this.clearFileInfo();
      this.app = app;
      this.updateProgress(this.fileSize, this.fileSize);
      log(`upload > upload response ---> ${JSON.stringify(app, null, 2)}`);

      let uri = app.getLink(REL.BY_PACKAGE_NAME);
      log(`upload > same package named task link ---> ${uri}`);
      this.common.anyway(this.auth.getTaskList(uri, 0)).then(list => {
        log(`upload > task ---> ${JSON.stringify(list, null, 2)}`);
        this.taskList = list;
        this.pg.update(this.taskList.page.pageIndex, this.taskList.page.itemsPerPage, this.taskList.page.totalItems);

        let uri = app.getLink(REL.BY_HASH);
        log(`upload > hash matched task link ---> ${uri}`);
        return this.common.anyway(this.auth.getTaskList(uri, 0));

      }).then(list => {
        log(`upload > hash matched task ---> ${JSON.stringify(list, null, 2)}`);
        if (list && list.items.length >= 1) {
          this.hashMatchedTask = list.items[0];
          log(`upload > hash matched task ---> ${JSON.stringify(this.hashMatchedTask, null, 2)}`);
        } else {
          this.hashMatchedTask = null;
        }

        this.state = State.none;
      });

    }).catch(e => {
      this.state = State.none;
      log(`upload error ---> `, e);
      this.common.showError("common.error", e);
    });
  }

  public loadPage(page: number) {
    let uri = this.app.getLink(REL.BY_PACKAGE_NAME);
    log(`loadPage > same package named task link ---> ${uri}`);
    this.common.anyway(this.auth.getTaskList(uri, page)).then(list => {
      log(`upload > page ---> ${JSON.stringify(page, null, 2)}`);
      log(`upload > task ---> ${JSON.stringify(list, null, 2)}`);
      this.taskList = list;
      this.pg.update(this.taskList.page.pageIndex, this.taskList.page.itemsPerPage, this.taskList.page.totalItems);
    });
  }

  public isEmptyString(s: string): boolean {
    return (!s || s.length == 0)
  }

  public taskListExist(): boolean {
    return (this.taskList && this.taskList.items.length > 0);
  }

  private clearFileInfo() {
    this.file = null;
    this.fileName = "";
    this.fileSize = 0;
    this.fileExist = false;
    this.updateProgress(0, 0);
  }
}

