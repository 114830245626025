<div *ngIf="isExist">
    <div class="media abc-checkbox abc-checkbox-primary pl-2">
        <input [id]="id" class="media-left" type="checkbox" [checked]="isChecked" (click)="onClick($event)">
        <label [for]="id" class="media-body form-check-label" [class.text-primary]="isLocked">
            {{displayName | translate}}
            <span *ngIf="isLocked">
                <i class="fa fa-lock d-inline"></i>
            </span>
        </label>
    </div>
</div>