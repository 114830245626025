<div class="modal-header">
  <h4 class="modal-title">{{'user-search.title' | translate}}</h4>
  <span class="close" aria-label="Close" (click)="cancel()">&times;
  </span>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 input-group mb-3">
      <div class="input-group-prepend">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle [disabled]="state != State.none">
            {{'user.'+selectedFieldName | translate}}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button [hidden]="!has(PARAM.ID)" class="dropdown-item" (click)="set(PARAM.ID)">{{'user.id' | translate}}</button>
            <button [hidden]="!has(PARAM.NAME)" class="dropdown-item" (click)="set(PARAM.NAME)">{{'user.name' | translate}}</button>
            <button [hidden]="!has(PARAM.DEPARTMENT)" class="dropdown-item" (click)="set(PARAM.DEPARTMENT)">{{'user.department' | translate}}</button>
            <button [hidden]="!has(PARAM.EMAIL)" class="dropdown-item" (click)="set(PARAM.EMAIL)">{{'user.email' | translate}}</button>
            <button [hidden]="!has(PARAM.TEL)" class="dropdown-item" (click)="set(PARAM.TEL)">{{'user.tel' | translate}}</button>
        </div>
        </div>
      </div>
      <input type="text" class="form-control" (input)="updateSearch($event.target.value)" [disabled]="state != State.none">
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" [disabled]="state != State.none" (click)="search()">{{'common.search'|translate}}</button>
      </div>
    </div>
  </div>

  <div [hidden]="state != State.loading">
    <div class="media">
      <div class="media-left">
        <i class="fa fa-refresh fast-spin fa-4x fa-fw"></i>
      </div>
      <div class="media-body">
        <h4 class="media-heading">{{'common.loading' | translate}}</h4>
        {{'common.loading-please-wait' | translate}}
      </div>
    </div>
    <hr>
  </div>
  <div [hidden]="state == State.loading">

    <table class="table table-sm table-hover table-rounded">
      <tbody>
        <tr *ngFor="let user of users?.items" class="row m-0" scope="row" (click)="toggleSelection(user)">
          <td class="col-sm-12 d-flex justify-content-between">
            <div class="ml-2">
              <input type="checkbox" id="select" [checked]="isSelectedUser(user)" (change)="selectUser(user, $event.target.value)" [disabled]="state == State.loading">&nbsp;
            </div>
            <div class="col-sm pl-1">
              <span [class]="isSelectedUser(user)?'text-primary':''">
                <span [hidden]="!user?.id" class="text-wrap">
                  <i class="fa fa-address-card-o"></i>&nbsp;{{user?.id}}
                </span>
                <span [hidden]="!user?.name" class="text-wrap">, {{'user.name'|translate}}: {{user?.name}}</span>
                <span [hidden]="!user?.department" class="text-wrap">, {{'user.department'|translate}}: {{user?.department}}</span>
                <br>
                <span [hidden]="!user?.email" class="text-wrap">
                  <i class="fa fa-envelope-o"></i>&nbsp;{{user?.email}}</span>
                <span [hidden]="!user?.tel" class="text-wrap">,
                  <i class="fa fa-phone"></i>&nbsp;{{user?.tel}}</span>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between">
      <div></div>
      <pagenation class="text-center" #pg (pageClick)="search($event)"></pagenation>
      <span class="float-right">{{'user-list.label.total-user'|translate:users?.page}}</span>
    </div>

  </div>

</div>


<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">{{'common.ok' | translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">{{'common.cancel' | translate}}</button>
</div>