<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <span class="close" aria-label="Close" (click)="activeModal.close(false)">&times;
  </span>
</div>
<div class="modal-body">
  <div class="media">
    <div class="media-left pr-2">
      <i class="fa fa-{{icon}} fa-3x blue"></i>
    </div>
    <div class="media-body multi-line-text wrap">{{contents}}</div>
  </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{ok}}</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close(false)" [hidden]="!cancel">{{cancel}}</button>
</div>