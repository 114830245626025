import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, User, KeyStore, TEMPLATE } from '../../service/auth.service';
import { CommonService } from '../../service/common.service';
import { environment, Logger } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { Template } from '../../service/cj';

let log = Logger('QnaCreateView');

enum State {
  none,
  loading,
  processing
}

@Component({
  selector: 'app-qna-create-view',
  templateUrl: './qna-create-view.component.html',
  styleUrls: ['./qna-create-view.component.css']
})
export class QnaCreateViewComponent implements OnInit, OnDestroy {
  public State = State;
  public TEMPLATE = TEMPLATE;
  public state: State = State.none;
  public itemUri: string = null;
  public template: Template = null;
  private authSubscription: Subscription = null;

  public title: string;
  public content: string;

  constructor(route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private common: CommonService) {

    this.itemUri = route.snapshot.params['itemUri'];
    log(`uri ---> ${this.itemUri}`);
    this.authSubscription = this.auth.state.subscribe(this.onAuthChanged.bind(this));
    this.load();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  get isProcessing(): boolean {
    return this.state == State.processing;
  }


  private onAuthChanged(authenticated: boolean) {
    log(`reload....`)
    this.load();
  }

  private load() {
    this.state = State.loading;
    this.auth.getQnaCreateTemplate(this.itemUri).then(template => {
      this.template = template;

      this.title = this.get(TEMPLATE.TITLE);
      this.content = this.get(TEMPLATE.CONTENT);

      log(`template ---> `, this.template);

      this.state = State.none;

    }).catch(e => {
      this.state = State.none;
      log(e);
    });
  }

  has(f: string): boolean {
    if (!this.template) {
      return false;
    }

    return this.template.has(f);
  }

  get(f: string): any {
    if (!this.template) {
      return;
    }

    let value = this.template.get(f);
    return value;
  }

  set(f: string, value: any): void {
    if (!this.template) {
      return;
    }

    if (!this.template.has(f)) {
      return;
    }

    this.template.set(f, value);
  }


  create() {
    this.state = State.processing;

    this.set(TEMPLATE.TITLE, this.title);
    this.set(TEMPLATE.CONTENT, this.content);

    log(`template --->`, this.template);
    
    this.auth.createQna(this.itemUri, this.template).then(uri => {
      this.state = State.none;
      this.common.showMessage("fa-chevron-down", "common.ok", "common.ok", "qna-create.create-completed").then(_ => this.goBack());
    }).catch(code => {
      this.state = State.none;
      this.common.showError("common.error", code);
    });
  }

}
