import { Component, OnInit, Input } from '@angular/core';
import { Task } from '../../service/auth.service';
import { Logger } from '../../../environments/environment';


let log = Logger("TaskView");

@Component({
  selector: 'task-option-badge-view',
  templateUrl: './task-option-badge-view.component.html',
  styleUrls: ['./task-option-badge-view.component.css']
})
export class TaskOptionBadgeViewComponent implements OnInit {
  @Input()
  public task: Task

  constructor() { }

  ngOnInit() {
  }

}
