<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_TITLE)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.alert-title' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_ALERT_TITLE)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.alert-exit-button' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_ALERT_EXIT_BUTTON)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.alert-restart-button' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_ALERT_RESTART_BUTTON)}}</small>
    </div>
</div>


<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-compromised' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_COMPROMISED)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-unsafe-env' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_UNSAFE_ENVIRONMENT)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-frida-detected' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_FRIDA_DETECTED)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-not-permitted-time' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_NOT_PERMITTED_TIME)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_INSTALLING)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-installing' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_INSTALLING)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_RESTART)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-restart' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_RESTART)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.msg-manual-restart' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{get(TEMPLATE.CONFIG_MESSAGE_MANUAL_RESTART)}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_ALERT_SHOW)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.alert-show' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{(get(TEMPLATE.CONFIG_ALERT_SHOW)?'common.yes':'common.no')|translate}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_USE)">
    <div class="col-sm-2 text-right pl-0 pr-0">
        <small>{{'config.use' | translate}}</small>
    </div>
    <div class="col-sm-8">
        <small>{{(get(TEMPLATE.CONFIG_USE)?'common.yes':'common.no')|translate}}</small>
    </div>
</div>

<div class="row mb-1" [hidden]="!existAny(TEMPLATE.CONFIG_BACKGROUND_IMAGE)">
    <div class="col-sm-2 text-right pl-0 pr-0 pt-1">
        <small>{{'config.bg-image' | translate}}</small>
    </div>
    <div class="col-sm-5">
        <div class="card">
            <div class="card-header">
                <button class="btn btn-link p-0" (click)="showBgImage = !showBgImage" [hidden]="!bgImageExist">
                    <small>{{'common.view' | translate}}</small>
                </button>
                <small [hidden]="bgImageExist">{{"common.none" | translate}}</small>
        </div>
            <div class="card-body" [hidden]="!showBgImage || !bgImageExist">
                <div class="col-sm">
                    <img [src]="getImageSource(TEMPLATE.CONFIG_BACKGROUND_IMAGE)" width="100%">
                </div>
            </div>
        </div>
    </div>
</div>