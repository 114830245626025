<ng-template #template>
  <div>
    <div class="row">
      <div class="col-sm-4 text-right text-nowrap pr-2">
        <span>{{ 'user.name' | translate }} :</span>
      </div>
      <div class="col-sm-4 pl-0 wrap">
        <span class="wrap" [hidden]="!user?.name">{{ user?.name }}</span>
        <span class="wrap text-secondary" [hidden]="user?.name">({{ 'common.none' | translate }})</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-right text-nowrap pr-2">
        <span>{{ 'user.department' | translate }} :</span>
      </div>
      <div class="col-sm pl-0 wrap">
        <span class="wrap" [hidden]="!user?.department">{{ user?.department }}</span>
        <span class="wrap text-secondary" [hidden]="user?.department">({{ 'common.none' | translate }})</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-right text-nowrap pr-2">
        <span>{{ 'user.email' | translate }} :</span>
      </div>
      <div class="col-sm pl-0 wrap">
        <span class="wrap" [hidden]="!user?.email">{{ user?.email }}</span>
        <span class="wrap text-secondary" [hidden]="user?.email">({{ 'common.none' | translate }})</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-right text-nowrap pr-2">
        <span>{{ 'user.tel' | translate }} :</span>
      </div>
      <div class="col-sm pl-0 wrap">
        <span class="wrap" [hidden]="!user?.tel">{{ user?.tel }}</span>
        <span class="wrap text-secondary" [hidden]="user?.tel">({{ 'common.none' | translate }})</span>
      </div>
    </div>
    <div [hidden]="!showLogout">
      <div class="dropdown-divider"></div>
      <div class="row text-center">
        <div class="col-sm">
          <button type="button" class="btn btn-primary btn-sm pl-3 pr-3" (click)="onLogoutClick()">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            {{'navigation.logout' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>